define("dem-editor-v2/components/meuble-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['meuble-creator'],
    meubleNotValid: Ember.computed('name', 'volume', function () {
      return Ember.isBlank(this.get('name')) || !this.get('volume');
    }),
    volume: Ember.computed('largeur', 'hauteur', 'profondeur', function () {
      return Math.round(this.get('largeur') * this.get('hauteur') * this.get('profondeur') * 100 / 1000000) / 100;
    }),
    description: Ember.computed('largeur', 'hauteur', 'profondeur', function () {
      return this.get('largeur') + " x " + this.get('hauteur') + " x " + this.get('profondeur');
    }),
    actions: {
      createMeuble: function () {
        var fakeType = Ember.Object.create({
          "fake_type": true,
          "nom_type": this.get('name'),
          "quantite_type": 1,
          "demontage_type": false,
          "emballage_type": false,
          "volume_type": this.get('volume'),
          "description_type": this.get('description')
        });
        this.createMeuble(fakeType);
        this.set("hauteur", null);
        this.set("largeur", null);
        this.set("profondeur", null);
        this.set("name", null);
      }
    }
  });

  _exports.default = _default;
});