define("dem-editor-v2/components/daterange-editor", ["exports", "dem-editor-v2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Component.extend({
    classNames: ["row", "daterange-editor"],
    classNameBindings: ["isDateIdeale:ideale", "isModelNew:bounceIn"],
    isModelNew: Ember.computed('rangeobject.animate', function () {
      return this.get('rangeobject').get('animate');
    }),
    didInsertElement: function () {
      Ember.run.later(this, function () {
        this.get('rangeobject').set('animate', false);
      }, 1000);
    },
    fulfilledServices: Ember.computed('rangeobject.{services,tempServices}', function () {
      var rangeobject = this.get('rangeobject');
      var tempServices = rangeobject.get('tempServices');

      if (Ember.isNone(tempServices)) {
        return this.get('rangeobject').get('services');
      }

      return tempServices;
    }),
    title: Ember.computed('rangeobject.type', function () {
      var type = this.get('rangeobject').get('type');

      if (type === 1) {
        return "Chargement";
      } else {
        return "Livraison";
      }
    }),
    editorTitle: Ember.computed('title', 'num', function () {
      return this.get("title") + " - Période #" + (this.get("num") + 1);
    }),

    setAsDirty(rangeobject) {
      if (!rangeobject.get("hasDirtyAttributes")) {
        rangeobject.send('becomeDirty');
      }
    },

    changeLinkedService: Ember.observer('rangeobject.services.[]', function () {
      var rangeobject = this.get('rangeobject');

      if (!rangeobject.get('isDeleted')) {
        Ember.run.once(this, this.setAsDirty, rangeobject);
      }
    }),

    setIdealeFalse(rangeobject) {
      rangeobject.set('ideale', false);
    },

    showDateIdeale: Ember.computed('rangeobject.services.@each.dateranges', function () {
      var rangeobject = this.get('rangeobject');
      var doesExist = rangeobject.get('services').find(function (service) {
        return service.get('dateranges').filterBy('type', rangeobject.get('type')).get('length') > 1;
      });
      var showIdeale = !Ember.isNone(doesExist);

      if (!showIdeale) {
        Ember.run.scheduleOnce('actions', this, this.setIdealeFalse, rangeobject);
      }

      return showIdeale;
    }),
    isDateIdeale: Ember.computed('rangeobject.ideale', function () {
      return this.get('rangeobject').get('ideale');
    }),
    isLongDistance: Ember.computed('rangeobject.services.@each.distance', function () {
      var serviceLongueDistance = this.get('rangeobject').get('services').find(function (service) {
        return service.get('distance') > _environment.default.IDEM_SETTINGS.MIN_LONG_DISTANCE;
      });
      return !Ember.isNone(serviceLongueDistance);
    }),

    resetChosenByClient(rangeobject) {
      rangeobject.set('chosen_by_client', null);
    },

    showFixePar: Ember.computed('rangeobject.{date_first,date_last,otherDates.[]}', function () {
      var dateFirst = this.get('rangeobject').get('date_first');
      var dateLast = this.get('rangeobject').get('date_last');
      var otherDates = this.get('rangeobject').get('otherDates');
      var show = dateFirst !== dateLast && !Ember.isBlank(dateLast) || !Ember.isBlank(otherDates);

      if (!show) {
        //RESET CHOSEN BY CLIENT IF NOT SHOWN
        Ember.run.scheduleOnce('actions', this, this.resetChosenByClient, this.get('rangeobject'));
      }

      return show;
    }),

    resetGroupage(rangeobject) {
      rangeobject.set('groupage', null);
    },

    showGroupage: Ember.computed('showFixePar', 'isLongDistance', function () {
      var showGroupage = this.get('showFixePar') && this.get('rangeobject').get('type') === 2 && this.get('isLongDistance');

      if (!showGroupage) {
        //RESET GROUPAGE IF NOT SHOWN
        Ember.run.scheduleOnce('actions', this, this.resetGroupage, this.get('rangeobject'));
      }

      return showGroupage;
    }),
    updateOtherDates: Ember.observer('rangeobject.otherDates.@each.{date_first,date_last}', function () {
      this.get('rangeobject').set('otherDates', this.get('rangeobject').get('otherDates'));
    }),
    actions: {
      addOtherDate: function () {
        var otherDates = this.get('rangeobject').get('otherDates');
        otherDates.pushObject(Ember.Object.create({
          date_first: moment().format("YYYY-MM-DD"),
          date_last: null
        }));
      },
      removeOtherDate: function (dates) {
        var otherDates = this.get('rangeobject').get('otherDates');
        otherDates.removeObject(dates);
      },
      removeDate: function () {
        this.removeAction(this.get("rangeobject"));
      },
      copyDate: function () {
        this.copyAction(this.get("rangeobject"));
      },
      dateIdealeChanged: function (value) {
        var dateRange = this.get('rangeobject');

        if (value) {
          this.idealeChanged(dateRange);
        }
      },
      showEtapeChooser: function () {
        this.showEtapeChooser(this.get('rangeobject'));
      }
    }
  });

  _exports.default = _default;
});