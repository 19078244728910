define("dem-editor-v2/routes/baseadresse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      var id = params.adresse_id;
      var project = this.modelFor('project');

      if (id === "new") {
        //TODO : ici le modèle n'est pas encore chargé
        var parentController = this.controllerFor(this.get("routeRoot"));
        var maxOrder = parentController.get('maxOrder', 0);
        return this.store.createRecord('adresse', {
          type: this.get('type'),
          type_logement: "DGTS_LOG_APPART",
          project: project,
          order: maxOrder + 1
        });
      }

      return this.store.findRecord('adresse', params.adresse_id);
    },
    afterModel: function (model) {
      var lastSelectedObject = model;

      if (model.get('isNew')) {
        var parentController = this.controllerFor(this.get("routeRoot"));
        parentController.set('isNewAdresse', true);
        lastSelectedObject = null;
      } //Save last selected object on project


      this.modelFor('project').set('last_selected_address_' + this.get('type'), lastSelectedObject);
      model.set('currentlySelected', true);
    },
    setupController: function (controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.set('refreshStreetView', true);
    },
    renderTemplate: function () {
      return this.render('project.adresse');
    },
    actions: {
      putInLoadingState: function () {
        this.intermediateTransitionTo(this.get('routeRoot') + '.loading');
      },
      willTransition: function (transition) {
        var controller = this.get("controller");
        var model = controller.get('model');

        if (model.get("isDeleted")) {
          return true;
        }

        if (model.get("isNew")) {
          transition.abort();
          controller.set("nextTransition", transition);
          controller.send("confirmSave");
          return false;
        }

        var isDirty = model.get('hasDirtyAttributes');
        var stationnements = model.get('stationnements');

        if (stationnements != null) {
          isDirty = isDirty || stationnements.findBy('hasDirtyAttributes', true);
        }

        if (isDirty) {
          transition.abort();
          controller.set("nextTransition", transition);
          controller.send("confirmSave");
          return false;
        } //SAVE POTENTIALLY MODIFIED ETAPES (DISTANCE UPDATE WHEN NO DISTANCE)


        var toSave = [];
        toSave.pushObjects(model.get('services_chargement').filterBy('hasDirtyAttributes', true).filterBy('isNew', false));
        toSave.pushObjects(model.get('services_livraison').filterBy('hasDirtyAttributes', true).filterBy('isNew', false));

        if (!Ember.isEmpty(toSave)) {
          transition.abort();
          this.send("putInLoadingState");
          Ember.RSVP.all(toSave.invoke('save')).then(function () {
            model.set('currentlySelected', false);
            transition.retry();
          });
          return false;
        }

        model.set('currentlySelected', false);
        return true;
      }
    }
  });

  _exports.default = _default;
});