define("dem-editor-v2/components/meeting-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: ['col-md-12'],
    actions: {
      import: function () {
        this.import(this.get('item'));
      },
      gotoChd: function () {
        this.gotoChd(this.get('item'));
      }
    }
  });

  _exports.default = _default;
});