define("dem-editor-v2/components/piece-category", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['piece-cat'],
    ready: false,
    prepareLayout: function () {
      var individualWidth = 60;
      var individualHeight = 78;
      var count = this.get("items").get('length');
      var maxPerRow = count;
      var nRow = 1;
      var totalWidth = count * individualWidth;
      var maxWidth = (0, _jquery.default)(this.element).parent().width();

      if (totalWidth > maxWidth) {
        totalWidth = maxWidth;
        maxPerRow = totalWidth / individualWidth;
        nRow = Math.floor(count / maxPerRow) + 1;
      }

      (0, _jquery.default)(this.element).find(".glowitem").css('width', 100 / maxPerRow + '%');
      (0, _jquery.default)(this.element).find('.glowitem').height(individualHeight + "px");
      (0, _jquery.default)(this.element).find('.glowrow').width(totalWidth + "px");
      var totalHeight = nRow * individualHeight;
      (0, _jquery.default)(this.element).find('.glowrow').height(totalHeight + "px");
      var thisWidth = (0, _jquery.default)(this.element).width();
      var left = (totalWidth - thisWidth) / 2;
      var position = (0, _jquery.default)(this.element).position();

      if (position.left < left) {
        left = position.left;

        if (left !== 0) {
          left = left + 2; //ajout des bordures
        }
      } else {
        //CHECK RIGHT POSITION TOO
        var rightPosMax = maxWidth;
        var rightPos = position.left + thisWidth + left;

        if (rightPos > rightPosMax) {
          left = left + rightPos - rightPosMax;
        }
      }

      (0, _jquery.default)(this.element).find('.glowrow').css('left', "-" + left + "px");
      (0, _jquery.default)(this.element).find('.glowrow').css('top', "-" + (position.top + totalHeight + 2) + "px");
      this.set('ready', true);
    },
    actions: {
      showCategory: function () {
        if (this.get('ready') === false) {
          this.prepareLayout();
        }

        if ((0, _jquery.default)(this.element).find('.glowrow').is(':hidden')) {
          (0, _jquery.default)('.glowrow').hide();
          (0, _jquery.default)('.piece-cat a').removeClass('active');
          (0, _jquery.default)(this.element).find('.glowrow').show();
          (0, _jquery.default)(this.element).find('a').addClass('active');
        }
      }
    }
  });

  _exports.default = _default;
});