define("dem-editor-v2/routes/project/prestations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Route.extend({
    model: function () {
      var project = this.modelFor('project');
      return project.get('services');
    },
    isTransitioning: false,
    setupController: function (controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.toggleProperty('resetTextArea');
      controller.set('serviceInEditMode', null);
      var gmData = controller.get('newServiceGMData');
      console.log(gmData);
      var project = this.modelFor('project');
      var isGM = project.get('adresseLivraison').get('type_logement') == "DGTS_LOG_GARDEMEUBLE_DGR";
      controller.set('livraisonIsGMAddress', isGM);
      gmData.set('relivraison', !isGM);
      console.log(isGM);
      console.log(gmData);
    },
    actions: {
      willTransition: function (transition) {
        transition.abort();
        this.intermediateTransitionTo('project.loading');
        var controller = this.get('controller'); //RESET EDITING STATE IF NECESSARY

        var editingService = controller.get('serviceInEditMode');

        if (!Ember.isNone(editingService)) {
          editingService.set('editing', false);
        }

        var model = controller.get('model');
        var project = this.modelFor('project');
        var toSave = [];
        var toDelete = [];
        var defaultServicesModified = false;
        model.forEach(function (item) {
          if (item.get('isDefaultService')) {
            toDelete.pushObject(item);
          } else if (item.get('hasDirtyAttributes')) {
            toSave.pushObject(item);
          }
        }); //SAVE OTHER THAN FORMULE INCLUDED SERVICES

        var formule = controller.get("formule");

        if (formule) {
          var formuleValue = formule.get("value");
          var value = 0;
          var otherDefaultsToSave = controller.get("defaultServices").sortBy("value:desc").filter(function (item) {
            var type = item.get("type");

            if (type === 1) {
              value += item.get("value");

              if (value > formuleValue) {
                return true;
              }
            } else if (type === 2) {
              return true;
            }

            return false;
          });

          var _this = this;

          otherDefaultsToSave.forEach(function (item) {
            //CHECK IF SAME SERVICE EXISTED BEFORE AND ALTER IT IF NECESSARY
            var sameItem = toDelete.findBy("key", item.get('key'));

            if (!Ember.isNone(sameItem)) {
              toDelete.removeObject(sameItem);
              sameItem.set("type", item.get("type"));

              if (sameItem.get("hasDirtyAttributes")) {
                toSave.pushObject(sameItem);
              }
            } else {
              var newService = _this.store.createRecord('service', {
                name: item.get('name'),
                type: item.get('type'),
                key: item.get('key'),
                color: item.get('color'),
                include_vol: true,
                adresse: null,
                project: project
              });

              toSave.pushObject(newService);
            }
          });
        } //SAVE PROJECT (THE FORMULE KEY)


        if (project.get('hasDirtyAttributes')) {
          toSave.pushObject(project);
        } else if (defaultServicesModified) {
          project.set('last_modified', moment.utc().format());
        }

        Ember.RSVP.allSettled(toDelete.invoke("destroyRecord")).then(function () {
          Ember.RSVP.allSettled(toSave.invoke('save')).then(function () {
            transition.retry();
          });
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});