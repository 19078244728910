define("dem-editor-v2/components/daterange-picker", ["exports", "jquery", "dem-editor-v2/helpers/holiday"], function (_exports, _jquery, _holiday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Component.extend({
    classNames: ["form-group", "my-range-picker"],
    inputClassName: "form-control",
    singleDatePicker: false,
    mustInit: false,
    displayDateFormat: "ddd D MMM",
    displayLongDateFormat: "dddd D MMMM",
    initialize: Ember.observer('mustInit', 'minDate', function () {
      var _this = this;

      var displayDateFormat = this.get('displayDateFormat');
      var dataDateFormat = "YYYY-MM-DD";
      var startDate = moment(this.get('dateStart'), dataDateFormat);
      var specifiedEndDate = this.get('dateEnd');
      var endDate = startDate;

      if (specifiedEndDate) {
        endDate = moment(specifiedEndDate, dataDateFormat);
      }

      var config = {
        applyClass: "btn-primary",
        autoUpdateInput: true,
        singleDatePicker: this.get('singleDatePicker'),
        autoApply: true,
        locale: {
          format: displayDateFormat,
          applyLabel: "Valider",
          cancelLabel: "Annuler",
          customRangeLabel: "Autre",
          daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
          monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
          firstDay: 1
        },

        isCustomDate(date) {
          return (0, _holiday.isHoliday)(date) ? 'bg-danger' : '';
        }

      };

      if (!this.get('noMinDate')) {
        var minDate = this.get('minDate');

        if (minDate) {
          config.minDate = moment(minDate);
        } else {
          config.minDate = moment();
        }

        if (config.minDate.isAfter(startDate)) {
          startDate = config.minDate;
        }

        if (config.minDate.isAfter(endDate)) {
          endDate = config.minDate;
        }
      }

      config.startDate = startDate;
      config.endDate = endDate;

      if (this.get('showRanges')) {
        config.ranges = {
          "Aujourd'hui": [moment(), moment()],
          "Hier": [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
        };
      }

      var inputElem = (0, _jquery.default)(this.element).find('input');
      inputElem.daterangepicker(config);
      inputElem.on('apply.daterangepicker', function (ev, picker) {
        _this.set('dateStart', picker.startDate.format('YYYY-MM-DD'));

        _this.set('dateEnd', picker.endDate.format('YYYY-MM-DD'));

        if (_this.onApply) _this.onApply();
      });
      inputElem.on('hide.daterangepicker', function (ev, picker) {
        _this.showFormattedDates(picker.startDate, picker.endDate);
      });
      Ember.run.scheduleOnce('actions', this, this.showUnformattedDates);
      if (_this.onApply) _this.onApply();
    }),
    willDestroyElement: function () {
      var inputElem = (0, _jquery.default)(this.element).find('input');
      inputElem.data('daterangepicker').remove();
      inputElem.off('daterangepicker');
      inputElem.unbind('.daterangepicker');
    },
    didInsertElement: function () {
      this.toggleProperty('mustInit');
    },
    updateDisplay: Ember.observer('dateStart', 'dateEnd', function () {
      Ember.run.scheduleOnce('actions', this, "showUnformattedDates");
    }),
    showUnformattedDates: function () {
      var dataDateFormat = "YYYY-MM-DD";
      var startDate = moment(this.get('dateStart'), dataDateFormat);
      var specifiedEndDate = this.get('dateEnd');
      var endDate = startDate;

      if (specifiedEndDate) {
        endDate = moment(specifiedEndDate, dataDateFormat);
      }

      this.showFormattedDates(startDate, endDate);
    },
    showFormattedDates: function (momentStart, momentEnd) {
      var inputElem = (0, _jquery.default)(this.element).find('input');

      if (momentEnd.isSame(momentStart, 'day')) {
        var formattedDate = momentStart.format(this.get('displayLongDateFormat'));
        inputElem.val(formattedDate);
      } else {
        var displayDateFormat = this.get('displayDateFormat');
        var formattedStartDate = momentStart.format(displayDateFormat);
        var formattedEndDate = momentEnd.format(displayDateFormat);
        inputElem.val('du ' + formattedStartDate + ' au ' + formattedEndDate);
      }
    }
  });

  _exports.default = _default;
});