define("dem-editor-v2/templates/components/form-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ni0C8FyC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"suggestions\"]]],null,{\"statements\":[[0,\"\\t\"],[1,[28,\"auto-complete\",null,[[\"value\",\"placeholder\",\"class\",\"disabled\",\"suggestions\",\"refresh\"],[[24,[\"value\"]],[24,[\"placeholder\"]],\"form-control one-line\",[24,[\"disabled\"]],[24,[\"suggestions\"]],[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"class\",\"disabled\",\"type\",\"autocomplete\"],[[24,[\"value\"]],[24,[\"placeholder\"]],\"form-control\",[24,[\"disabled\"]],[24,[\"type\"]],\"KO\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/form-input.hbs"
    }
  });

  _exports.default = _default;
});