define("dem-editor-v2/components/service-tagger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tags: Object.freeze(["par escalier", "par ascenseur", "en chandelle", "100 kg", "150 kg", "200 kg"]),
    onTagAdded: false,
    actions: {
      toggleTag: function (tag) {
        var service = this.get('value');

        if (!service) {
          service = "";
        }

        if (service.includes(tag)) {
          service = service.replace(tag, "");
          service = service.replace("  ", " ");
        } else {
          service = service + " " + tag;
        }

        this.set('value', service.trim());
        this.toggleProperty('onTagAdded');
      }
    }
  });

  _exports.default = _default;
});