define("dem-editor-v2/helpers/nl2br", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.nl2br = nl2br;
  _exports.default = void 0;

  function nl2br(params
  /*, hash*/
  ) {
    var breakTag = '<br />';
    return new Ember.String.htmlSafe((params + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
  }

  var _default = Ember.Helper.helper(nl2br);

  _exports.default = _default;
});