define("dem-editor-v2/templates/project/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iTC0lYgD",
    "block": "{\"symbols\":[\"adresse\"],\"statements\":[[7,\"nav\",true],[10,\"class\",\"fixed-header fixed-sub-nav\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"pagination\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"orderedModels\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"currentlySelected\"]],\"\",\"notactive\"],null]]]],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"adresseRoute\"]],[23,1,[\"id\"]]]],{\"statements\":[[1,[23,1,[\"order\"]],false],[0,\". \"],[1,[23,1,[\"adresse_descriptor\"]],false]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[7,\"li\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"isNewAdresse\"]],\"\",\"notactive\"],null]]]],[8],[4,\"link-to\",null,[[\"route\",\"model\"],[[24,[\"adresseRoute\"]],\"new\"]],{\"statements\":[[0,\"Nouvelle adresse\"]],\"parameters\":[]},null],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/project/location.hbs"
    }
  });

  _exports.default = _default;
});