define("dem-editor-v2/components/boolean-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    falseText: "NON",
    trueText: "OUI",
    nullable: false,
    canSetToNull: false,
    showCheckBox: false,
    calculatedFalseValue: Ember.computed('value', function () {
      var value = this.get('value');

      if (this.get('nullable') && (value === undefined || value === null)) {
        return false;
      }

      return value;
    }),
    calculatedTrueValue: Ember.computed('value', function () {
      var value = this.get('value');

      if (this.get('nullable') && (value === undefined || value === null)) {
        return true;
      }

      return value;
    }),
    actions: {
      setValue: function (value) {
        var nowValue = this.get('value');

        if (nowValue === value && this.get('canSetToNull')) {
          value = null;
        }

        this.set("value", value);
      }
    }
  });

  _exports.default = _default;
});