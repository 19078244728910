define("dem-editor-v2/transforms/nullboolean", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.BooleanTransform.extend({
    deserialize(serialized) {
      if (serialized === null || serialized === undefined) {
        return null;
      }

      return this._super(serialized);
    },

    serialize(deserialized) {
      if (deserialized === null || deserialized === undefined) {
        return null;
      }

      return this._super(deserialized);
    }

  });

  _exports.default = _default;
});