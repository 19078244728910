define("dem-editor-v2/components/edit-text", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    didInsertElement: function () {
      (0, _jquery.default)(this.element).focus();
    },
    updateCaretPosition: Ember.observer('onTextUpdate', function () {
      Ember.run.once(this, this.doUpdateCaretPosition);
    }),

    doUpdateCaretPosition() {
      (0, _jquery.default)(this.element).focus();
      var valLength = this.get('value').length;
      this.element.setSelectionRange(valLength, valLength);
    }

  });

  _exports.default = _default;
});