define("dem-editor-v2/templates/components/check-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xmKTrQun",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[0,\" \\n\"],[7,\"div\",true],[11,\"class\",[22,\"checkboxClass\"]],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[24,[\"checked\"]],[24,[\"checkDisabled\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[22,\"labelClass\"]],[8],[0,\"\\n\\t\"],[1,[22,\"label\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"enableComment\"]]],null,{\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"commentClass\"]],[8],[0,\"\\n\"],[1,[28,\"form-input\",null,[[\"model\",\"field\",\"placeholder\",\"disabled\",\"class\",\"suggestions\",\"refresh\",\"notNull\"],[[24,[\"model\"]],[24,[\"commentField\"]],[24,[\"commentaire\"]],[24,[\"disabled\"]],\"\",[24,[\"suggestions\"]],[24,[\"refreshComment\"]],[24,[\"commentNotNull\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"dontKnow\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"dont-know-block col-xs-12 warning\"],[8],[0,\"\\n    VERIFIER: \"],[1,[22,\"dontKnowComment\"],false],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"verifyDontKnow\"]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-check\"],[8],[9],[0,\" OK\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/check-comment.hbs"
    }
  });

  _exports.default = _default;
});