define("dem-editor-v2/components/boolean-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    toggleText: "OUI",
    actions: {
      toggleValue: function () {
        this.set('value', !this.get('value'));
        if (this.onChange) this.onChange(this.get('value'));
      }
    }
  });

  _exports.default = _default;
});