define("dem-editor-v2/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f87GYE17",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"id\",\"login\"],[10,\"class\",\"form-group\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"loggingIn\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"style\",\"width: 100%; height: 100%; text-align: center; padding: 30px;\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/assets/images/loader.gif\"],[10,\"height\",\"32\"],[10,\"width\",\"32\"],[8],[9],[0,\" \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\tConnexion en cours...\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"Login\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"error\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"style\",\"padding-bottom: 10px;\"],[8],[0,\"Nom d'utilisateur ou mot de passe incorrect\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\\t\"],[7,\"form\",false],[12,\"class\",[28,\"if\",[[24,[\"model\",\"error\"]],\"has-error\"],null]],[3,\"action\",[[23,0,[]],\"login\"],[[\"on\"],[\"submit\"]]],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"edit-text\",null,[[\"class\",\"placeholder\",\"value\"],[\"form-control\",\"Nom d'utilisateur\",[24,[\"model\",\"username\"]]]]],false],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"edit-text\",null,[[\"class\",\"type\",\"placeholder\",\"value\"],[\"form-control\",\"password\",\"Mot de passe\",[24,[\"model\",\"password\"]]]]],false],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"button\",true],[10,\"class\",\"btn btn-primary\"],[10,\"type\",\"submit\"],[8],[0,\"Valider\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/login.hbs"
    }
  });

  _exports.default = _default;
});