define("dem-editor-v2/controllers/project/chargement/adresse", ["exports", "dem-editor-v2/controllers/baseadresse"], function (_exports, _baseadresse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseadresse.default.extend({
    routeRoot: 'project.chargement',
    parentController: Ember.inject.controller('project.chargement')
  });

  _exports.default = _default;
});