define("dem-editor-v2/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      this.controllerFor("application").set("title", "Se connecter");
      return this.modelFor('application');
    }
  });

  _exports.default = _default;
});