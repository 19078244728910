define("dem-editor-v2/templates/components/service-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zNnGfksW",
    "block": "{\"symbols\":[\"serviceObject\"],\"statements\":[[4,\"each\",[[24,[\"getServices\"]]],null,{\"statements\":[[7,\"tr\",true],[8],[0,\"\\n\\t\"],[7,\"td\",true],[8],[1,[28,\"input\",null,[[\"type\",\"id\",\"checked\",\"disabled\"],[\"checkbox\",[23,1,[\"chooserId\"]],[23,1,[\"isSelected\"]],[23,1,[\"isDisabled\"]]]]],false],[9],[0,\"\\n\\t\"],[7,\"td\",true],[8],[1,[28,\"service-box\",null,[[\"service\"],[[23,1,[\"service\"]]]]],false],[9],[0,\"\\n\\t\"],[7,\"td\",true],[8],[7,\"label\",true],[11,\"for\",[29,[[23,1,[\"chooserId\"]]]]],[8],[1,[23,1,[\"service\",\"name\"]],false],[9],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/service-chooser.hbs"
    }
  });

  _exports.default = _default;
});