define("dem-editor-v2/components/auto-complete", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Handlebars, autosize */
  var _default = Ember.TextArea.extend({
    clearAfterAction: false,
    attributeBindings: ['rows', 'disabled'],
    rows: 1,
    simpleObjectMapper: Ember.computed('objectMapper', function () {
      var mapper = this.get('objectMapper');

      if (!mapper) {
        mapper = function (item) {
          if (typeof item === "string") {
            return {
              text: item,
              emberObject: item
            };
          } else {
            return {
              text: item.text,
              emberObject: item
            };
          }
        };
      }

      return mapper;
    }),
    isFocusEvent: false,
    didInsertElement: function () {
      var _this = this;

      var _element = this.element;
      var maxResults = this.get('maxResults');

      if (maxResults == null) {
        maxResults = 10;
      }

      var minLength = this.get('minLength');

      if (minLength == null) {
        minLength = 0;
      }

      var typeahead = (0, _jquery.default)(this.element).typeahead({
        minLength: minLength,
        hint: false,
        highlight: true,
        autoselect: true
      }, {
        name: "typeahead",
        source: function (query, process) {
          var myResults = _this.get("suggestions");

          var queryRegExp = new RegExp(window.escapeRegExp(query), "i");
          var results = myResults.map(_this.get('simpleObjectMapper')).filter(function (item) {
            return _this.get('isFocusEvent') || queryRegExp.test(item.text);
          });

          _this.set('isFocusEvent', false);

          process(results.slice(0, maxResults));
        },
        templates: {
          suggestion: Handlebars.compile('<p class="text-suggestions">{{text}}</p>')
        }
      });
      typeahead.on("typeahead:selected", function (event, item) {
        (0, _jquery.default)(_element).typeahead('val', item.text);

        _this.send("onItemSelected", item.emberObject);

        if (_this.get('clearAfterAction')) {
          (0, _jquery.default)(_element).val('');
          (0, _jquery.default)(_element).typeahead('val', null);
          (0, _jquery.default)(_element).blur();
        }
      });
      typeahead.on("typeahead:autocompleted", function (event, item) {
        (0, _jquery.default)(_element).typeahead("val", item.text);
      }); //SHOW OPTIONS ON FOCUS

      (0, _jquery.default)(_element).on("focus", function () {
        _this.set('isFocusEvent', true);

        var ev = _jquery.default.Event("keydown");

        ev.keyCode = ev.which = 40;
        (0, _jquery.default)(this).trigger(ev);
        return true;
      });
      (0, _jquery.default)(_element).keydown(function (e) {
        //PREVENT ENTER KEY
        if (e.keyCode == 13 && !e.shiftKey) {
          e.preventDefault();
        }
      });
      autosize((0, _jquery.default)('textarea'));
    },

    doRefreshValue() {
      (0, _jquery.default)(this.element).typeahead('val', this.get('value'));
    },

    refreshValue: Ember.observer('refresh', function () {
      Ember.run.once(this, this.doRefreshValue);
    }),

    doAutoSize() {
      autosize.update((0, _jquery.default)(this.element));
    },

    updateSize: Ember.observer('value', function () {
      Ember.run.scheduleOnce("actions", this, this.doAutoSize);
    }),

    doUpdateCaret() {
      (0, _jquery.default)(this.element).typeahead('val', this.get('value'));
      (0, _jquery.default)(this.element).focus();
      var valLength = this.get('value').length;
      (0, _jquery.default)(this.element).get(0).setSelectionRange(valLength, valLength);
    },

    updateCaretPosition: Ember.observer('onTextUpdate', function () {
      Ember.run.once(this, this.doUpdateCaret);
    }),
    willDestroyElement: function () {
      (0, _jquery.default)(this.element).typeahead('destroy');
    },
    actions: {
      onItemSelected: function (obj) {
        if (this.onSelect) {
          this.onSelect(obj);
        }
      }
    }
  });

  _exports.default = _default;
});