define("dem-editor-v2/templates/components/boolean-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xGGs3CHU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[28,\"unless\",[[24,[\"calculatedTrueValue\"]],\"label label-false label-full\",\"label label-false label-empty\"],null]],[3,\"action\",[[23,0,[]],\"setValue\",false]],[8],[0,\"\\n    \\n\"],[4,\"if\",[[24,[\"showCheckBox\"]]],null,{\"statements\":[[4,\"unless\",[[24,[\"calculatedTrueValue\"]]],null,{\"statements\":[[0,\"            \"],[7,\"i\",true],[10,\"class\",\"fa fa-check\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[22,\"falseText\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[28,\"if\",[[24,[\"calculatedFalseValue\"]],\"label label-true label-full\",\"label label-true label-empty\"],null]],[3,\"action\",[[23,0,[]],\"setValue\",true]],[8],[0,\"\\n    \\n\"],[4,\"if\",[[24,[\"showCheckBox\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"calculatedFalseValue\"]]],null,{\"statements\":[[0,\"            \"],[7,\"i\",true],[10,\"class\",\"fa fa-check\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[22,\"trueText\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/boolean-switch.hbs"
    }
  });

  _exports.default = _default;
});