define("dem-editor-v2/routes/project/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      willTransition: function () {
        this.controllerFor('project.inventaire').set('isInLoadingRoute', false);
      }
    }
  });

  _exports.default = _default;
});