define("dem-editor-v2/routes/project/dates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      var project = this.modelFor('project');
      return project.get('dateranges');
    },
    setupController: function (controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      controller.set('selectedDateRange', null);
    },
    actions: {
      willTransition: function (transition) {
        var controller = this.get('controller');
        var model = controller.get('model');
        var toSave = model.filter(function (item) {
          if (item.get('hasDirtyAttributes') || item.get('isNew')) {
            return true;
          }

          return false;
        });

        if (toSave.get('length') > 0) {
          transition.abort();
          this.intermediateTransitionTo('project.loading');
          Ember.RSVP.allSettled(toSave.invoke('save')).then(function () {
            transition.retry();
          });
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});