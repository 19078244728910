define("dem-editor-v2/helpers/my-sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mySum = mySum;
  _exports.default = void 0;

  function mySum(params
  /*, hash*/
  ) {
    return params[0] + params[1];
  }

  var _default = Ember.Helper.helper(mySum);

  _exports.default = _default;
});