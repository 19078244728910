define("dem-editor-v2/templates/components/service-type-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NrA3LmB4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[28,\"if\",[[24,[\"isService\"]],\"service label label-full\",\"service label label-empty\"],null]],[3,\"action\",[[23,0,[]],\"setServiceType\",1]],[8],[0,\"SERVICE\"],[9],[0,\"\\n\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[28,\"if\",[[24,[\"isOption\"]],\"option label label-full\",\"option label label-empty\"],null]],[3,\"action\",[[23,0,[]],\"setServiceType\",2]],[8],[0,\"OPTION\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/service-type-chooser.hbs"
    }
  });

  _exports.default = _default;
});