define("dem-editor-v2/templates/components/overlay-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/NYMuczY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"hider\"],[11,\"style\",[28,\"safestring\",[[24,[\"opacity_string\"]]],null]],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"loader-object\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"loader-window\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/assets/images/loader.gif\"],[10,\"style\",\"margin-top: 60px;\"],[10,\"height\",\"32\"],[10,\"width\",\"32\"],[8],[9],[0,\" \\n        \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[1,[22,\"text\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/overlay-loader.hbs"
    }
  });

  _exports.default = _default;
});