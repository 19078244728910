define("dem-editor-v2/routes/project/chd", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      return this.modelFor('project');
    },
    afterModel: function (model, transition) {
      model.set('shouldReloadCHD', false);
      transition.trigger(false, 'loadVersions');
      transition.trigger(false, 'loadChd');
    },
    actions: {
      loadVersions: function () {
        (0, _jquery.default)("#chd-versions").fadeOut();

        var _this = this;

        var server = _environment.default.APP.API_HOST;
        var chdVersionUrl = server + "/versions/list/" + this.modelFor('project').get('num_client');

        _jquery.default.get(chdVersionUrl, function (data) {
          var controller = _this.get('controller');

          controller.set('currentModifs', data.shiftObject());
          controller.set('currentVersion', data.get('firstObject'));
          controller.set("versions", data);
          (0, _jquery.default)("#chd-versions").fadeIn();
        });
      },
      loadChd: function () {
        (0, _jquery.default)('#chd-data').fadeOut();
        var server = _environment.default.APP.API_HOST;
        var chdUrl = server + "/chd/" + this.modelFor('project').get('num_client');

        _jquery.default.get(chdUrl, function (data) {
          (0, _jquery.default)('#chd-data').html(data);
          (0, _jquery.default)('#chd-data').fadeIn();
        });
      }
    }
  });

  _exports.default = _default;
});