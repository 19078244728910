define("dem-editor-v2/helpers/meetingname", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function meetingname(params
  /*, hash*/
  ) {
    let meeting = params[0];
    let meetingName = meeting.name;

    if (meeting.parent.visit_type && meeting.parent.visit_type.match(/ONLINE/)) {
      meetingName += " ONLINEBOOKING";
    }

    return meetingName;
  });

  _exports.default = _default;
});