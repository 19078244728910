define("dem-editor-v2/templates/components/street-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Plmq6OZV",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\\t<button style=\\\"position: relative; top: 40px; left: 90%; z-index: 10000;\\\" {{action 'savePicture'}}><i class=\\\"fa fa-camera\\\"></i></button>\\n\"],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[7,\"div\",true],[10,\"style\",\"width: 100%; border-radius: 5px; height: 400px; text-align: center; vertical-align: middle; line-height: 400px; position: absolute; z-index: 100000; background-color: rgba(255, 255, 255, 0.8); opacity: 0.8\"],[8],[0,\"\\n\\t\"],[7,\"img\",true],[10,\"src\",\"/assets/images/loader.gif\"],[10,\"height\",\"32\"],[10,\"width\",\"32\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"style\",\"width: 100%; height: 400px; border-radius: 5px; \"],[10,\"id\",\"pano\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/street-view.hbs"
    }
  });

  _exports.default = _default;
});