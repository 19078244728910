define("dem-editor-v2/templates/components/calc-assurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CzkL+8BV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"table\",true],[10,\"class\",\"assurance\"],[8],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"Taux :\"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"taux\"],[8],[1,[22,\"tauxAssuranceMin\"],false],[0,\" %\"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"separator\"],[8],[0,\"-\"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"taux\"],[8],[1,[22,\"tauxAssuranceMax\"],false],[0,\" %\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tr\",true],[8],[0,\"   \\n        \"],[7,\"td\",true],[8],[0,\"Prix :\"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"taux\"],[8],[1,[22,\"valueAssuranceMin\"],false],[0,\" € TTC\"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"separator\"],[8],[0,\"-\"],[9],[0,\"\\n        \"],[7,\"td\",true],[10,\"class\",\"taux\"],[8],[1,[22,\"valueAssuranceMax\"],false],[0,\" € TTC\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/calc-assurance.hbs"
    }
  });

  _exports.default = _default;
});