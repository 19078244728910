define("dem-editor-v2/components/qty-editor", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    step: 1,
    value: 0,
    modePrecis: false,
    allow_zero: false,
    computedStep: Ember.computed('modePrecis', 'step', function () {
      if (this.get('modePrecis')) {
        return 0.1;
      }

      return this.get('step');
    }),
    safeStyle: Ember.computed('style', function () {
      return new Ember.String.htmlSafe(this.get('style'));
    }),
    didInsertElement: function () {
      var _this = this;

      (0, _jquery.default)(this.element).find('input.qtyinput').keypress(function (event) {
        var keyCode = event.which; //+

        if (keyCode === 43) {
          event.preventDefault();

          _this.send('increment');

          return false;
        } //-


        if (keyCode === 45) {
          event.preventDefault();

          _this.send('decrement');

          return false;
        } //, (for mac users)


        if (keyCode == 44) {
          event.preventDefault();
          var selStart = event.target.selectionStart;
          var selEnd = event.target.selectionEnd;

          var val = _this.get('value');

          _this.set('value', val.substring(0, selStart) + '.' + val.substring(selEnd));

          Ember.run.scheduleOnce('render', event.target, "setSelectionRange", selStart + 1, selStart + 1);
          return false;
        } //Only allow numbers and decimal point


        if (!(keyCode <= 57 && keyCode >= 48 || keyCode === 46)) {
          return false;
        }

        return true;
      });
    },
    adjustToStep: function (floatVal, floatStep, lowerBound) {
      var divider = Math.round(floatVal * 100 / floatStep) / 100;

      if (divider !== parseInt(divider)) {
        if (!lowerBound) {
          return Math.round(Math.ceil(divider) * floatStep * 100) / 100;
        }

        return Math.round((Math.ceil(divider) - 1) * floatStep * 100) / 100;
      }

      return false;
    },
    adjustToStepAndReturnValue: function (floatVal, floatStep, lowerBound) {
      var adjustedValue = this.adjustToStep(floatVal, floatStep, lowerBound);
      return adjustedValue ? adjustedValue : floatVal;
    },
    actions: {
      increment: function () {
        var val = parseFloat(this.get('value')) + parseFloat(this.get('computedStep'));
        this.set('value', this.adjustToStepAndReturnValue(Math.round(val * 100) / 100, this.get('computedStep'), true));
      },
      decrement: function () {
        var val = parseFloat(this.get('value')) - parseFloat(this.get('computedStep'));

        if (val > 0) {
          this.set('value', this.adjustToStepAndReturnValue(Math.round(val * 100) / 100, this.get('computedStep'), false));
        } else if (this.get('allow_zero')) {
          this.set('value', 0);
        }
      },
      checkValue: function () {
        var value = this.get("value");
        var floatVal = parseFloat(value);

        if (isNaN(floatVal) || floatVal < 0 || floatVal === 0 && !this.get('allow_zero')) {
          this.set('value', this.get('computedStep'));
          return true;
        }

        var checkStep = this.get('checkStep', null);

        if (!checkStep) {
          checkStep = this.get('computedStep');
        }

        var adjustedValue = this.adjustToStep(floatVal, parseFloat(checkStep));

        if (adjustedValue) {
          this.set('value', adjustedValue);
          return true;
        } //FIX IF FLOATVAL IS NOT REALLY THE SAME VALUE


        if (floatVal + "" !== value) {
          this.set('value', floatVal);
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});