define("dem-editor-v2/components/piece-type-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["piece-type-chooser", "row"],
    value: Ember.computed("selectedType", {
      get: function () {
        return this.get('selectedType');
      },
      set: function (key, value) {
        var selectedType = this.get('selectedType');

        if (!Ember.isNone(selectedType)) {
          if (Ember.isNone(value)) {
            selectedType.set("selected", false);
            this.set('selectedType', null);
            return null;
          }
        }

        return value;
      }
    }),
    selectedType: null,
    availableTypes: Object.freeze([Ember.Object.create({
      name: "Entrée",
      key: "hall"
    }), Ember.Object.create({
      name: "Chambre",
      key: "bedroom"
    }), Ember.Object.create({
      name: "Salon",
      key: "living"
    }), Ember.Object.create({
      name: "Cuisine",
      key: "kitchen"
    }), Ember.Object.create({
      name: "Salle de bain",
      key: "bathroom"
    }), Ember.Object.create({
      name: "WC",
      key: "toilets"
    }), Ember.Object.create({
      name: "Couloir",
      key: "corridor"
    }), Ember.Object.create({
      name: "Bureau",
      key: "office"
    }), Ember.Object.create({
      name: "Buanderie",
      key: "buanderie"
    }), Ember.Object.create({
      name: "Dressing",
      key: "dressing"
    }), Ember.Object.create({
      name: "Mezzanine",
      key: "mezzanine"
    }), Ember.Object.create({
      name: "Débarras",
      key: "debarras"
    }), Ember.Object.create({
      name: "Placard",
      key: "placard"
    }), Ember.Object.create({
      name: "Cave",
      key: "cellar"
    }), Ember.Object.create({
      name: "Garage",
      key: "garage"
    }), Ember.Object.create({
      name: "Jardin",
      key: "garden"
    }), Ember.Object.create({
      name: "Balcon",
      key: "balcony"
    }), Ember.Object.create({
      name: "Terrasse",
      key: "terrace"
    }), Ember.Object.create({
      name: "Palier",
      key: "palier"
    }), Ember.Object.create({
      name: "Sous sol",
      key: "soussol"
    }), Ember.Object.create({
      name: "Salle à manger",
      key: "salleamanger"
    })]),
    actions: {
      selectPieceType: function (pieceType) {
        var previouslySelected = this.get("selectedType");

        if (previouslySelected != null) {
          previouslySelected.set("selected", false);
        }

        this.set("selectedType", pieceType);
        pieceType.set("selected", true);

        if (this.onChange) {
          Ember.run.once(this, this.onChange, pieceType);
        }
      }
    }
  });

  _exports.default = _default;
});