define("dem-editor-v2/components/bs-modal", ["exports", "dem-editor-v2/templates/components/bs-modal"], function (_exports, _bsModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _bsModal.default,
    disabled: false,
    validateText: "Valider",
    cancelText: "Annuler",
    actions: {
      confirm: function () {
        if (this.onConfirm) {
          this.onConfirm();
        }
      },
      cancel: function () {
        if (this.onCancel) {
          this.onCancel();
        }
      }
    }
  });

  _exports.default = _default;
});