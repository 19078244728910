define("dem-editor-v2/controllers/project", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    app_controller: Ember.inject.controller('application'),
    project: Ember.computed('model', function () {
      return this.get('model');
    }),
    userinfos: Ember.computed('app_controller', function () {
      return this.get('app_controller').get('model');
    }),
    username: Ember.computed('userinfos', function () {
      return this.get('userinfos').get('username');
    }),
    crmLink: Ember.computed('project', function () {
      var project = this.get('project');

      if (project) {
        return _environment.default.APP.API_HOST + '/crm/' + project.get('token');
      }

      return "#";
    }),
    onlineBookingFormLink: Ember.computed('project.isOnlineBooking', function () {
      var project = this.get('project');

      if (project && project.get('isOnlineBooking')) {
        var numClient = project.get('num_client');
        return 'https://docs.google.com/forms/d/e/1FAIpQLSeB0WQlS29zuHhAlr-VBoequZLacH7M2U09L3ESn1RHEjRAnQ/viewform?usp=pp_url&entry.1140607297=' + numClient;
      }

      return false;
    }),
    importAndReset: true,
    modifiedDistance: null,
    isDistanceInvalid: Ember.computed('modifiedDistance', function () {
      var dist = this.get('modifiedDistance');
      return Ember.isBlank(dist) || dist.toString().match(/[^\d]/) || isNaN(parseInt(dist));
    }),
    actions: {
      exportExcel: function () {
        var project = this.get('project');

        if (project) {
          if (project.get('hasError')) {
            _jquery.default.growl.error({
              title: 'Export impossible',
              message: 'Corriger les erreurs pour pouvoir exporter.'
            });

            return;
          }

          var currentUser = this.get('userinfos').get('userid');

          var growl = _jquery.default.growl({
            title: '<img src="/assets/images/ajax_loader.gif" height="24" width="24"/> Export en cours...',
            fixed: true,
            message: ''
          });

          var onFail = function (error) {
            console.log(error);
            growl.dismiss(function () {
              growl.remove(function () {
                _jquery.default.growl.error({
                  title: 'Une erreur est survenue...',
                  message: ''
                });
              });
            });
          };

          _jquery.default.get(_environment.default.APP.API_HOST + '/export-excel/' + project.get('num_client'), function () {
            project.set('allowExit', true);
            document.location = _environment.default.APP.LEGACY_HOST + '/admin.php/generate-chd/' + project.get('token') + "?current_user=" + currentUser;
            setTimeout(function () {
              project.set('allowExit', false);
              growl.dismiss(function () {
                growl.remove(function () {
                  _jquery.default.growl.notice({
                    title: 'Fichier excel téléchargé !',
                    message: ''
                  });
                });
              });
            }, 5000);
          }).fail(onFail);
        }
      },
      importInventaire: function () {
        (0, _jquery.default)("#importInventairePopup").modal('show');
      },
      confirmImport: function () {
        var project = this.get('project');
        var importType = this.get('chosenImportType');
        var url = null;

        var _this = this;

        var reset = this.get('importAndReset');

        if (importType == "importFromCRM") {
          url = _environment.default.APP.API_HOST + '/import-inventaire-crm/' + project.get('num_client');
        } else if (importType == "importOldProject") {
          url = _environment.default.APP.API_HOST + '/copy-inventaire/' + project.get('num_client') + '/' + this.get('projectToImport');
        }

        url += "?reset=" + this.get('importAndReset');

        var growl = _jquery.default.growl({
          title: '<img src="/assets/images/ajax_loader.gif" height="24" width="24"/> Import en cours...',
          fixed: true,
          message: ''
        });

        var onFail = function (error) {
          console.error(error);
          var errorTitle = "Erreur réseau";

          if (error.status == "404") {
            errorTitle = "Non trouvé !";
          } else if (error.status == "500") {
            errorTitle = "Erreur serveur 500";
          }

          growl.dismiss(function () {
            growl.remove(function () {
              _jquery.default.growl.error({
                title: errorTitle,
                message: error.responseText
              });
            });
          });
        };

        _jquery.default.get(url, function (result) {
          _this.store.query('project', {
            num_client: project.get('num_client')
          }).then(function () {
            growl.dismiss(function () {
              growl.remove(function () {
                _jquery.default.growl.notice({
                  title: result,
                  message: ''
                }); //IF RESET, DONT SAVE CURRENT PIECE (IT SHOULD HAVE BEEN DELETED)


                if (reset) {
                  _this.set('bypassSavePiece', true);

                  project.set('lastPieceOpened', null);
                } else {
                  _this.set('bypassSavePiece', false);
                } //RESET VALUES TO DEFAULT AND TRANSITION TO INVENTAIRE


                _this.set('importAndReset', true);

                _this.set('projectToImport', null);

                _this.set('chosenImportType', null);

                _this.transitionToRoute('project.inventaire');
              });
            });
          });
        }).fail(onFail);
      },
      fixDistance: function () {
        this.set('modifiedDistance', this.get('project').get('distance'));
        (0, _jquery.default)("#fixDistanceModal").modal('show');
      },
      applyNewDistance: function () {
        var dist = this.get('modifiedDistance');

        if (dist >= 0) {
          var mainEtape = this.get('project').get('mainEtape');
          mainEtape.set('distance', parseInt(dist));
          mainEtape.save().then(function () {
            _jquery.default.growl.notice({
              title: 'Distance mise à jour',
              message: dist + ' km'
            });
          });
        }
      },
      calcDroits: function () {
        var project = this.get('project');
        var url = _environment.default.APP.API_HOST + '/calc-droits/' + project.get('num_client');

        var _this = this;

        var onFail = function (error) {
          console.log(error);

          _jquery.default.growl.error({
            title: error.responseJSON,
            message: ''
          });
        };

        var volume = project.get("volumeTotal");
        var plafondVol = project.get("plafondVol");
        if (plafondVol) volume = Math.min(volume, plafondVol);
        var data = {
          "volume": volume
        };
        this.set('calculatingPrice', true);

        _jquery.default.get(url, data, function (result) {
          _this.set('plafondMinarmPrice', result.plafond_price);
        }).fail(onFail).always(function () {
          _this.set('calculatingPrice', false);
        });
      },
      confirmExit: function () {
        this.transitionToRoute('index');
      }
    }
  });

  _exports.default = _default;
});