define("dem-editor-v2/components/null-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextArea.extend({
    value: Ember.computed('nullValue', {
      get: function () {
        return this.get('nullValue');
      },
      set: function (key, value) {
        if (Ember.isBlank(value)) {
          value = null;
        }

        this.set('nullValue', value);
        return value;
      }
    })
  });

  _exports.default = _default;
});