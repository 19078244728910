define("dem-editor-v2/controllers/project/inventaire", ["exports", "jquery", "ember-data"], function (_exports, _jquery, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    project: Ember.inject.controller('project'),
    selectedAdresseService: null,
    mergeArrayPromises: function (promises, sortBy) {
      var _this = this;

      var promise = Ember.RSVP.all(promises).then(function (arrays) {
        var mergedArray = Ember.A();
        arrays.forEach(function (records) {
          mergedArray.pushObjects(_this.sortByArray.apply(records, sortBy).toArray());
        });
        return mergedArray;
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    },
    sortByArray: function (sortBy) {
      return this.sortBy(sortBy);
    },
    piecesSuggestions: Ember.computed('model.pieces.@each.piece_type', 'project.model.{hasCave,hasGarage}', function () {
      var mostCommonPieces = [Ember.Object.create({
        name: "Entrée",
        key: "hall"
      }), Ember.Object.create({
        name: "Chambre",
        key: "bedroom"
      }), Ember.Object.create({
        name: "Salon",
        key: "living"
      }), Ember.Object.create({
        name: "Cuisine",
        key: "kitchen"
      }), Ember.Object.create({
        name: "Salle de bain",
        key: "bathroom"
      }), Ember.Object.create({
        name: "Cave",
        key: "cellar"
      }), Ember.Object.create({
        name: "Garage",
        key: "garage"
      })];
      var pieces = this.get('model').pieces;
      var project = this.get('project').get('model');
      return mostCommonPieces.filter(function (item) {
        var shouldSuggest = true;

        if (item.get('key') === "cellar") {
          shouldSuggest = project.get('hasCave');

          if (shouldSuggest) {
            item.set('warning', true);
          }
        }

        if (item.get('key') === 'garage') {
          shouldSuggest = project.get('hasGarage');

          if (shouldSuggest) {
            item.set('warning', true);
          }
        }

        return shouldSuggest && Ember.isNone(pieces.findBy('piece_type', item.get('key')));
      });
    }),
    orderedCustomServices: Ember.computed('model.allServices.[]', function () {
      var allServices = this.get('model').allServices;

      if (!Ember.isNone(allServices)) {
        return allServices.filterBy('isDefaultService', false).sortBy('key', 'livraison.order', 'chargement.order', 'type', 'id');
      }

      return [];
    }),
    adresseServices: Ember.computed('model.allServices.[]', function () {
      var allServices = this.get('model').allServices;

      if (!Ember.isNone(allServices)) {
        return allServices.filterBy('isAdresseService', true).sortBy('livraison.order', 'chargement.order', 'type', 'id');
      }

      return [];
    }),
    newPieceName: Ember.computed("newPieceType", "model.pieces.[]", {
      get: function () {
        var pieceType = this.get("newPieceType");

        if (Ember.isNone(pieceType)) {
          return "";
        }

        var newName = pieceType.get('name');
        var queryRegExp = new RegExp(window.escapeRegExp(window.removeAccents(newName)) + "((?= ) \\d+|$)", "i");
        var sameName = this.get('model').pieces.filter(function (item) {
          return queryRegExp.test(window.removeAccents(item.get('name')));
        }).get('length');

        if (sameName > 0) {
          newName = newName + " " + (sameName + 1);
        }

        return newName;
      },
      set: function (key, value) {
        return value;
      }
    }),
    specialServiceName: Ember.computed('specialServiceMeuble', {
      get: function () {
        var specialServiceMeuble = this.get('specialServiceMeuble');
        return Ember.isNone(specialServiceMeuble) ? "" : specialServiceMeuble.get('special_service');
      },
      set: function (key, value) {
        return value;
      }
    }),
    specialServiceType: 1,
    isSpecialServiceInvalid: Ember.computed('specialServiceName', 'specialServiceType', function () {
      return Ember.isBlank(this.get('specialServiceName')) || Ember.isNone(this.get('specialServiceType'));
    }),
    modePrecis: true,
    actions: {
      addSuggestedPiece: function (suggestedPiece) {
        this.set('newPieceType', suggestedPiece);
        this.set('newPieceName', suggestedPiece.get('name'));
        this.send("addPiece");
      },
      addPiece: function () {
        var finalPieceType = "custom";
        var pieceType = this.get('newPieceType');

        if (!Ember.isNone(pieceType)) {
          finalPieceType = pieceType.get('key');
        }

        var newPiece = this.store.createRecord('piece', {
          name: this.get('newPieceName'),
          piece_type: finalPieceType,
          cartons_std: 0,
          cartons_liv: 0,
          divers: 0.00,
          penderies: 0.0,
          meubles: [],
          project: this.get('project').get('model')
        });
        this.set("newPieceType", null);

        var _this = this;

        newPiece.save().then(function (newPieceSaved) {
          _this.transitionToRoute("project.inventaire.piece", newPieceSaved);
        }, function (error) {
          newPiece.deleteRecord();

          _jquery.default.growl.error({
            title: 'Impossible de créer une pièce',
            message: error.message
          });
        });
      },
      deletePiece: function (piece) {
        this.set('selectedPieceToDelete', piece);
        (0, _jquery.default)('#confirmDeletePiece').modal('show');
      },
      modifyPiece: function (piece) {
        var precPiece = this.get('selectedPieceToEdit');

        if (precPiece) {
          precPiece.set('isEditing', false);
        }

        this.set('selectedPieceToEdit', piece);
        piece.set('isEditing', true);
        return false;
      },
      endModifyPiece: function () {
        var piece = this.get('selectedPieceToEdit');

        if (piece) {
          piece.set('isEditing', false);
          this.set('selectedPieceToEdit', null);
        }
      },
      confirmDelete: function () {
        var piece = this.get("selectedPieceToDelete");

        if (!piece) {
          return false;
        }

        var _this = this;

        this.set("selectedPieceToDelete", null);
        var name = piece.get("name");
        var meubles = piece.get('meubles');
        meubles.then(function (loadedMeubles) {
          Ember.RSVP.all(loadedMeubles.invoke('deleteRecord')).then(function () {
            piece.destroyRecord().catch(function (error) {
              console.error("Erreur lors de la suppression d'une pièce");
              console.error(error.message);
            });

            _jquery.default.growl.notice({
              title: 'Pièce supprimée',
              message: name
            });

            _this.transitionToRoute("project.inventaire");
          });
        });
      },
      addSpecialServiceConfirm: function () {
        var meuble = this.get('specialServiceMeuble');
        var newService = this.store.createRecord('service', {
          name: this.get('specialServiceName'),
          type: this.get('specialServiceType'),
          key: "custom",
          chargement: null,
          livraison: null,
          project: this.get('project').get('model'),
          include_vol: this.get('specialServiceType') === 1
        });
        newService.set('color', newService.calcNewColor(0, 0));

        var _this = this;

        newService.save().then(function () {
          meuble.get('services').pushObject(newService);
          meuble.set('isOpened', false);

          _this.set('specialServiceType', 1);

          _this.set('specialServiceMeuble', null);

          _this.get('model').allServices.pushObject(newService);

          _jquery.default.growl.notice({
            title: 'Nouvelle prestation',
            message: newService.get('name')
          });
        }).catch(function (error) {
          console.log("Erreur lors de la création de la nouvelle prestation");
          console.error(error);

          _jquery.default.growl.error({
            title: 'Prestation non créée',
            message: newService.get('name')
          });
        });
      },
      removeSpecialServicesConfirm: function () {
        var services = this.get('specialServicesToRemove');
        services.forEach(function (serv) {
          if (serv.get('toDelete')) {
            var servName = serv.get('name');
            serv.destroyRecord().then(function () {
              _jquery.default.growl.notice({
                title: 'Prestation supprimée',
                message: servName
              });
            }).catch(function (error) {
              console.log("Erreur lors de la suppression d'une prestation");
              console.error(error);

              _jquery.default.growl.error({
                title: 'Prestation non supprimée',
                message: servName
              });
            });
          }
        });
      }
    },
    isNewPieceInvalid: Ember.computed('newPieceName', function () {
      var newName = this.get("newPieceName");
      return Ember.isBlank(newName);
    }),
    adding: false
  });

  _exports.default = _default;
});