define("dem-editor-v2/routes/project/livraison", ["exports", "dem-editor-v2/routes/basemultiadresse"], function (_exports, _basemultiadresse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basemultiadresse.default.extend({
    routeRoot: 'project.livraison',
    type: 2
  });

  _exports.default = _default;
});