define("dem-editor-v2/components/energy-transfer", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    requestedValue: null,
    eligible: true,
    energyUtils: Ember.inject.service('energy-utils'),
    contractsUrl: Ember.computed('project.token', function () {
      return this.get('energyUtils').getContractsUrl(this.get('project').get('token'));
    }),
    init: function () {
      this._super(...arguments);

      this.set('loading', true);
      this.get('energyUtils').requestTransfer(this.get('project'), true).then(data => {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this.set('eligible', data['is_eligible']);
          this.set('loading', false);
        }
      });
    },
    date_chargement: Ember.computed('project.mainEtape.dates_chargement', function () {
      const dateCharg = this.get('project').get('mainEtape').get('dates_chargement');

      if (!Ember.isEmpty(dateCharg)) {
        return dateCharg.get('firstObject').get('date_first');
      }

      return null;
    }),
    actions: {
      confirmContractsTransfer(value) {
        if (value === true) {
          (0, _jquery.default)("#confirmTransferPopup").modal('show');
        } else {
          this.send('updateContractsTransfer', value);
        }
      },

      updateContractsTransfer(value) {
        if (value === null) {
          this.set('requestTransfer', value);
          return;
        }

        this.set('loading', true);

        _jquery.default.post(this.get('contractsUrl'), {
          'requested_transfer': value,
          'made_by': 'EDITOR',
          'dep_date': this.get('date_chargement')
        }, () => {
          if (!this.get('isDestroyed') && !this.get('isDestroying')) {
            this.set('requestTransfer', value);
            this.set('loading', false);
          }
        });
      }

    }
  });

  _exports.default = _default;
});