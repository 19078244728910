define("dem-editor-v2/models/stationnement", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    portage: _emberData.default.attr('string'),
    portage_comment: _emberData.default.attr('string'),
    autorisation: _emberData.default.attr('boolean'),
    autorisation_comment: _emberData.default.attr('string'),
    vl: _emberData.default.attr('number'),
    vl_comment: _emberData.default.attr('string'),
    difficulty: _emberData.default.attr('boolean'),
    difficulty_comment: _emberData.default.attr('string'),
    largeur: _emberData.default.attr('boolean'),
    largeur_comment: _emberData.default.attr('string'),
    hauteur: _emberData.default.attr('boolean'),
    hauteur_comment: _emberData.default.attr('string'),
    marches: _emberData.default.attr('boolean'),
    marches_comment: _emberData.default.attr('string'),
    transbo_dist: _emberData.default.attr('number'),
    adresse: _emberData.default.belongsTo('adresse', {
      inverse: 'stationnements'
    }),
    transboDistanceMissing: Ember.computed('vl', 'transbo_dist', function () {
      return this.get('vl') && !this.get('transbo_dist');
    }),
    hasAccessErrors: Ember.computed('isDeleted', 'portage', 'difficulty', 'difficulty_comment', 'largeur', 'largeur_comment', 'hauteur', 'hauteur_comment', 'marches', 'marches_comment', function () {
      if (this.get('isDeleted')) return false;
      return !this.get('portage') || this.get('difficulty') && !this.get('difficulty_comment') || this.get('largeur') && !this.get('largeur_comment') || this.get('hauteur') && !this.get('hauteur_comment') || this.get('marches') && !this.get('marches_comment');
    })
  });

  _exports.default = _default;
});