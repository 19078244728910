define("dem-editor-v2/components/form-input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-group', 'one-line'],
    classNameBindings: ['inputClass'],
    type: "text",
    refresh: false,
    notNull: false,
    value: Ember.computed('model.isLoaded', 'refresh', {
      get: function () {
        var model = this.get('model');
        return model.get(this.get('field'));
      },
      set: function (key, value) {
        var model = this.get('model');

        if (Ember.isBlank(value)) {
          value = null;
        }

        model.set(this.get('field'), value);
        return value;
      }
    }),
    didInsertElement: function () {
      var _this = this;

      (0, _jquery.default)(this.element).find('input').tooltip({
        title: function () {
          return _this.get('errorMessage');
        },
        placement: "top",
        trigger: 'focus'
      });
    },
    willDestroyElement: function () {
      (0, _jquery.default)(this.element).find('input').tooltip('destroy');
    },
    inputClass: Ember.computed('errorMessage', function () {
      var error = this.get('errorMessage');

      if (error !== null) {
        return "has-error";
      }

      return null;
    }),
    errorMessage: Ember.computed('model.errors.[]', 'value', 'notNull', 'customError', 'disabled', function () {
      var errors = this.get('model').get('errors');

      if (errors != null) {
        var error = errors.findBy("attribute", this.get('field'));

        if (error != null) {
          return error.message;
        }
      }

      if (!this.get("disabled") && this.get('notNull') && Ember.isBlank(this.get('value'))) {
        return "Ce champ ne peut être vide.";
      }

      var customError = this.get('customError');
      if (!Ember.isBlank(customError)) return customError;
      return null;
    })
  });

  _exports.default = _default;
});