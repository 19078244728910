define("dem-editor-v2/models/project-related-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = _emberData.default.Model.extend({
    //TO CONTINUE
    save: function () {
      this.get('project').then(function (project) {
        if (!Ember.isNone(project)) {
          project.set('last_modified', moment.utc().format());
          var userId = project.get('current_user_id');

          if (!Ember.isBlank(userId)) {
            project.set('last_modified_by', userId);
          }
        }
      });
      return this._super();
    }
  });

  _exports.default = _default;
});