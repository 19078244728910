define("dem-editor-v2/components/inv-meuble", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    classNameBindings: ["currentSelectionMatch:highlight", "isMeubleFocused:highlight-select", "isMeubleDeleted:hide", "wasCreated:bounceIn"],
    isMeubleFocused: Ember.computed('model.isFocused', function () {
      return this.get('model').get('isFocused');
    }),
    isMeubleDeleted: Ember.computed('model.isDeleted', function () {
      return this.get('model').get('isDeleted');
    }),
    wasCreated: Ember.computed('model.isNew', function () {
      return this.get('model').get('isNew');
    }),
    currentSelectionMatch: Ember.computed('currentAdresseService', 'model.services', 'searchAdresseService', function () {
      var searchAdresseService = this.get('searchAdresseService');

      if (!searchAdresseService) {
        return false;
      }

      return this.get('model').isPartOfAddressService(this.get('currentAdresseService'));
    }),
    meublesObservationsSuggestions: Object.freeze(["démonté par client", "dépose client", "à décrocher", "déjà démonté", "déjà protégé", "déjà emballé", "emballé par client", "portes vitrées", "marbre", "cristal", "porcelaine", "bois massif", "plateau en verre", "verre", "attention lourd", "à descendre trottoir", "déchetterie", "non démontable", "à venir"]),
    getArrayOfServiceIds: function () {
      var ids = [];
      this.get('model').get('services').forEach(function (serv) {
        ids.pushObject(serv.get('id'));
      });
      return ids;
    },
    previousServicesIds: null,
    //THIS FUNCTION HANDLES THE INPUT SELECTION / TRAVERSAL WITH ARROW KEYS - LEFT, UP, RIGHT, DOWN
    didInsertElement: function () {
      this.set('previousServicesIds', this.getArrayOfServiceIds());

      var _this = this;

      var inputElem = (0, _jquery.default)(this.element).find('input, textarea');
      inputElem.focus(function (event) {
        var elem = (0, _jquery.default)(event.target);

        if (!elem.hasClass('meuble-name')) {
          (0, _jquery.default)(event.target).select();
        }

        _this.get('model').set('isFocused', true);
      });
      inputElem.focusout(function () {
        _this.get('model').set('isFocused', false);
      });
      inputElem.keydown(function (event) {
        if (event.which === 13) {
          (0, _jquery.default)('input.meuble-creator.tt-input').focus();
          return;
        } //LEFT AND RIGHT ARROWS


        if (event.which === 37 || event.which === 39) {
          var pureInput = event.target;
          var jqInput = (0, _jquery.default)(pureInput);
          var selStart = 0,
              selEnd = 0,
              valueLength = 0;

          if (jqInput.attr('type') === 'text' || pureInput.tagName.toLowerCase() === 'textarea') {
            selStart = pureInput.selectionStart;
            selEnd = pureInput.selectionEnd;
            valueLength = jqInput.val().length;
          }

          var nextTd = null;

          if (selStart === 0 && (selEnd === 0 || selEnd === valueLength) && event.which === 37) {
            nextTd = jqInput.closest('td').prevAll('td:has(input:not([tabindex="-1"])), td:has(textarea)').first();
          }

          if (selEnd === valueLength && (selStart === 0 || selStart === valueLength) && event.which === 39) {
            nextTd = jqInput.closest('td').nextAll('td:has(input:not([tabindex="-1"])), td:has(textarea)').first();
          }

          if (nextTd !== null && nextTd !== undefined) {
            var nextInp = nextTd.find('input:not([tabindex="-1"]), textarea').first();

            if (nextInp !== null && nextInp !== undefined) {
              event.preventDefault();
              nextInp.focus();
            }
          }
        } //UP AND DOWN ARROWS


        if (event.which === 38 || event.which === 40) {
          var input = (0, _jquery.default)(event.target);

          if (input.hasClass('tt-input') || input.hasClass('tt-hint')) {
            return;
          }

          event.preventDefault();
          var td = input.closest('td');
          var row = td.parent();
          var nextInput = null;
          var nextRow = null; //UP

          if (event.which === 38) {
            nextRow = row.prevAll('tr:not(.not-a-row, .hide)').first();

            if (Ember.isNone(nextRow) || Ember.isEmpty(nextRow)) {
              return;
            }
          } //DOWN


          if (event.which === 40) {
            nextRow = row.nextAll('tr:not(.not-a-row, .hide)').first();

            if (Ember.isNone(nextRow) || Ember.isEmpty(nextRow)) {
              //FOCUS THE MEUBLE CREATOR FIELD
              (0, _jquery.default)('input.meuble-creator.tt-input').focus();
              return;
            }
          }

          var tdWithClass = 'td.' + td.attr('class').replace(/ /g, '.');
          nextInput = nextRow.find(tdWithClass + ' input, ' + tdWithClass + ' textarea').first();

          if (nextInput) {
            nextInput.focus(); //SCROLL TO ELEMENT

            var invDiv = (0, _jquery.default)("#inventaire").parent();
            var elemOffset = nextRow.offset().top;
            var invDivOffset = invDiv.offset().top;
            var relativePosition = elemOffset - invDivOffset;
            var isVisible = 0 <= relativePosition && relativePosition + nextRow.height() <= invDiv.height();

            if (!isVisible) {
              var scrollDiff = relativePosition > 0 ? relativePosition + nextRow.height() - invDiv.height() : relativePosition;
              invDiv.scrollTop(invDiv.scrollTop() + scrollDiff);
            }
          }
        }
      });
    },

    makeMeubleDirty() {
      this.get('model').send('becomeDirty');
    },

    hasServicesChanged: Ember.observer('model.services.[]', function () {
      var model = this.get("model");
      var services = this.getArrayOfServiceIds();
      var previousServices = this.get('previousServicesIds');
      this.set('previousServicesIds', services);

      if (!model.get("hasDirtyAttributes") && !model.get('isDeleted')) {
        var newServ = services.find(function (id) {
          return !previousServices.includes(id);
        });
        var lostServ = previousServices.find(function (id) {
          return !services.includes(id);
        });

        if (!Ember.isNone(newServ) || !Ember.isNone(lostServ)) {
          Ember.run.scheduleOnce('afterRender', this, this.makeMeubleDirty);
        }
      }
    }),
    servicesOrderBy: Object.freeze(['chargement.order', 'livraison.order', 'type', 'id']),
    orderedServices: Ember.computed.sort('model.services', 'servicesOrderBy'),
    volume: Ember.computed("model.volume", {
      get: function () {
        return this.get('model').get('volume');
      },
      set: function (key, value) {
        var model = this.get('model'); //SETTER

        var pattern = /^\d+(\.\d\d*)?$/;

        if (pattern.test(value)) {
          var floatVal = Math.round(parseFloat(value) * 100) / 100;
          model.set('volume', floatVal);
          return floatVal;
        }

        return value;
      }
    }),
    //SI ON MODIFIE LA QUANTITE, ON MODIFIE LE VOLUME
    quantity: Ember.computed("model.quantity", {
      get: function () {
        return this.get('model').get('quantity');
      },
      set: function (key, value) {
        var model = this.get('model');
        var oldValue = Math.round(parseFloat(model.get("quantity") * 100) / 100);
        var newValue = Math.round(parseFloat(value * 100) / 100);

        if (newValue !== oldValue) {
          this.set("volume", Math.round(parseFloat(newValue / this.get("quantity_unit") * this.get("volume_type")) * 100) / 100);
          model.set("quantity", newValue);
        }

        return value;
      }
    }),
    volume_type: Ember.computed('model.{type.volume_type,volume_type_from_dimensions}', function () {
      var meubleType = this.get("model").get("type");

      if (meubleType && meubleType !== undefined && meubleType.get('content') !== null) {
        return meubleType.get("volume_type");
      }

      const volType = this.get('model').get('volume_type_from_dimensions');

      if (volType && !isNaN(volType)) {
        return volType;
      }

      return 0.25;
    }),
    volume_unit: Ember.computed('model.type.volume_type', function () {
      return Math.min(this.get("volume_type"), 0.25);
    }),
    quantity_unit: Ember.computed('model.type.quantite_type', function () {
      var meubleType = this.get("model").get("type");

      if (meubleType && meubleType !== undefined && meubleType.get('content') != null) {
        return meubleType.get("quantite_type");
      }

      return 1;
    }),

    /* eslint-disable ember/no-on-calls-in-components */
    openDetailsPanel: Ember.on('init', Ember.observer('model.isOpened', function () {
      var model = this.get('model');

      if (model.get("isOpened") === true) {
        var lastMeubleOpened = model.get("piece").get("lastMeubleOpened");

        if (lastMeubleOpened != null && lastMeubleOpened !== model) {
          lastMeubleOpened.set("isOpened", false);
        }

        model.get("piece").set("lastMeubleOpened", model);
      }
    })),

    /* eslint-enable ember/no-on-calls-in-components */
    actions: {
      openDetails: function () {
        var model = this.get('model');
        model.set("isOpened", !model.get("isOpened"));
      },
      showServiceChooser: function () {
        this.showServiceChooser(this.get("model"));
      },
      deleteMeuble: function () {
        this.deleteMeuble(this.get("model"));
      }
    }
  });

  _exports.default = _default;
});