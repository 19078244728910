define("dem-editor-v2/components/service-chooser", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    meuble: null,
    tagName: 'table',
    classNames: ['service-chooser'],
    outerListener: null,

    init() {
      this._super(...arguments);

      this.allServices = this.allServices || [];
    },

    selector: Ember.computed('id', function () {
      return '[data-popover-target="' + this.get("id") + '"]';
    }),
    didInsertElement: function () {
      var that = this;
      var selector = this.get('selector'); //INIT POPOVER

      (0, _jquery.default)('#piece-editor').popover({
        html: true,
        selector: selector,
        placement: 'right',
        container: 'body',
        animation: false,
        content: function () {
          return (0, _jquery.default)(that.element);
        }
      });

      var listener = function (e) {
        var container = (0, _jquery.default)('#' + that.get('id')); // if the target of the click isn't the contain

        if (!container.is(e.target) && container.has(e.target).length === 0) {
          (0, _jquery.default)(selector).popover('destroy');
        }
      }; //HIDE POPOVER WHEN CLICK OUTSIDE


      (0, _jquery.default)('html').click(listener);
      this.set('outerListener', listener);
    },
    willDestroyElement: function () {
      (0, _jquery.default)(this.get('selector')).popover('destroy');
      (0, _jquery.default)('html').unbind('click', this.get('outerListener'));
    },
    getServices: Ember.computed('meubles.[]', function () {
      var result = [];
      var ServiceSelector = Ember.Object.extend({
        service: null,
        chooserId: Ember.computed('service', function () {
          return "serv-chooser-" + this.get('service').get('id');
        }),
        state: Ember.computed('service', function () {
          var ser = this.get('service');
          var isChosen = false;
          var meubles = this.get('meubles');
          var m = meubles.filter(function (meuble) {
            if (meuble.get('services').includes(ser)) {
              isChosen = true;
              return true;
            }

            return false;
          });
          return Ember.Object.create({
            isDisabled: !(m.get('length') === 0 || m.get('length') === meubles.get('length')),
            isSelected: isChosen
          });
        }),
        isDisabled: Ember.computed('state', 'isSelected', 'meubles.@each.not_deleted_services', function () {
          var isDisabled = this.get('state').get('isDisabled');

          if (isDisabled) {
            return true;
          }

          var isChosen = this.get('isSelected');
          var meubles = this.get('meubles');

          if (isChosen) {
            var ser = this.get('service');

            if (ser.get('isAdresseService')) {
              var m1 = meubles.find(function (meuble) {
                var adresseService = meuble.get('not_deleted_services').find(function (serv) {
                  return serv !== ser && serv.get('isAdresseService');
                });
                return !Ember.isNone(adresseService);
              });
              return Ember.isNone(m1);
            }
          }

          return isDisabled;
        }),
        isSelected: Ember.computed("state", {
          get: function () {
            return this.get('state').get('isSelected');
          },
          set: function (key, value) {
            var ser = this.get('service');
            var meubles = this.get('meubles');
            meubles.forEach(function (meuble) {
              var services = meuble.get('services');

              if (value && !services.includes(ser)) {
                services.pushObject(ser);
              }

              if (!value && services.includes(ser)) {
                meuble.get('services').removeObject(ser);
              }
            });
            return value;
          }
        })
      });
      var meubles = this.get('meubles');
      this.get('allServices').forEach(function (ser) {
        var selector = ServiceSelector.create();
        selector.set("service", ser);
        selector.set("meubles", meubles);
        result.pushObject(selector);
      });
      return result;
    })
  });

  _exports.default = _default;
});