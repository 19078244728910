define("dem-editor-v2/models/demenageur", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    diminutif: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    postal: _emberData.default.attr('string'),
    minarm_access: _emberData.default.attr('boolean'),
    dgrs_type_volmax_smalldist: _emberData.default.attr('number'),
    dgrs_type_volmax_longdist: _emberData.default.attr('number')
  });

  _exports.default = _default;
});