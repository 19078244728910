define("dem-editor-v2/components/calc-assurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tauxAssuranceMin: Ember.computed('type_assurance', function () {
      var type = this.get("type_assurance");

      if (type === "CLASSIQUE") {
        return 0.2;
      } else if (type === "COMPLEMENTAIRE") {
        return 0.5;
      }

      return 0;
    }),
    tauxAssuranceMax: Ember.computed('type_assurance', function () {
      var type = this.get("type_assurance");

      if (type === "CLASSIQUE") {
        return 0.4;
      } else if (type === "COMPLEMENTAIRE") {
        return 0.7;
      }

      return 0;
    }),
    valueAssuranceMin: Ember.computed('tauxAssuranceMin', 'value_assurance', function () {
      return Math.round(this.get('tauxAssuranceMin') / 100 * this.get('value_assurance'));
    }),
    valueAssuranceMax: Ember.computed('tauxAssuranceMax', 'value_assurance', function () {
      return Math.round(this.get('tauxAssuranceMax') / 100 * this.get('value_assurance'));
    })
  });

  _exports.default = _default;
});