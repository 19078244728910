define("dem-editor-v2/serializers/meuble-category", ["exports", "dem-editor-v2/serializers/drf", "ember-data"], function (_exports, _drf, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      meubles_type: {
        serialize: 'ids',
        deserialize: 'records'
      },
      sub_categories: {
        serialize: 'ids',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});