define("dem-editor-v2/controllers/project/dates", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    project: Ember.inject.controller('project'),
    selectedDateRange: null,
    availableServices: Ember.computed('project.model.services.isFulfilled', function () {
      return this.get('project').get('model').get('services').filterBy('key', 'address').sortBy('chargement.order', 'livraison.order', 'id');
    }),
    defaultService: Ember.computed('availableServices.[]', function () {
      return this.get('availableServices').get('firstObject');
    }),
    datesChargement: Ember.computed('model.@each.type', function () {
      return this.get('model').filterBy('type', 1);
    }),
    datesLivraison: Ember.computed('model.@each.type', function () {
      return this.get('model').filterBy('type', 2);
    }),
    sortedDates: Ember.computed('model.@each.{type,services}', function () {
      var dates = this.get('model');
      var processedDates = [];
      var result = [];
      dates.forEach(function (date) {
        if (!processedDates.includes(date)) {
          var dateGroup = Ember.Object.create();
          var dateResult = [];
          dateResult[0] = [];
          dateResult[1] = [];
          dateResult[date.get('type') - 1].pushObject(date);
          processedDates.pushObject(date);
          var services = date.get('services');
          dates.forEach(function (correspondingDate) {
            if (!processedDates.includes(correspondingDate)) {
              var corresponds = true;
              var correspondingServices = correspondingDate.get('services');
              services.forEach(function (serv) {
                if (!correspondingServices.includes(serv)) {
                  corresponds = false;
                }
              });

              if (corresponds && services.get('length') === correspondingServices.get('length')) {
                dateResult[correspondingDate.get('type') - 1].pushObject(correspondingDate);
                processedDates.pushObject(correspondingDate);
              }
            }
          });
          dateGroup.set('dateranges', dateResult);
          dateGroup.set('services', services);
          result.pushObject(dateGroup);
        }
      });
      return result;
    }),

    updateServices(dateRange) {
      var tempServices = dateRange.get('tempServices');
      dateRange.set('tempServices', undefined);
      dateRange.set('services', tempServices);
      dateRange.set('animate', true);
    },

    actions: {
      addDates: function (dateType, services) {
        if (!services) {
          services = this.get('availableServices');
        }

        Ember.run.next(this, function () {
          this.store.createRecord("daterange", {
            type: dateType,
            date_first: moment().format("YYYY-MM-DD"),
            project: this.get('project').get('model'),
            services: services,
            animate: true
          });
        });
      },
      removeDates: function () {
        var daterange = this.get('selectedDatesToDelete');

        if (daterange) {
          daterange.deleteRecord();
          daterange.save().then(function () {
            _jquery.default.growl.notice({
              title: 'Dates supprimées',
              message: ''
            });
          }).catch(function (error) {
            _jquery.default.growl.error({
              title: 'Dates non supprimées',
              message: error.message
            });
          });
        }

        this.set('selectedDatesToDelete', null);
      },
      confirmDeleteDates: function (daterange) {
        this.set('selectedDatesToDelete', daterange);
        (0, _jquery.default)('#confirmDeleteDates').modal('show');
      },
      copyDates: function (daterange) {
        Ember.run.next(this, function () {
          var newRecord = this.store.createRecord("daterange", {
            type: daterange.get('type') === 1 ? 2 : 1,
            date_first: daterange.get('date_first'),
            date_last: daterange.get('date_last'),
            project: this.get('project').get('model'),
            services: daterange.get('services'),
            chosen_by_client: daterange.get('chosen_by_client'),
            other_dates: daterange.get('other_dates'),
            ideale: daterange.get('ideale'),
            groupage: daterange.get('groupage'),
            animate: true
          });

          if (newRecord.get('ideale')) {
            this.send('resetDateIdeale', newRecord);
          }
        });
      },
      resetDateIdeale: function (daterange) {
        var type = daterange.get('type');
        var services = daterange.get('services').get('content');
        var toReset = this.get('model').filterBy('type', type).filter(function (dates) {
          if (dates !== daterange) {
            var containsServices = dates.get('services').find(function (ser) {
              return services.includes(ser);
            });
            return !Ember.isNone(containsServices);
          }

          return false;
        });
        toReset.forEach(function (item) {
          item.set("ideale", false);
        });
      },
      showEtapeChooser: function (daterange) {
        //VALIDATE CHANGES FROM PREVIOUS SELECTED DATE RANGE
        var selectedDateRange = this.get('selectedDateRange');

        if (selectedDateRange === daterange) {
          return false;
        }

        (0, _jquery.default)('[data-popover-target="etapeChooser"]').popover('destroy');
        this.send('validateServiceChanges', selectedDateRange);
        this.set('selectedDateRange', daterange);
        Ember.run.next(this, function () {
          var chooserToShow = '#' + daterange.get('etapeChooserId');
          (0, _jquery.default)(chooserToShow).attr('data-popover-target', 'etapeChooser');
          (0, _jquery.default)(chooserToShow).click();
        });
      },
      validateServiceChanges: function (dateRange) {
        if (Ember.isNone(dateRange)) {
          return false;
        }

        var tempServices = dateRange.get('tempServices'); //NO CHANGES OR ALREADY APPLIED

        if (Ember.isNone(tempServices)) {
          return false;
        }

        Ember.run.scheduleOnce('afterRender', this, this.updateServices, dateRange);
      }
    }
  });

  _exports.default = _default;
});