define("dem-editor-v2/components/service-type-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'td',
    disabled: false,
    isDefaultService: false,
    classNames: ['service-type-chooser'],
    classNameBindings: ['isDefaultService:default-service'],
    attributeBindings: ['rowspan'],
    isService: Ember.computed('type', function () {
      return this.get('type') === 1;
    }),
    isOption: Ember.computed('type', function () {
      return this.get('type') === 2;
    }),
    actions: {
      setServiceType: function (newType) {
        if (!this.get('disabled')) {
          if (newType === this.get('type') && this.get('isDefaultService')) {
            newType = 0;
          }

          this.set('type', newType);
        }
      }
    }
  });

  _exports.default = _default;
});