define("dem-editor-v2/components/auto-complete-meuble", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Handlebars, Fuse */
  var _default = Ember.TextField.extend({
    classNames: ["typeahead", "meuble-creator"],
    didInsertElement: function () {
      var _this = this;

      var maxResults = 5;
      var typeahead = (0, _jquery.default)(this.element).typeahead({
        minLength: 1,
        highlight: true,
        autoselect: false
      }, {
        name: "typeahead",
        source: function (query, process) {
          var myResults = _this.get("choices");

          var results = myResults.filter(function (item) {
            return parseInt(item.get('id')) > 3;
          }).map(function (item) {
            return {
              value: item.get('nom_type'),
              icon: Ember.isNone(item.get('icon')) ? 'default' : item.get('icon'),
              volume: item.get('volume_type'),
              emberObject: item
            };
          });
          var options = {
            keys: ["value"],
            minMatchCharLength: 3,
            findAllMatches: true,
            tokenize: true,
            shouldSort: true
          };
          var fuse = new Fuse(results, options);
          var result = fuse.search(window.removeAccents(query));
          process(result.slice(0, maxResults));
        },
        templates: {
          suggestion: Handlebars.compile('<p class="meuble-suggestions">{{value}}<span style="float:right;" class="icon {{icon}}"></span><span style="float: right;">{{volume}} m3</span></p>')
        }
      });
      typeahead.on("typeahead:selected", function (event, item) {
        _this.createMeuble(item.emberObject);
      });
      typeahead.on("typeahead:autocompleted", function (event, item) {
        _this.set('selection', item);
      });
      (0, _jquery.default)(this.element).keydown(function (event) {
        var input = (0, _jquery.default)(event.target);

        if (!input.val()) {
          //UP - SELECT LAST CREATED MEUBLE
          if (event.which === 38) {
            event.preventDefault();
            (0, _jquery.default)('#inventaire tr:not(.not-a-row, .hide) td.quantity input').last().focus();
            return false;
          }

          if (event.which === 39) {
            event.preventDefault();
            input.closest('td').next().find('input').first().focus();
            return false;
          }
        }
      }); //ADD CTRL+SPACE SHORTCUT TO FOCUS THE MEUBLE CREATION

      var isCtrl = false;
      (0, _jquery.default)(document).keyup(function (e) {
        if (e.which === 17) {
          isCtrl = false;
        }
      }).keydown(function (e) {
        if (e.which === 17) {
          isCtrl = true;
        }

        if (e.which === 32 && isCtrl === true) {
          (0, _jquery.default)(_this.element).focus();
          return false;
        }
      });
    },
    willDestroyElement: function () {
      (0, _jquery.default)(this.element).typeahead('destroy');
    },
    actions: {},
    createMeuble: function (meuble) {
      this.onSelect(meuble);
      this.set("selection", null);
      (0, _jquery.default)(this.element).typeahead('val', null);
    },
    insertNewline: function () {
      var val = (0, _jquery.default)(this.element).typeahead('val');
      var selection = this.get('selection');

      if (selection && selection.value === val) {
        this.createMeuble(selection.emberObject);
      } else if (val !== "" && val != null) {
        this.createMeuble(val);
      }
    }
  });

  _exports.default = _default;
});