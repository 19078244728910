define("dem-editor-v2/templates/components/gm-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sbsJhQ6N",
    "block": "{\"symbols\":[\"xs\",\"opt\"],\"statements\":[[7,\"td\",true],[10,\"class\",\"service-name service-gm-editor\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"form-inline service-type-chooser\"],[10,\"style\",\"display: flex\"],[8],[0,\"\\n\"],[4,\"x-select\",null,[[\"value\",\"onChange\",\"class\"],[[24,[\"gmData\",\"duration\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"gmData\",\"duration\"]]],null]],null],\"form-control flex-grow\"]],{\"statements\":[[4,\"each\",[[23,0,[\"durationOptions\"]]],null,{\"statements\":[[0,\"\\t\\t    \"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"option\"]],\"expected `xs.option` to be a contextual component but found a string. Did you mean `(component xs.option)`? ('dem-editor-v2/templates/components/gm-editor.hbs' @ L5:C9) \"],null]],[[\"value\"],[[23,2,[\"value\"]]]],{\"statements\":[[1,[23,2,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n\\t\\t\"],[1,[28,\"boolean-toggle\",null,[[\"class\",\"toggleText\",\"value\",\"onChange\"],[\"gm-toggle form-control\",\"RELIVR.\",[24,[\"gmData\",\"relivraison\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"gmData\",\"relivraison\"]]],null]],null]]]],false],[0,\"\\n\\t\\t\"],[1,[28,\"boolean-toggle\",null,[[\"class\",\"toggleText\",\"value\",\"onChange\"],[\"gm-toggle form-control\",\"EXTERNE\",[24,[\"gmData\",\"externe\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"gmData\",\"externe\"]]],null]],null]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[1,[28,\"input\",null,[[\"type\",\"autocomplete\",\"value\",\"class\",\"placeholder\"],[\"text\",\"KO\",[24,[\"gmData\",\"comment\"]],\"form-control gm-comment\",\"Commentaires\"]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/gm-editor.hbs"
    }
  });

  _exports.default = _default;
});