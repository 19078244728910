define("dem-editor-v2/models/service", ["exports", "ember-data", "dem-editor-v2/models/project-related-model"], function (_exports, _emberData, _projectRelatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global randomColor, getEtapeServiceName */
  var _default = _projectRelatedModel.default.extend({
    key: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    color: _emberData.default.attr('string'),
    include_vol: _emberData.default.attr('boolean'),
    distance: _emberData.default.attr('number'),
    gm_data: _emberData.default.attr('json'),
    meubles: _emberData.default.hasMany('meuble', {
      inverse: 'services',
      async: true
    }),
    project: _emberData.default.belongsTo('project', {
      async: true,
      inverse: "services"
    }),
    dateranges: _emberData.default.hasMany('daterange', {
      async: true,
      inverse: "services"
    }),
    chargement: _emberData.default.belongsTo('adresse', {
      async: true,
      inverse: "services_chargement"
    }),
    livraison: _emberData.default.belongsTo('adresse', {
      async: true,
      inverse: "services_livraison"
    }),
    isGMService: Ember.computed('gm_data', function () {
      return !Ember.isBlank(this.get('gm_data'));
    }),
    calcNewColor: function (chargement_order, livraison_order) {
      if (chargement_order === 0 && livraison_order === 0) {
        return randomColor({
          seed: this.get('name'),
          hue: this.get('type') === 1 ? 'blue' : 'red'
        }).substring(1);
      }

      return randomColor({
        seed: 1 + (chargement_order - 1) * 1000 + (livraison_order - 1) * 10,
        luminosity: 'dark'
      }).substring(1);
    },
    updateColor: function () {
      var chargement_order = 0;
      var livraison_order = 0;

      if (this.get('isAdresseService')) {
        var charg = this.get('chargement');
        var livr = this.get('livraison');

        if (!charg.get('isFulfilled') && !charg.content || !livr.get('isFulfilled') && !livr.content) {
          return;
        }

        chargement_order = this.get('chargement_order');
        livraison_order = this.get('livraison_order'); //ADD 10 IF WE HAVE INVERSE CHARGEMENT AND LIVRAISON TO GENERATE NEW COLORS

        if (charg.get("type") === 2) {
          chargement_order += 4;
        }

        if (livr.get("type") === 1) {
          livraison_order += 4;
        }
      }

      this.set('color', this.calcNewColor(chargement_order, livraison_order));
    },
    addressColorWatcher: Ember.observer('chargement.order', 'livraison.order', 'type', function () {
      if (!this.get('isDefaultService') && !this.get('isDeleted')) {
        Ember.run.scheduleOnce('actions', this, 'updateColor');
      }
    }),
    updateServiceName: function () {
      if (this.get('chargement').get('hasDirtyAttributes') || this.get('livraison').get('hasDirtyAttributes') || this.get('editing')) {
        this.set('name', getEtapeServiceName(this.get('chargement'), this.get('livraison'), this));
        this.set('distance', null);
      }
    },
    addressOrderAndDescriptorWatcher: Ember.observer('chargement.{order,adresse_descriptor}', 'livraison.{order,adresse_descriptor}', function () {
      if (this.get('isAdresseService') && !this.get('isDeleted')) {
        Ember.run.scheduleOnce('actions', this, 'updateServiceName');
      }
    }),
    dates_chargement: Ember.computed('dateranges.@each.{type,isDeleted}', function () {
      return this.get('dateranges').filterBy('type', 1).filterBy('isDeleted', false);
    }),
    dates_livraison: Ember.computed('dateranges.@each.{type,isDeleted}', function () {
      return this.get('dateranges').filterBy('type', 2).filterBy('isDeleted', false);
    }),
    chargement_order: Ember.computed('chargement.order', function () {
      var charg = this.get('chargement');

      if (charg.content) {
        return charg.get('order');
      }

      return 0;
    }),
    livraison_order: Ember.computed('livraison.order', function () {
      var livr = this.get('livraison');

      if (livr.content) {
        return livr.get('order');
      }

      return 0;
    }),
    isLongDistanceStep: Ember.computed('chargement', 'livraison', function () {
      var charg = this.get('chargement');
      var livr = this.get('livraison');

      if (charg && livr) {
        var postalDep = charg.get('postal');
        var postalArr = livr.get('postal');

        if (postalDep && postalArr && postalDep.length > 2 && postalArr.length > 2) {
          postalDep = postalDep.substr(0, 2);
          postalArr = postalArr.substr(0, 2);
          var idf = ['75', '77', '78', '91', '92', '93', '94', '95'];

          if (idf.indexOf(postalDep) >= 0 && idf.indexOf(postalArr) >= 0) {
            return false;
          }

          return postalDep !== postalArr;
        }

        return true;
      }

      return false;
    }),
    getAddressOfType: function (type) {
      return type === 1 ? this.get('chargement') : this.get('livraison');
    },
    descriptionString: Ember.computed('adressesDescriptionString', 'name', function () {
      return this.get('name') + ' (' + this.get('adressesDescriptionString') + ')';
    }),
    adressesDescriptionString: Ember.computed('chargement', 'livraison', function () {
      var charg = this.get('chargement');
      var livr = this.get('livraison');
      var chargPrefix = charg.get('type') === 1 ? 'C' : 'L';
      var livrPrefix = livr.get('type') === 1 ? 'C' : 'L';
      return '[' + chargPrefix + charg.get('order') + '] ' + charg.get('adresse_descriptor') + ' => [' + livrPrefix + livr.get('order') + '] ' + livr.get('adresse_descriptor');
    }),
    isDefaultService: Ember.computed('key', function () {
      return this.get('key') !== "custom" && this.get('key') !== "address";
    }),
    type_name: Ember.computed("type", function () {
      return this.get('type') === 1 ? "SERVICE" : "OPTION";
    }),
    volume_total: Ember.computed("meubles.@each.{volume,isDeleted,counted_volume}", function () {
      var total = 0;
      var volumeToCount = this.get('isMainService') ? "counted_volume" : "volume";
      this.get('meubles').forEach(function (item) {
        if (!item.get('isDeleted')) {
          total += Math.round(item.get(volumeToCount) * 100) / 100;
        }
      });
      return Math.round(total * 10) / 10;
    }),
    isAdresseService: Ember.computed('key', function () {
      return this.get('key') === 'address';
    }),
    isMainService: Ember.computed('isAdresseService', 'livraison_order', 'chargement_order', function () {
      return this.get('isAdresseService') && this.get('livraison_order') === 1 && this.get('chargement_order') === 1;
    }),
    isDeletable: Ember.computed('chargement.services_chargement.[]', 'livraison.services_livraison.[]', function () {
      return this.get('chargement').get('services_chargement').get('length') > 1 && this.get('livraison').get('services_livraison').get('length') > 1;
    }),
    volume_total_text: Ember.computed("volume_total", function () {
      var total = this.get('volume_total');

      if (total === 0) {
        return "";
      }

      return total + " m3";
    }),
    stepSpecificDates: Ember.computed('dateranges.@each.services', function () {
      return this.get('dateranges').filter(function (dates) {
        return dates.get('services').get('length') <= 1;
      });
    }),
    becomeDirty: function () {
      this.send('becomeDirty');
    },
    notDeletedMeubles: Ember.computed('meubles.@each.isDeleted', function () {
      return this.get('meubles').filterBy('isDeleted', false);
    }),
    adresseChangeObserver: Ember.observer('chargement', 'livraison', function () {
      if (!this.get('chargement').get('isFulfilled') || !this.get('livraison').get('isFulfilled')) {
        return;
      }

      if (!this.get("hasDirtyAttributes") && !this.get('isDeleted')) {
        Ember.run.once(this, 'becomeDirty');
      }
    })
  });

  _exports.default = _default;
});