define("dem-editor-v2/routes/project/inventaire/piece", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function (controller, model) {
      controller.set('model', model);
      controller.set('modifiedMeubles', []);
      this.controllerFor('project.inventaire').set('adding', false);
      this.controllerFor('project').set('bypassSavePiece', false);
    },
    isTransitioning: false,
    model: function (params) {
      return this.store.findRecord("piece", params.piece_id);
    },
    afterModel: function (piece) {
      this.modelFor('project').set('lastPieceOpened', piece);
    },
    renderTemplate: function () {
      this.render('project.inventaire.piece');
      this.render('project.inventaire.menu', {
        // the template to render
        into: 'project.menu',
        // the template to render into
        outlet: 'specificMenu',
        // the name of the outlet in that template
        controller: this.get('controller') // the controller to use for the template

      });
    },
    actions: {
      error: function (error) {
        _jquery.default.growl.error({
          title: 'Pièce non trouvée',
          message: ''
        });

        console.error(error);
        this.transitionTo('project.inventaire');
      },
      willTransition: function (transition) {
        var model = this.get('controller').get('model'); //IF MODEL ALREADY DELETED, DON'T PREVENT TRANSITION (IT SHOULD TRANSITION FROM OUR CODE IN DELETE PIECE)

        if (model.get('isDeleted')) {
          return;
        }

        var projectController = this.controllerFor('project');

        if (projectController && projectController.get('bypassSavePiece')) {
          projectController.set('bypassSavePiece', false);
          return;
        }

        var objectsToSave = model.get('meubles').filterBy('hasDirtyAttributes', true); //ADD PIECE TO SAVE IF SOME MEUBLES WERE MODIFIED SO THAT THE PROJECT IS AWARE OF THE MODIFICATION THROUGH THE PIECE

        var saveModel = model.get('hasDirtyAttributes') || model.get('isNew') || objectsToSave.get('length') > 0;

        if (saveModel) {
          model.send('becomeDirty', true); //IF GOING OUT OF INVENTAIRE, LET THE WILL TRANSITION CODE FROM THE INVENTAIRE ROUTE DO THE JOB

          if (transition.targetName.indexOf('project.inventaire.piece') < 0) {
            return true;
          }

          transition.abort();
          var controller = this.get('controller');
          controller.set('isInLoadingRoute', true);
          this.intermediateTransitionTo('project.inventaire.loading');

          var _this = this;

          var afterFunction = function () {
            //IF WE LEFT THE LOADING ROUTE, A TRANSITION ALREADY HAPPENED SO DON'T TRANSITION (THE USER LEFT...)
            if (!controller.get('isInLoadingRoute')) {
              return;
            }

            var pieceId = transition.to.params.piece_id;

            if (pieceId) {
              _this.transitionTo(transition.to.name, pieceId);
            } else {
              var newPiece = transition.intent.contexts[0];

              _this.transitionTo(transition.to.name, newPiece);
            }
          }; //Save piece and then finish the transition


          model.save().then(function (model) {
            var meubles = model.get('meubles');
            meubles.filterBy('isNew', true).forEach(function (item) {
              item.deleteRecord();
            });
            afterFunction();
          }).catch(function (error) {
            _jquery.default.growl.error({
              title: 'Pièce non enregistrée',
              message: error.message,
              fixed: true
            });

            afterFunction();
          });
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});