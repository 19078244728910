define("dem-editor-v2/models/appoff", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    date_best: _emberData.default.attr('string'),
    date_end: _emberData.default.attr('string'),
    urgent: _emberData.default.attr('boolean'),
    subject: _emberData.default.attr('string'),
    reponses: _emberData.default.attr('raw'),
    mail_content: _emberData.default.attr('string'),
    demenageurs: _emberData.default.hasMany('demenageur', {
      async: true
    }),
    project: _emberData.default.belongsTo('project', {
      inverse: "appoff"
    }),
    get_reponse_for_demenageur: function (demenageur_id) {
      var rep = this.get('reponses').findBy('demenageur_id', demenageur_id);

      if (!Ember.isNone(rep)) {
        return rep;
      }

      return null;
    }
  });

  _exports.default = _default;
});