define("dem-editor-v2/components/street-view", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global google */
  var _default = Ember.Component.extend({
    classNameBindings: ['isHidden:hidden'],
    geocoder: null,
    panorama: null,
    distanceService: null,
    desactivateStreetView: true,
    okForRefresh: true,
    timer: null,
    isLoading: true,
    firstRefresh: true,
    refreshTimer: Ember.observer("address", "distanceOnly", function () {
      var timer = this.get("timer");

      if (timer != null) {
        clearTimeout(timer);
      }

      this.set("okForRefresh", false);

      var _this = this;

      timer = setTimeout(function () {
        _this.set("okForRefresh", true);
      }, 1400);
      this.set("timer", timer);
    }),
    didRender: function () {
      var _this = this;

      if (!window.google || !window.google.maps) {
        window.initMaps = function () {
          _this.initStreetViewElements();

          _this.show();
        }; //CALL GOOGLE MAPS WITH API KEY


        var key = _environment.default.APP.GOOGLE_MAPS_KEY;

        if (key !== "") {
          key = "key=" + key + "&";
        }

        _jquery.default.getScript("https://maps.googleapis.com/maps/api/js?" + key + "callback=initMaps");

        return;
      }

      Ember.run.next(this, function () {
        if (!this.get('isDestroying')) {
          this.initStreetViewElements();
          this.show();
        }
      });
    },
    willDestroyElement: function () {
      clearTimeout(this.get("timer")); //TODO VOIR SI CA CHANGE QQCH

      (0, _jquery.default)(this.element).html('');
      var old_element = this.element;
      var new_element = old_element.cloneNode(true);
      old_element.parentNode.replaceChild(new_element, old_element);
    },
    initStreetViewElements: function () {
      var distService = this.get("distanceService");

      if (Ember.isNone(distService)) {
        if (!this.get('desactivateStreetView')) {
          this.set("geocoder", new google.maps.Geocoder());
          var element = (0, _jquery.default)("#pano").get(0);
          this.set("panorama", new google.maps.StreetViewPanorama(element));
        }

        this.set('distanceService', new google.maps.DistanceMatrixService());
      }
    },
    updateDistance: function (firstRefresh) {
      var _this = this;

      var origins = [this.get('address')];
      var destinations = [this.get('address')];
      var etapesChargement = this.get('etapesChargement');
      var etapesLivraison = this.get('etapesLivraison');
      var indexChargements = [];
      var indexLivraisons = [];
      etapesChargement.forEach(function (etape, i) {
        //IF FIRST REFRESH, ONLY UPDATE IF DISTANCE = NULL
        if (!firstRefresh || Ember.isBlank(etape.get('distance'))) {
          destinations.pushObject(etape.get('livraison').get('fullTextAddress'));
          indexChargements.pushObject(i);
        }
      });
      etapesLivraison.forEach(function (etape, i) {
        //IF FIRST REFRESH, ONLY UPDATE IF DISTANCE = NULL
        if (!firstRefresh || Ember.isBlank(etape.get('distance'))) {
          origins.pushObject(etape.get('chargement').get('fullTextAddress'));
          indexLivraisons.pushObject(i);
        }
      }); //DONT PROCEED IF WE DON'T HAVE ANYTHING TO UPDATE

      if (origins.get('length') <= 1 && destinations.get('length') <= 1) {
        return;
      }

      var params = {
        origins: origins,
        destinations: destinations,
        travelMode: google.maps.TravelMode.DRIVING,
        avoidFerries: true
      };
      this.get('distanceService').getDistanceMatrix(params, function (response, status) {
        if (_this.get('isDestroying')) {
          return;
        }

        if (status === google.maps.DistanceMatrixStatus.OK) {
          var chargementResults = response.rows[0].elements;
          chargementResults.forEach(function (result, i) {
            if (i > 0 && i <= indexChargements.get('length')) {
              var distance;

              if (Ember.isNone(result.distance) || result.status !== 'OK') {
                _jquery.default.growl.error({
                  title: 'Distance non calculée',
                  message: "Impossible de trouver le lieu donné."
                });

                distance = null;
              } else {
                distance = Math.round(result.distance.value / 1000);

                _jquery.default.growl.notice({
                  title: 'Distance mise à jour',
                  message: "Distance = " + distance + " km"
                });
              }

              etapesChargement.objectAt(indexChargements[i - 1]).set('distance', distance);
            }
          });
          var livraisonResults = response.rows;
          livraisonResults.forEach(function (result, i) {
            if (i > 0 && i <= indexLivraisons.get('length')) {
              var element = result.elements[0];
              var distance;

              if (Ember.isNone(element.distance) || element.status !== 'OK') {
                _jquery.default.growl.error({
                  title: 'Distance non calculée',
                  message: "Impossible de trouver le lieu donné."
                });

                distance = null;
              } else {
                distance = Math.round(element.distance.value / 1000);

                _jquery.default.growl.notice({
                  title: 'Distance mise à jour',
                  message: "Distance = " + distance + " km"
                });
              }

              etapesLivraison.objectAt(indexLivraisons[i - 1]).set('distance', distance);
            }
          });
        } else {
          _jquery.default.growl.error({
            title: 'ATTENTION : distance(s) non calculée(s)',
            fixed: true,
            message: 'Erreur lors de la requête à Google Maps : ' + status
          });
        }
      });
    },
    updateStreetView: function () {
      if (this.get('distanceOnly') || this.get('desactivateStreetView')) {
        this.set('isHidden', true);
        this.set('isLoading', false);
        return;
      }

      var _this = this;

      var geocoder = this.get("geocoder");
      var panorama = this.get("panorama");
      var address = this.get('address');
      geocoder.geocode({
        'address': address
      }, function (results, status) {
        if (_this.get('isDestroying')) {
          return;
        }

        if (status === google.maps.GeocoderStatus.OK) {
          var panoramaOptions = {
            position: results[0].geometry.location,
            pov: {
              heading: 34,
              pitch: 10
            }
          };
          panorama.setOptions(panoramaOptions);

          _this.set("isHidden", false);
        } else {
          console.log('Geocode was not successful for the following reason: ' + status);

          _this.set("isHidden", true);
        }

        _this.set('isLoading', false);
      });
    },
    addressChanged: Ember.observer("addressModel", function () {
      this.set('firstRefresh', true);
    }),
    show: Ember.observer('refresh', 'okForRefresh', function () {
      var address = this.get("address");
      var doRefresh = this.get("refresh");

      if (doRefresh) {
        this.set('isLoading', true);
        this.set('isHidden', this.get("distanceOnly") || Ember.isBlank(address));
      }

      var okForRefresh = this.get("okForRefresh");

      if (doRefresh && okForRefresh) {
        this.set("okForRefresh", false);
        this.set("refresh", false);
        var firstRefresh = this.get('firstRefresh');

        if (firstRefresh) {
          this.set('firstRefresh', false);
        }

        if (Ember.isBlank(address)) {
          this.set('isLoading', false);
          this.set("isHidden", true);
          return;
        } //If not firstRefresh check
        //It prevents the distance from getting refreshed every time we load another address


        this.updateDistance(firstRefresh);
        this.updateStreetView();
      }
    })
  });

  _exports.default = _default;
});