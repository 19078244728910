define("dem-editor-v2/templates/components/bs-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oulUxkx3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"id\",[22,\"modalId\"]],[10,\"class\",\"modal fade\"],[10,\"tabindex\",\"-1\"],[10,\"role\",\"dialog\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"modal-dialog\"],[8],[0,\"\\n\\t    \"],[7,\"div\",true],[10,\"class\",\"modal-content\"],[8],[0,\"\\n\\t      \\t\"],[7,\"div\",true],[10,\"class\",\"modal-header\"],[8],[0,\"\\n\\t        \\t\"],[7,\"button\",true],[10,\"class\",\"close\"],[10,\"data-dismiss\",\"modal\"],[10,\"aria-label\",\"Close\"],[10,\"type\",\"button\"],[8],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[9],[0,\"\\n\\t        \\t\"],[7,\"h4\",true],[10,\"class\",\"modal-title\"],[10,\"id\",\"exampleModalLabel\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\\t      \\t\"],[9],[0,\"\\n\\t      \\t\"],[7,\"div\",true],[10,\"class\",\"modal-body\"],[8],[0,\"\\n\\t        \\t\"],[14,1],[0,\"\\n\\t      \\t\"],[9],[0,\"\\n\\t      \\t\"],[7,\"div\",true],[10,\"class\",\"modal-footer\"],[8],[0,\"\\n\\t        \\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-default\"],[12,\"data-dismiss\",\"modal\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"cancel\"]],[8],[1,[22,\"cancelText\"],false],[9],[0,\"\\n\\t        \\t\"],[7,\"button\",false],[12,\"class\",\"btn btn-primary\"],[12,\"data-dismiss\",\"modal\"],[12,\"disabled\",[22,\"disabled\"]],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"confirm\"]],[8],[1,[22,\"validateText\"],false],[9],[0,\"\\n\\t      \\t\"],[9],[0,\"\\n\\t    \"],[9],[0,\"\\n  \\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/bs-modal.hbs"
    }
  });

  _exports.default = _default;
});