define("dem-editor-v2/adapters/photo", ["exports", "jquery", "dem-editor-v2/config/environment", "dem-editor-v2/adapters/application"], function (_exports, _jquery, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    createRecord(store, type, snapshot) {
      let data = this.serialize(snapshot);
      let fd = new FormData();
      return new Promise(function (resolve, reject) {
        fd.append('file', data.file);

        _jquery.default.ajax({
          url: `${_environment.default.APP.API_HOST}/editor/upload_image/${data.token}`,
          type: 'post',
          data: fd,
          contentType: false,
          processData: false
        }).then(function (data) {
          Ember.run(null, resolve, data);
        }, function (jqXHR) {
          jqXHR.then = null;
          Ember.run(null, reject, jqXHR);
        });
      });
    }

  });

  _exports.default = _default;
});