define("dem-editor-v2/templates/components/piece-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oTPHhbvN",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row glowrow\"],[10,\"style\",\"position: absolute;\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"glowitem\"],[8],[0,\"\\n        \\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"addMeuble\",[23,1,[]]]],[8],[0,\"\\n        \\t\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"icon \",[23,1,[\"icon\"]]]]],[8],[9],[0,\"\\n        \\t\\t\"],[7,\"div\",true],[10,\"class\",\"item-name\"],[8],[1,[23,1,[\"nom_type\"]],false],[9],[0,\"\\n        \\t\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"a\",false],[12,\"href\",\"#\"],[3,\"action\",[[23,0,[]],\"showCategory\"]],[8],[1,[22,\"catname\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/piece-category.hbs"
    }
  });

  _exports.default = _default;
});