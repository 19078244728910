define("dem-editor-v2/templates/components/meuble-multi-modifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G+GFzn8u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\",true],[10,\"class\",\"inv-btn\"],[8],[1,[22,\"multiSelectCount\"],false],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"inv-name\"],[10,\"colspan\",\"2\"],[8],[0,\"éléments sélectionnés\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"inv-btn\"],[8],[0,\"\\n\\t\"],[7,\"div\",false],[12,\"class\",\"popover-trigger\"],[12,\"role\",\"button\"],[12,\"id\",\"multiServicesChooser\"],[12,\"data-popover-target\",\"serviceChooser\"],[3,\"action\",[[23,0,[]],\"showServiceChooser\"],[[\"bubbles\"],[false]]],[8],[0,\"\\n\\t\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"colspan\",\"5\"],[8],[9],[0,\"\\n\"],[7,\"td\",true],[10,\"class\",\"inv-btn\"],[10,\"style\",\"padding-right: 22px;\"],[8],[0,\"\\n\\t\"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"data-toggle\",\"modal\"],[10,\"data-target\",\"#confirmMultiDelete\"],[8],[7,\"i\",true],[10,\"class\",\"fa fa-trash-o fa-lg\"],[8],[9],[9],[0,\"\\n\"],[4,\"bs-modal\",null,[[\"modalId\",\"onConfirm\",\"title\"],[\"confirmMultiDelete\",[28,\"action\",[[23,0,[]],\"confirmMultiDelete\"],null],\"Supprimer\"]],{\"statements\":[[0,\"    \\t\"],[7,\"p\",true],[8],[0,\"Supprimer les \"],[1,[22,\"multiSelectCount\"],false],[0,\" éléments sélectionnés ?\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/meuble-multi-modifier.hbs"
    }
  });

  _exports.default = _default;
});