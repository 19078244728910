define("dem-editor-v2/adapters/application", ["exports", "dem-editor-v2/adapters/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _drf.default.extend({
    get headers() {
      return {
        "crm-username": Ember.get(document.cookie.match(/username=([^;]*)/), "1"),
        "crm-userhash": Ember.get(document.cookie.match(/userhash=([^;]*)/), "1")
      };
    },

    shouldBackgroundReloadRecord: function () {
      return false;
    }
  });

  _exports.default = _default;
});