define("dem-editor-v2/models/adresse", ["exports", "ember-data", "dem-editor-v2/models/project-related-model"], function (_exports, _emberData, _projectRelatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _projectRelatedModel.default.extend({
    street: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    type: _emberData.default.attr('number'),
    type_logement: _emberData.default.attr('string'),
    surface: _emberData.default.attr('number'),
    postal: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    floor: _emberData.default.attr('string'),
    stairs: _emberData.default.attr('number'),
    stairs_comment: _emberData.default.attr('string'),
    lift: _emberData.default.attr('boolean'),
    lift_comment: _emberData.default.attr('string'),
    all_in_lift: _emberData.default.attr('number'),
    all_in_lift_comment: _emberData.default.attr('string'),
    mm_utilisable: _emberData.default.attr('number'),
    mm_obligatoire: _emberData.default.attr('number'),
    mm_conseille: _emberData.default.attr('number'),
    mm_comment: _emberData.default.attr('string'),
    cave: _emberData.default.attr('boolean'),
    cave_comment: _emberData.default.attr('string'),
    garage: _emberData.default.attr('boolean'),
    garage_comment: _emberData.default.attr('string'),
    observations: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    stationnements: _emberData.default.hasMany('stationnement', {
      async: true,
      inverse: "adresse"
    }),
    project: _emberData.default.belongsTo('project', {
      async: true,
      inverse: "adresses"
    }),
    services_chargement: _emberData.default.hasMany('service', {
      async: true,
      inverse: "chargement"
    }),
    services_livraison: _emberData.default.hasMany('service', {
      async: true,
      inverse: "livraison"
    }),
    countries: Ember.inject.service('country-list'),
    countryName: Ember.computed('country', function () {
      var country = this.get("countries").get("list").filterBy("value", this.get("country")).get("firstObject");
      if (country) return country.text;
      return null;
    }),
    fullTextAddress: Ember.computed('city', 'postal', 'street', 'countryName', function () {
      var fullAddress = "";
      var street = this.get('street');
      var postal = this.get('postal');
      var city = this.get('city');
      var country = this.get('countryName');
      fullAddress += !Ember.isBlank(street) ? street : '';
      fullAddress += !Ember.isBlank(postal) ? ', ' + postal : '';
      fullAddress += !Ember.isBlank(city) ? ' ' + city : '';
      fullAddress += !Ember.isBlank(country) ? ' ' + country : '';
      return fullAddress;
    }),
    isChargement: Ember.computed('type', function () {
      return this.get('type') === 1;
    }),
    isLivraison: Ember.computed('type', function () {
      return this.get('type') === 2;
    }),
    adresse_type_text: Ember.computed('type', function () {
      return this.get('type') === 1 ? "chargement" : "livraison";
    }),
    adresse_descriptor: Ember.computed('city', 'logement_descriptor', function () {
      var logDescriptor = this.get('logement_descriptor');

      if (!logDescriptor) {
        return this.get('city');
      }

      return logDescriptor;
    }),
    adresse_description: Ember.computed('adresse_type_text', 'adresse_descriptor', 'order', function () {
      return this.get('adresse_type_text') + " " + this.get('order') + " - " + this.get('adresse_descriptor');
    }),
    isNotDeletable: Ember.computed('services_chargement.[]', 'services_livraison.[]', function () {
      return this.get('services_chargement').get('length') > 0 || this.get('services_livraison').get('length') > 0;
    }),
    isDeletableAfterServiceChargementDelete: Ember.computed('services_chargement.[]', 'services_livraison.[]', function () {
      return this.get('services_chargement').get('length') <= 1 && this.get('services_livraison').get('length') <= 0;
    }),
    isDeletableAfterServiceLivraisonDelete: Ember.computed('services_chargement.[]', 'services_livraison.[]', function () {
      return this.get('services_chargement').get('length') <= 0 && this.get('services_livraison').get('length') <= 1;
    }),
    associatedMeubles: Ember.computed('services_chargement.@each.meubles', 'services_livraison.@each.meubles', function () {
      var meubles = [];
      this.get('services_chargement').forEach(function (service) {
        service.get('meubles').forEach(function (meuble) {
          if (!meubles.includes(meuble)) {
            meubles.pushObject(meuble);
          }
        });
      });
      this.get('services_livraison').forEach(function (service) {
        service.get('meubles').forEach(function (meuble) {
          if (!meubles.includes(meuble)) {
            meubles.pushObject(meuble);
          }
        });
      });
      return meubles;
    }),
    logement_descriptor: Ember.computed('type_logement', function () {
      var type = this.get('type_logement'); //TYPE DGTS_LOG_UNDEFINED => we still take the city so return null

      if (type === "DGTS_LOG_GARDEMEUBLE_DGR") {
        return "Garde meuble";
      } else if (type === "DGTS_LOG_TROTTOIR") {
        return "Trottoir";
      } else if (type === "DGTS_LOG_DECHETTERIE") {
        return "Déchetterie";
      }

      return null;
    }),
    //NOT USED
    type_logement_text: Ember.computed('type_logement', function () {
      var type = this.get('type_logement');

      if (type === "DGTS_LOG_APPART") {
        return "Appartement";
      } else if (type === "DGTS_LOG_MAISON") {
        return "Maison";
      } else if (type === "DGTS_LOG_GARDEMEUBLE") {
        return "Garde meuble";
      } else if (type === "DGTS_LOG_DUPLEX") {
        return "Duplex";
      } else if (type === "DGTS_LOG_TRIPLEX") {
        return "Triplex";
      } else if (type === "DGTS_LOG_BUREAUX") {
        return "Bureaux";
      }

      return "UNKNOWN";
    }),
    isSpecialLogement: Ember.computed('type_logement', function () {
      return ['DGTS_LOG_TROTTOIR', 'DGTS_LOG_DECHETTERIE', 'DGTS_LOG_GARDEMEUBLE_DGR', 'DGTS_LOG_UNDEFINED'].includes(this.get('type_logement'));
    }),
    hasOnlineBookingErrors: Ember.computed('all_in_lift', 'stairs', 'mm_utilisable', 'stationnements.@each.vl', function () {
      const statWithErrors = this.get('stationnements').findBy('vl', -1);
      return this.get('all_in_lift') === -1 || this.get('stairs') === -1 || this.get('mm_utilisable') === -1 || this.get('mm_obligatoire') === -1 || !!statWithErrors;
    }),
    hasAccessErrors: Ember.computed('isDeleted', 'mm_utilisable', 'mm_comment', 'cave', 'cave_comment', 'garage', 'garage_comment', 'lift', 'lift_comment', 'all_in_lift', 'all_in_lift_comment', 'isSpecialLogement', 'stationnements.@each.hasAccessErrors', function () {
      if (this.get('isDeleted')) return false;
      return this.get('mm_utilisable') && !this.get('mm_comment') || this.get('cave') && !this.get('cave_comment') || this.get('garage') && !this.get('garage_comment') || this.get('lift') && !this.get('lift_comment') || this.get('lift') && !this.get('all_in_lift') && !this.get('all_in_lift_comment') || this.get('stationnements').findBy('hasAccessErrors', true) || Ember.isEmpty(this.get('stationnements').get('firstObject')) && !this.get('isSpecialLogement');
    }),
    transboDistanceMissing: Ember.computed('stationnements.@each.transboDistanceMissing', function () {
      return !Ember.isEmpty(this.get('stationnements').findBy('transboDistanceMissing', true));
    })
  });

  _exports.default = _default;
});