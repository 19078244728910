define("dem-editor-v2/templates/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b8Oh67ZY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[10,\"class\",\"navbar-form navbar-right\"],[10,\"role\",\"search\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"form-control\"],[8],[0,\"\\n    \"],[7,\"label\",true],[10,\"for\",\"all_files\"],[8],[0,\"Tous dossiers\"],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"id\",\"checked\"],[\"checkbox\",\"all_files\",[24,[\"showAllFiles\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/admin.hbs"
    }
  });

  _exports.default = _default;
});