define("dem-editor-v2/initializers/custom-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'custom-fields',
    initialize: function () {
      /*Bootstrap.BsPanelComponent.reopen({
      	path: '/',
      	click: function(event) {
      	  var open = this.get('open');
            var id = "#"+this.get('collapsibleBodyId');
            var href = this.get('collapsibleBodyLink');
            
      	  var targetHref = event.target.href;
      	  if (!targetHref || !targetHref.match(href))
      	  	return;
            
            if (open) {
            	this.$(id).removeClass('in');
            	this.set('open', false);
            } else {
            	this.$(id).addClass('in');
            	this.set('open', true);
            }
            return this.sendAction('clicked');
          },
          collapsibleBodyLink: (function() {
          	return this.get('path');
          }).property('path')
      });*/
      Ember.TextField.reopen({
        attributeBindings: ['min', 'max', 'step', 'required']
      });
    }
  };
  _exports.default = _default;
});