define("dem-editor-v2/helpers/two-digits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.twoDigits = twoDigits;
  _exports.default = void 0;

  function twoDigits(params
  /*, hash*/
  ) {
    var i = parseInt(params[0]);
    if (i > 10) return params[0];
    return "0" + params[0];
  }

  var _default = Ember.Helper.helper(twoDigits);

  _exports.default = _default;
});