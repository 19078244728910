define("dem-editor-v2/controllers/project/photos", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    app_controller: Ember.inject.controller('application'),
    disabledRemove: null,
    fileMaxSize: 3,
    photos: Ember.computed('model.[]', 'project.model.token', {
      get() {
        const projectToken = this.get('project').get('model').get('token');
        return this.get('model').filterBy('token', projectToken).sortBy('id:desc').reverse();
      }

    }),
    sending: Ember.computed('project.model.token', function () {
      let _this = this;

      return function (file) {
        this.removeFile(file);
        if (file.size > _this.get('fileMaxSize') * 1000000) return;

        _this.set("disabledRemove", true);

        let photo = _this.store.createRecord('photo', {
          'file': file,
          'token': _this.get('project').get('model').get('token')
        });

        photo.save().then(() => {
          _this.set("disabledRemove", null);
        }, function (error) {
          photo.deleteRecord();

          _jquery.default.growl.error({
            title: 'Impossible d\'ajouter une photo',
            message: error.message
          });
        });
      };
    }),
    onUploadError: function (file, errorMsg) {
      _jquery.default.growl.error({
        title: 'Une erreur est survenue',
        message: errorMsg
      });
    },
    project: Ember.inject.controller('project'),
    actions: {
      deletePhoto: function (photo) {
        if (photo) {
          this.set("disabledRemove", true);
          photo.destroyRecord().then(() => {
            _jquery.default.growl.notice({
              title: 'Photo supprimée',
              message: photo.file_name
            });

            this.set("disabledRemove", null);
          }).catch(error => {
            _jquery.default.growl.error({
              title: 'Photo non supprimée',
              message: error.message
            });

            this.set("disabledRemove", null);
          });
        }
      }
    }
  });

  _exports.default = _default;
});