define("dem-editor-v2/templates/components/qty-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ECae8IPy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"tabindex\",\"-1\"],[12,\"class\",\"qtybutton btn btn-link\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"decrement\"]],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-minus-square-o fa-2x\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"style\",\"value\",\"focus-out\",\"class\"],[\"text\",[24,[\"safeStyle\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"checkValue\"],null],\"qtyinput\"]]],false],[0,\"\\n\"],[7,\"button\",false],[12,\"tabindex\",\"-1\"],[12,\"class\",\"qtybutton btn btn-link\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],\"increment\"]],[8],[0,\"\\n\\t\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus-square-o fa-2x\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/qty-editor.hbs"
    }
  });

  _exports.default = _default;
});