define("dem-editor-v2/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    civility: _emberData.default.attr('string'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    phone_mobile: _emberData.default.attr('string'),
    phone_home: _emberData.default.attr('string'),
    email1: _emberData.default.attr('string'),
    email2: _emberData.default.attr('string'),
    formule: _emberData.default.attr('string'),
    volume: _emberData.default.attr('number'),
    assurance: _emberData.default.attr('string'),
    assurance_value: _emberData.default.attr('number'),
    num_client: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    observations: _emberData.default.attr('string'),
    observations_idem: _emberData.default.attr('string'),
    name_to_show: _emberData.default.attr('string'),
    adresses: _emberData.default.hasMany('adresse', {
      inverse: 'project',
      async: true
    }),
    pieces: _emberData.default.hasMany('piece', {
      inverse: 'project',
      async: true
    }),
    services: _emberData.default.hasMany('service', {
      inverse: 'project',
      async: true
    }),
    dateranges: _emberData.default.hasMany('daterange', {
      inverse: 'project',
      async: true
    }),
    appoff: _emberData.default.belongsTo('appoff', {
      inverse: 'project',
      async: true
    }),
    assigned_user_id: _emberData.default.attr('string'),
    last_modified: _emberData.default.attr('string'),
    last_modified_by: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    other_data: _emberData.default.attr('json'),
    chd_version_date: _emberData.default.attr('string'),
    booking_step: _emberData.default.attr('string'),
    current_user_id: null,
    requested_transfer: undefined,
    save: function () {
      var userId = this.get('current_user_id');

      if (!Ember.isBlank(userId)) {
        this.set('last_modified_by', userId);
      }

      return this._super();
    },
    chdNotUpToDate: Ember.computed('last_modified', 'chd_version_date', {
      get: function () {
        var chdVersionDate = this.get('chd_version_date');

        if (Ember.isBlank(chdVersionDate)) {
          return true;
        }

        return moment(this.get('last_modified')).isAfter(moment(this.get('chd_version_date')));
      },
      set: function (key, value) {
        return value;
      }
    }),
    lastModifiedFormatted: Ember.computed('last_modified', function () {
      return moment(this.get('last_modified')).format('DD/MM à HH:mm');
    }),
    distance: Ember.computed('mainEtape.distance', function () {
      var mainEtape = this.get('mainEtape');

      if (!Ember.isNone(mainEtape)) {
        return mainEtape.get('distance');
      }

      return null;
    }),
    mainEtape: Ember.computed('adressesServices.[]', function () {
      var services = this.get('adressesServices');

      if (!Ember.isEmpty(services)) {
        return services.get('firstObject');
      }

      return null;
    }),
    allMeubles: Ember.computed('pieces.@each.meubles', function () {
      var meubles = [];
      this.get('pieces').forEach(function (piece) {
        meubles.pushObjects(piece.get('meubles'));
      });
      return meubles;
    }),
    formuleName: Ember.computed('formule', function () {
      var formule = this.get('formule');

      if (formule === "FORM_TRANSPORT") {
        return "transport";
      } else if (formule === "FORM_ECO") {
        return "économique";
      } else if (formule === "FORM_STANDARD") {
        return "standard";
      } else if (formule === "FORM_LUXE") {
        return "complète";
      }

      return "non définie";
    }),
    volumeTotal: Ember.computed('pieces.@each.volumeTotal', function () {
      var total = 0;
      this.get('pieces').forEach(function (item) {
        total += item.get('volumeTotal');
      });
      total = Math.floor(Math.round(total * 10) / 10 + 0.5);
      /* eslint-disable ember/no-side-effects */

      this.set('volume', total);
      /* eslint-enable ember/no-side-effects */

      return total;
    }),
    showClientName: Ember.computed('isMinarmClient', {
      get: function () {
        return this.get('isMinarmClient') || this.get('name_to_show') !== null;
      },
      set: function (key, value) {
        return value;
      }
    }),
    adressesServices: Ember.computed("services.{isFulfilled,@each.chargement_order,@each.livraison_order}", function () {
      return this.get('services').filterBy('key', 'address').sortBy('chargement_order', 'livraison_order', 'id');
    }),
    sortingAdresses: Object.freeze(['type', 'order']),
    sortedAdresses: Ember.computed.sort('adresses', 'sortingAdresses'),
    adresseChargement: Ember.computed('adresses.@each.order', function () {
      return this.get('adresses').find(function (adresse) {
        return adresse.get('type') === 1 && adresse.get('order') === 1;
      });
    }),
    adresseLivraison: Ember.computed('adresses.@each.order', function () {
      return this.get('adresses').find(function (adresse) {
        return adresse.get('type') === 2 && adresse.get('order') === 1;
      });
    }),
    adressesChargement: Ember.computed('adresses.{isFulfilled,@each.type}', function () {
      return this.get('adresses').filterBy('type', 1);
    }),
    adressesLivraison: Ember.computed('adresses.{isFulfilled,@each.type}', function () {
      return this.get('adresses').filterBy('type', 2);
    }),
    errorUnlinkedAddresses: Ember.computed('adresses.@each.{services_chargement,services_livraison}', function () {
      var adresses = this.get('adresses');
      return adresses.filter(function (adresse) {
        var servicesChargement = adresse.get('services_chargement');
        var servicesLivraison = adresse.get('services_livraison');
        return servicesChargement.get('length') <= 0 && servicesLivraison.get('length') <= 0;
      });
    }),
    errorAccessChargement: Ember.computed('adressesChargement.@each.hasAccessErrors', function () {
      return this.get('adressesChargement').filter(function (adresse) {
        return adresse.get('hasAccessErrors');
      });
    }),
    errorAccessLivraison: Ember.computed('adressesLivraison.@each.hasAccessErrors', function () {
      return this.get('adressesLivraison').filter(function (adresse) {
        return adresse.get('hasAccessErrors');
      });
    }),
    errorEmptyServices: Ember.computed('adressesServices.@each.notDeletedMeubles', function () {
      var services = this.get('adressesServices');
      return services.filter(function (service) {
        var meubles = service.get('notDeletedMeubles');
        return meubles.get('length') <= 0;
      });
    }),
    errorNoDatesServices: Ember.computed('adressesServices.@each.{dates_chargement,dates_livraison}', function () {
      var services = this.get('adressesServices');
      return services.filter(function (service) {
        //IL FAUT UNE DATE DE CHARGEMENT ET UNE DATE DE LIVRAISON
        return service.get('dates_chargement').get('length') <= 0 || service.get('dates_livraison').get('length') <= 0;
      });
    }),
    errorDemontage: Ember.computed('services.[]', 'formule', 'pieces.@each.hasDemontage', function () {
      var formule = this.get('formule');

      if (formule === 'FORM_TRANSPORT') {
        var demontageService = this.get('services').findBy('key', 'demrem');

        if (!Ember.isEmpty(demontageService)) {
          return false;
        }

        return !Ember.isEmpty(this.get('pieces').findBy('hasDemontage'));
      }

      return false;
    }),
    errorLivraisonCartons: Ember.computed('services.[]', 'formule', 'pieces.@each.hasCartonsALivrer', function () {
      var formule = this.get('formule');

      if (formule === "FORM_TRANSPORT") {
        var livraisonService = this.get('services').findBy('key', 'cartons');

        if (!Ember.isEmpty(livraisonService)) {
          return false;
        }

        return !Ember.isEmpty(this.get('pieces').findBy('hasCartonsALivrer'));
      }

      return false;
    }),
    errorPenderies: Ember.computed('services.[]', 'formule', 'pieces.@each.hasPenderies', function () {
      var formule = this.get('formule');

      if (formule === "FORM_TRANSPORT" || formule === "FORM_ECO") {
        var penderiesService = this.get('services').findBy('key', 'penderie');

        if (!Ember.isEmpty(penderiesService)) {
          return false;
        }

        return !Ember.isEmpty(this.get('pieces').findBy('hasPenderies'));
      }

      return false;
    }),
    errorAssurance: Ember.computed('assurance', 'assurance_value', function () {
      return !this.get('assurance') || !this.get('assurance_value') && this.get('assurance') !== "AUCUNE";
    }),
    errorFormule: Ember.computed('formule', function () {
      return !this.get('formule');
    }),
    errorDistance: Ember.computed('distance', function () {
      var dist = this.get('distance');
      return Ember.isBlank(dist) || dist < 0;
    }),
    warningNoDistanceServices: Ember.computed('services.@each.distance', function () {
      return this.get('adressesServices').slice(1).filterBy('distance', null);
    }),
    cartonsStdCount: Ember.computed('pieces.@each.cartonsStdCount', function () {
      var count = 0;
      this.get('pieces').forEach(function (piece) {
        count += piece.get('cartonsStdCount');
      });
      return count;
    }),
    cartonsLivCount: Ember.computed('pieces.@each.cartonsLivCount', function () {
      var count = 0;
      this.get('pieces').forEach(function (piece) {
        count += piece.get('cartonsLivCount');
      });
      return count;
    }),
    penderiesCount: Ember.computed('pieces.@each.penderiesCount', function () {
      var count = 0;
      this.get('pieces').forEach(function (piece) {
        count += piece.get('penderiesCount');
      });
      return Math.round(count * 10) / 10;
    }),
    caveWarning: Ember.computed('hasCave', 'pieces.@each.piece_type', function () {
      return this.get('hasCave') && Ember.isNone(this.get('pieces').findBy('piece_type', 'cellar'));
    }),
    garageWarning: Ember.computed('hasGarage', 'pieces.@each.piece_type', function () {
      return this.get('hasGarage') && Ember.isNone(this.get('pieces').findBy('piece_type', 'garage'));
    }),
    hasGarage: Ember.computed('adresseChargement.garage', function () {
      var address = this.get('adresseChargement');

      if (!Ember.isNone(address)) {
        return address.get('garage');
      }

      return false;
    }),
    hasCave: Ember.computed('adresseChargement.cave', function () {
      var address = this.get('adresseChargement');

      if (!Ember.isNone(address)) {
        return address.get('cave');
      }

      return false;
    }),
    transboDistanceWarning: Ember.computed('isMinarmClient', 'adresses.@each.transboDistanceMissing', function () {
      return this.get('isMinarmClient') && this.get('adresses').findBy('transboDistanceMissing', true);
    }),
    transferRequestWarning: Ember.computed('requested_transfer', function () {
      return this.get('requested_transfer') === null;
    }),
    hasError: Ember.computed('errorPenderies', 'errorDemontage', 'errorAssurance', 'errorUnlinkedAddresses', 'errorLivraisonCartons', 'errorEmptyServices', 'errorNoDatesServices', 'errorDistance', 'errorFormule', 'errorAccessChargement', 'errorAccessLivraison', 'hasOnlineBookingErrors', function () {
      return this.get('errorPenderies') || this.get('errorDemontage') || this.get('errorAssurance') || !Ember.isBlank(this.get('errorUnlinkedAddresses')) || this.get('errorLivraisonCartons') || !Ember.isBlank(this.get('errorEmptyServices')) || !Ember.isBlank(this.get('errorNoDatesServices')) || !Ember.isBlank(this.get('errorAccessChargement')) || !Ember.isBlank(this.get('errorAccessLivraison')) || this.get('errorDistance') || this.get('errorFormule') || this.get('hasOnlineBookingErrors');
    }),
    hasWarning: Ember.computed('hasImportantWarning', 'chdNotUpToDate', function () {
      return this.get('chdNotUpToDate') || this.get('hasImportantWarning');
    }),
    hasImportantWarning: Ember.computed('warningNoDistanceServices', 'caveWarning', 'garageWarning', 'transboDistanceWarning', 'transferRequestWarning', function () {
      return this.get('caveWarning') || this.get('garageWarning') || this.get('transferRequestWarning') || !Ember.isBlank(this.get('transboDistanceWarning')) || !Ember.isBlank(this.get('warningNoDistanceServices'));
    }),
    defaultServices: Object.freeze([Ember.Object.create({
      key: "base",
      name: "Transport, livraison et remise en place",
      type: 0,
      color: "aaa",
      value: 10001,
      disabled: true,
      in_formule: true
    }), Ember.Object.create({
      key: "protec",
      name: "Protection du mobilier et literie",
      type: 0,
      color: "aaa",
      value: 10000,
      disabled: true,
      in_formule: true
    }), Ember.Object.create({
      key: "demrem",
      name: "Démontage et remontage du mobilier",
      type: 0,
      color: "aaa",
      value: 1001
    }), Ember.Object.create({
      key: "cartons",
      name: "Livraison de cartons et rouleaux adhésifs",
      type: 0,
      color: "aaa",
      value: 1000
    }), Ember.Object.create({
      key: "penderie",
      name: "Mise en penderies des habits sur cintres",
      type: 0,
      color: "aaa",
      value: 102
    }), Ember.Object.create({
      key: "emb_fragile",
      name: "Emballage du fragile (verrerie, cadres, TV)",
      type: 0,
      color: "aaa",
      value: 101
    }), Ember.Object.create({
      key: "deb_fragile",
      name: "Deballage du fragile (verrerie, cadres, TV)",
      type: 0,
      color: "aaa",
      value: 100
    }), Ember.Object.create({
      key: "emb_nonfragile",
      name: "Emballage du non fragile (livres, vêtements)",
      type: 0,
      color: "aaa",
      value: 10
    }), Ember.Object.create({
      key: "deb_nonfragile",
      name: "Déballage du non fragile (livres, vêtements)",
      type: 0,
      color: "aaa",
      value: 2
    }), Ember.Object.create({
      key: "decrochage",
      name: "Décrochage mural (meubles, tringle, cadres)",
      type: 0,
      color: "aaa",
      value: 1
    })]),
    availableFormules: Object.freeze([Ember.Object.create({
      name: "TRANSPORT",
      value: 20001,
      key: "FORM_TRANSPORT"
    }), Ember.Object.create({
      name: "ECONOMIQUE",
      value: 22002,
      key: "FORM_ECO"
    }), Ember.Object.create({
      name: "STANDARD",
      value: 22305,
      key: "FORM_STANDARD"
    }), Ember.Object.create({
      name: "COMPLETE",
      value: 22315,
      key: "FORM_LUXE"
    })]),
    formuleObject: Ember.computed('formule', function () {
      return this.get('availableFormules').findBy('key', this.get('formule'));
    }),
    isCommissionnaire: Ember.computed('other_data.is_commissionnaire', function () {
      return this.get('other_data.is_commissionnaire');
    }),
    provPartner: Ember.computed('other_data.prov_partner', function () {
      return this.get('other_data.prov_partner');
    }),
    isMinarmClient: Ember.computed('other_data.is_minarm', function () {
      return this.get('other_data.is_minarm');
    }),
    minarmType: Ember.computed('other_data.minarm_type', function () {
      var type = this.get('other_data.minarm_type');
      if (type) return type;
      return "MINARM";
    }),
    plafondVol: Ember.computed('other_data.plafond_vol', function () {
      return this.get('other_data.plafond_vol');
    }),
    plafondPrice: Ember.computed('other_data.plafond_price', function () {
      return this.get('other_data.plafond_price');
    }),
    errorAssuranceMinarm: Ember.computed('assurance_value', 'plafondAssurance', function () {
      return this.get('assurance_value') > this.get('plafondAssurance');
    }),
    plafondAssurance: Ember.computed('plafondVolPourCalcul', function () {
      return this.get('plafondVolPourCalcul') * 500;
    }),
    plafondVolPourCalcul: Ember.computed('volumeTotal', 'plafondVol', function () {
      var plafondVolCCR = this.get('plafondVol');
      var volume = this.get('volumeTotal');
      var volPlafond = volume;
      if (volume > plafondVolCCR) volPlafond = plafondVolCCR;
      if (volPlafond < 20) volPlafond = 20;
      return volPlafond;
    }),
    isOnlineBooking: Ember.computed('booking_step', function () {
      const step = this.get('booking_step');
      return step && step !== 'CREATED' && step !== 'VERIFIED';
    }),
    hasOnlineBookingErrors: Ember.computed('adresses.@each.hasOnlineBookingErrors', function () {
      return this.get('adresses').findBy('hasOnlineBookingErrors', true);
    })
  });

  _exports.default = _default;
});