define("dem-editor-v2/components/slide-menu", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Menu */
  var _default = Ember.Component.extend({
    prepareSlideMenu() {
      var slideMenu = new Menu({
        wrapper: '#o-wrapper',
        type: 'slide-right',
        menuOpenerClass: '.c-button',
        maskId: '#c-mask'
      });
      var menuOpener = (0, _jquery.default)('#menu-opener');

      var _this = this;

      menuOpener.click(function (e) {
        e.preventDefault();
        slideMenu.open();
        (0, _jquery.default)(_this.element).find('textarea').first().focus();
      });
      (0, _jquery.default)('#c-mask, .c-menu__close').click(function () {
        var project = _this.get('project');

        if (project.get('hasDirtyAttributes')) {
          project.save();
        }
      });
    },

    didInsertElement: function () {
      Ember.run.scheduleOnce('afterRender', this, this.prepareSlideMenu);
    }
  });

  _exports.default = _default;
});