define("dem-editor-v2/router", ["exports", "dem-editor-v2/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('project', {
      path: '/:num_client'
    }, function () {
      this.route('chargement', function () {
        this.route('adresse', {
          path: '/adresse/:adresse_id'
        });
      });
      this.route('livraison', function () {
        this.route('adresse', {
          path: '/adresse/:adresse_id'
        });
      });
      this.route('dates');
      this.route('prestations');
      this.route('inventaire', function () {
        this.route('add');
        this.route('loading');
        this.route('piece', {
          path: '/piece/:piece_id'
        });
      });
      this.route('loading');
      this.route('client');
      this.route('chd');
      this.route('appoff');
      this.route('photos');
    });
    this.route('import', {
      path: '/import/:num_client'
    });
    this.route('login');
  });
});