define("dem-editor-v2/components/gm-editor", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global autosize */
  var _default = Ember.Component.extend({
    tagName: 'tr',
    DURATION_UNKNOWN: -1,
    gm_externe: Ember.computed('gmData.externe', function () {
      return this.get('gmData').externe ? " externe" : " déménageur";
    }),
    gm_duration: Ember.computed('gmData.duration', function () {
      var duration = this.get('gmData').duration;

      if (duration == this.DURATION_UNKNOWN) {
        return " pour une durée indéterminée";
      }

      if (duration > 0) {
        return ` pour ${duration} mois`;
      }

      return "";
    }),
    gm_relivraison: Ember.computed('gmData.relivraison', function () {
      return this.get('gmData').relivraison ? " + relivraison" : "";
    }),
    gm_comment: Ember.computed('gmData.comment', function () {
      var comment = this.get('gmData').comment;
      return comment ? " (" + comment + ")" : "";
    }),

    init() {
      this._super(...arguments);

      this.set('durationOptions', this.getDurationOptions());
    },

    getDurationOptions() {
      var opts = [{
        "name": "Durée indéterminée",
        "value": this.DURATION_UNKNOWN
      }, {
        "name": "< 1 mois",
        "value": 0
      }];

      for (var i = 1; i < 25; i++) {
        opts.push({
          "name": i + " mois",
          "value": i
        });
      }

      return opts;
    },

    resizeTextAreas() {
      autosize.update((0, _jquery.default)(this.element).prev('tr').find('textarea'));
    },

    serviceText: Ember.on('init', Ember.observer('gmData.externe', 'gmData.duration', 'gmData.relivraison', 'gmData.comment', function () {
      var gmString = "Mise en garde-meuble" + this.get('gm_externe') + this.get('gm_duration') + this.get('gm_relivraison') + this.get('gm_comment');

      if (this.onChange) {
        Ember.run.once(this, this.onChange, gmString);
        Ember.run.next(this, this.resizeTextAreas);
      }

      return gmString;
    }))
  });

  _exports.default = _default;
});