define("dem-editor-v2/routes/project/client", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      return this.modelFor('project');
    },
    isTransitioning: false,
    actions: {
      didTransition: function () {
        this.set('isTransitioning', false);
        return true; // Bubble the didTransition event
      },
      willTransition: function (transition) {
        if (this.get('isTransitioning')) {
          return true;
        }

        var _this = this;

        var model = this.get('controller').get('model');

        if (model.get('showClientName') && Ember.isBlank(model.get('name_to_show'))) {
          transition.abort();

          _jquery.default.growl.error({
            title: 'Nom manquant',
            message: "Préciser le nom auquel faire les devis."
          });

          return false;
        }

        if (model.get('hasDirtyAttributes')) {
          transition.abort();
          this.set('isTransitioning', true);
          this.intermediateTransitionTo('project.loading');
          model.save().then(function () {
            _this.set('isTransitioning', false);

            transition.retry();
          }).catch(function () {
            _this.transitionTo('project.client');

            _jquery.default.growl.error({
              title: 'Infos non enregistrées',
              message: "Erreurs ou champs manquants"
            });
          });
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});