define("dem-editor-v2/components/etape-chooser", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['etape-chooser'],
    outerListener: null,

    init() {
      this._super(...arguments);

      this.etapes = this.etapes || [];
    },

    selector: Ember.computed('id', function () {
      return '[data-popover-target="' + this.get("id") + '"]';
    }),
    didInsertElement: function () {
      var that = this;
      var selector = this.get('selector'); //INIT POPOVER

      (0, _jquery.default)('#dates-editor').popover({
        html: true,
        selector: selector,
        placement: 'right',
        container: 'body',
        animation: false,
        content: function () {
          return (0, _jquery.default)(that.element);
        }
      });
      (0, _jquery.default)('#dates-editor').on('show.bs.popover', function (event) {
        (0, _jquery.default)(selector).not(event.target).popover('destroy');
      });

      var listener = function (e) {
        var container = (0, _jquery.default)('#' + that.get('id')); // if the target of the click isn't the contain

        if (!container.is(e.target) && container.has(e.target).length === 0) {
          (0, _jquery.default)(selector).popover('destroy');
          that.applyChanges(that.get('daterange'));
          that.set('daterange', null);
        }
      };

      this.set('outerListener', listener); //HIDE POPOVER WHEN CLICK OUTSIDE

      (0, _jquery.default)('html').click(listener);
    },
    willDestroyElement: function () {
      (0, _jquery.default)(this.get('selector')).popover('destroy');
      (0, _jquery.default)('html').unbind('click', this.get('outerListener'));
      this.applyChanges(this.get('daterange'));
      this.set('daterange', null);
    },
    getEtapes: Ember.computed('daterange', 'etapes.[]', function () {
      var result = [];
      var EtapeSelector = Ember.Object.extend({
        service: null,
        daterange: null,
        uniqueId: new Date().getTime(),
        chooserId: Ember.computed('service', function () {
          return "etape_chooser_choice" + this.get('service').get('id');
        }),

        /*relatedAddress: computed('service.{chargement,livraison}', 'daterange.type', function() {
          var type = this.get('daterange').get('type');
          return this.get('service').getAddressOfType(type);
        }),*/
        tempServices: Ember.computed('daterange.tempServices', function () {
          var daterange = this.get('daterange');

          if (!daterange) {
            return [];
          }

          var tempServ = daterange.get('tempServices');

          if (Ember.isNone(tempServ)) {
            tempServ = [];
            var services = daterange.get('services');
            services.forEach(function (ser) {
              tempServ.pushObject(ser);
            });
            Ember.run.once('actions', function () {
              daterange.set('tempServices', tempServ);
            });
          }

          return tempServ;
        }),
        isDisabled: Ember.computed('tempServices.[]', 'isSelected', function () {
          var services = this.get('tempServices');
          return services.get('length') === 1 && this.get('isSelected');
        }),
        isSelected: Ember.computed("tempServices.[]", "service", {
          get: function () {
            return this.get('tempServices').includes(this.get('service'));
          },
          set: function (key, value) {
            var ser = this.get('service');
            var services = this.get('tempServices');

            if (value && !services.includes(ser)) {
              /* UPDATE - ALLOW TO SELECT MULTIPLE ETAPES EVEN IF THEY DON'T HAVE THE SAME ADDRESSES
              var type = this.get('daterange').get('type');
              var address = this.get('relatedAddress');
              var servicesToRemove = this.get('daterange').get('services').filter(function(item) {
                  var serviceAddr = item.getAddressOfType(type);
                  return serviceAddr.get('id') !== address.get('id');
              });
                servicesToRemove.forEach(function(item) {
                  services.removeObject(item);
              });*/
              services.pushObject(ser);
            }

            if (!value && services.includes(ser)) {
              services.removeObject(ser);
            }

            return value;
          }
        })
      });
      var daterange = this.get('daterange');
      this.get('etapes').forEach(function (ser) {
        if (!ser.get('isDeleted')) {
          var selector = EtapeSelector.create();
          selector.set("service", ser);
          selector.set("daterange", daterange);
          result.pushObject(selector);
        }
      });
      return result;
    })
  });

  _exports.default = _default;
});