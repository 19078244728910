define("dem-editor-v2/services/energy-utils", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getContractsUrl: function (token) {
      const server = _environment.default.APP.API_HOST + "/" + _environment.default.APP.API_NAMESPACE;
      return server + '/contracts-transfer/' + token;
    },
    requestTransfer: function (projectModel, force) {
      const url = this.getContractsUrl(projectModel.get('token'));
      return new Ember.RSVP.Promise(function (resolve) {
        const requested = projectModel.get('requested_transfer');

        if (requested !== undefined && !force) {
          resolve({
            'requested_transfer': requested
          });
          return;
        }

        _jquery.default.get(url, data => {
          const result = data['requested_transfer'];
          const eligible = data['is_eligible'];

          if (result !== null || eligible) {
            projectModel.set('requested_transfer', result);
          } else {
            projectModel.set('requested_transfer', undefined);
          }

          resolve(data);
        });
      });
    }
  });

  _exports.default = _default;
});