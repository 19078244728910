define("dem-editor-v2/models/daterange", ["exports", "ember-data", "dem-editor-v2/models/project-related-model"], function (_exports, _emberData, _projectRelatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = _projectRelatedModel.default.extend({
    type: _emberData.default.attr('number'),
    chosen_by_client: _emberData.default.attr('number'),
    ideale: _emberData.default.attr('boolean'),
    groupage: _emberData.default.attr('nullboolean'),
    date_first: _emberData.default.attr('string'),
    date_last: _emberData.default.attr('string'),
    other_dates: _emberData.default.attr('string'),
    services: _emberData.default.hasMany('service', {
      async: true,
      inverse: "dateranges"
    }),
    project: _emberData.default.belongsTo('project', {
      inverse: "dateranges"
    }),
    mustUpdateDateMin: false,
    etapeChooserId: Ember.computed('id', function () {
      if (this.get('id')) {
        return "etape-chooser-" + this.get('id');
      }

      return "etape-chooser-" + new Date().getTime();
    }),
    otherDates: Ember.computed('other_dates', {
      get: function () {
        var other_dates = this.get('other_dates');

        if (Ember.isNone(other_dates)) {
          return [];
        }

        return JSON.parse(this.get('other_dates'));
      },
      set: function (key, value) {
        this.set('other_dates', JSON.stringify(value));
        return value;
      }
    }),

    setDateFirst(dateFirst) {
      this.set('date_first', dateFirst);
    },

    setDateLast(dateLast) {
      this.set('date_last', dateLast);
    },

    dateMin: Ember.computed('services.@each.dates_chargement', 'mustUpdateDateMin', function () {
      if (this.get('type') === 2) {
        var dates = [];
        this.get('services').forEach(function (service) {
          service.get('dates_chargement').forEach(function (daterange) {
            dates.pushObject(moment(daterange.get('date_first')));
          });
        });
        var min = moment.min(dates);
        var minFormatted = min.format('YYYY-MM-DD');
        var dateFirst = moment(this.get('date_first'));
        var dateLast = this.get('date_last');

        if (min.isAfter(dateFirst)) {
          Ember.run.once(this, this.setDateFirst, minFormatted);
        }

        if (!Ember.isBlank(dateLast) && min.isAfter(moment(dateLast))) {
          Ember.run.once(this, this.setDateLast, minFormatted);
        }

        return minFormatted;
      }

      return null;
    }),
    sendUpdateDateMin: Ember.observer('date_first', 'services.[]', function () {
      if (this.get('type') === 1) {
        Ember.run.once(this, this.toggleUpdateDateMin);
      }
    }),

    toggleUpdateDateMin() {
      this.get('services').forEach(function (service) {
        service.get('dates_livraison').forEach(function (daterange) {
          daterange.toggleProperty('mustUpdateDateMin');
        });
      });
    },

    mustBeDeleted: Ember.observer('services.[]', function () {
      Ember.run.once(this, this.deleteWhenNotLinked);
    }),

    deleteWhenNotLinked() {
      if (!this.get('isSaving') && !this.get('isDeleted')) {
        var servicesCount = this.get('services').get('length');

        if (servicesCount <= 0) {
          this.destroyRecord();
        }
      }
    }

  });

  _exports.default = _default;
});