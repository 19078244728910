define("dem-editor-v2/models/piece", ["exports", "ember-data", "dem-editor-v2/models/project-related-model"], function (_exports, _emberData, _projectRelatedModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _projectRelatedModel.default.extend({
    name: _emberData.default.attr('string'),
    piece_type: _emberData.default.attr('string'),
    meubles: _emberData.default.hasMany('meuble', {
      async: true,
      inverse: "piece"
    }),
    project: _emberData.default.belongsTo('project', {
      inverse: "pieces"
    }),
    volumeTotal: Ember.computed("meubles.@each.counted_volume", function () {
      var total = 0;
      this.get('meubles').forEach(function (item) {
        total += item.get('counted_volume');
      });
      return Math.round(total * 100) / 100;
    }),
    volumeTotalRounded: Ember.computed("volumeTotal", function () {
      return Math.round(this.get("volumeTotal") * 10) / 10;
    }),
    hasDemontage: Ember.computed("meubles.@each.demontage", function () {
      return !Ember.isEmpty(this.get('meubles').findBy('demontage'));
    }),
    hasCartonsALivrer: Ember.computed("meubles.@each.type", function () {
      return !Ember.isEmpty(this.get('meubles').findBy('is_carton'));
    }),
    hasPenderies: Ember.computed("meubles.@each.type", function () {
      return !Ember.isEmpty(this.get('meubles').findBy('is_penderie'));
    }),
    cartonsStdCount: Ember.computed('meubles.@each.{is_carton_std,quantity,isDeleted}', function () {
      var count = 0;
      this.get('meubles').filterBy('is_carton_std', true).forEach(function (carton) {
        if (!carton.get('isDeleted')) {
          count += carton.get('quantity');
        }
      });
      return Math.round(count);
    }),
    cartonsLivCount: Ember.computed('meubles.@each.{is_carton_liv,quantity,isDeleted}', function () {
      var count = 0;
      this.get('meubles').filterBy('is_carton_liv', true).forEach(function (carton) {
        if (!carton.get('isDeleted')) {
          count += carton.get('quantity');
        }
      });
      return Math.round(count);
    }),
    penderiesCount: Ember.computed('meubles.@each.{is_penderie,quantity,isDeleted}', function () {
      var count = 0;
      this.get('meubles').filterBy('is_penderie', true).forEach(function (carton) {
        if (!carton.get('isDeleted')) {
          count += carton.get('quantity');
        }
      });
      return Math.round(count);
    })
  });

  _exports.default = _default;
});