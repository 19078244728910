define("dem-editor-v2/routes/import", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      this.controllerFor("application").set("title", "Importer un dossier");
      return Ember.Object.create({
        "num_client": params.num_client,
        "importing": false
      });
    },
    setupController: function (controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      controller.set('importing', false);
    },
    actions: {
      importClient: function (num_client) {
        this.get("controller").set("importing", true);
        var server = _environment.default.APP.API_HOST;
        var url = server + "/import/" + num_client;

        var _this = this;

        _jquery.default.get(url, function () {
          _this.transitionTo('project.client', num_client);
        }).fail(function (error) {
          var errorText = "Problème d'import du dossier.";
          if (error.status == 404) errorText = "Dossier non trouvé dans le CRM.";else if (error.status == 403) errorText = error.responseText;

          _jquery.default.growl.error({
            title: errorText,
            message: ''
          });

          console.error(error);
        }).always(function () {
          _this.get("controller").set("importing", false);
        });
      }
    }
  });

  _exports.default = _default;
});