define("dem-editor-v2/models/photo", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    file_name: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    s3_url: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    file: _emberData.default.attr()
  });

  _exports.default = _default;
});