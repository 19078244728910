define("dem-editor-v2/controllers/baseadresse", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global getEtapeServiceName */
  var _default = Ember.Controller.extend({
    project: Ember.inject.controller('project'),
    availableLogementTypes: Object.freeze([Ember.Object.create({
      name: "Appartement",
      value: "DGTS_LOG_APPART"
    }), Ember.Object.create({
      name: "Maison",
      value: "DGTS_LOG_MAISON"
    }), Ember.Object.create({
      name: "Garde meuble",
      value: "DGTS_LOG_GARDEMEUBLE"
    }), Ember.Object.create({
      name: "Duplex",
      value: "DGTS_LOG_DUPLEX"
    }), Ember.Object.create({
      name: "Triplex",
      value: "DGTS_LOG_TRIPLEX"
    }), Ember.Object.create({
      name: "Bureaux",
      value: "DGTS_LOG_BUREAUX"
    }), Ember.Object.create({
      name: "Garde meuble déménageur",
      value: "DGTS_LOG_GARDEMEUBLE_DGR"
    }), Ember.Object.create({
      name: "Autre - à préciser",
      value: "DGTS_LOG_OTHER"
    }), Ember.Object.create({
      name: "Mise sur trottoir",
      value: "DGTS_LOG_TROTTOIR"
    }), Ember.Object.create({
      name: "Mise en déchetterie",
      value: "DGTS_LOG_DECHETTERIE"
    }), Ember.Object.create({
      name: "A définir",
      value: "DGTS_LOG_UNDEFINED"
    })]),
    selectedLogement: Ember.computed('model.type_logement', {
      set: function (key, logement) {
        this.get('model').set('type_logement', logement.get('value'));
        return logement;
      },
      get: function () {
        //REPLACE "^DGTS_LOG_APPART^" with "DGTS_LOG_APPART"
        var type_logement = this.get('model').get('type_logement').replace(/^\^+|\^+$/gm, ''); //DEFAULT TYPE LOGEMENT

        if (Ember.isBlank(type_logement)) {
          type_logement = "DGTS_LOG_APPART";
        }

        return this.get('availableLogementTypes').findBy('value', type_logement);
      }
    }),
    monteMeubleSuggestions: Object.freeze(["tracté", "échelle électrique", "porté", "tracté ou échelle électrique", "porté ou tracté"]),
    ascenseurSuggestions: Object.freeze(["1-2 pers", "2-3 pers", "3-4 pers", "4-5 pers", "5-6 pers", "7-8 pers", "8-10 pers", "> 10 pers", "petit", "moyen", "grand", "non utilisable"]),
    allInLiftSuggestions: Object.freeze(["Cartons uniquement", "Cartons & petits mobiliers uniquement"]),
    caveSuggestions: Object.freeze(["par ascenseur", "par escalier", "rampe extérieure"]),
    escaliersSuggestions: Object.freeze(["colimaçon", "étroit"]),
    statSuggestions: Object.freeze(["places de stationnement en bas", "places de stationnement côté opposé", "places de livraison en bas", "places de livraison côté opposé", "parking privé", "en double file", "voie de bus"]),
    autorSuggestions: Object.freeze(["impératif", "impératif, RATP", "fait par le client", "à demander au gardien", "plots à enlever"]),
    vlSuggestions: Object.freeze([]),
    statDifficileSuggestions: Object.freeze(["PL porteur max sans remorque"]),
    marchesSuggestions: Object.freeze(['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']),
    portageSuggestions: Object.freeze(['0-10 m', '10-20 m', '20-30 m', '30-40 m', '40-50 m', '50-60 m', '60-70 m', '70-80 m', '80-100 m', '100-150 m']),
    minarmPortageSuggestions: Object.freeze(['0 m', '10 m', '20 m', '30 m', '40 m', '50 m', '60 m', '70 m', '80 m', '100 m']),
    etagesSuggestions: Object.freeze(['0', '1', '2', '3', '4', '5', '6', '7', '8']),
    refreshStreetView: Ember.computed('fullAdresse', {
      set: function (key, value) {
        return value;
      },
      get: function () {
        return true;
      }
    }),
    wasSpecialLogement: false,
    specialLogementObserver: Ember.observer('model.isSpecialLogement', function () {
      var prevValue = this.get('wasSpecialLogement');
      var nowValue = this.get('model').get('isSpecialLogement');

      if (prevValue !== nowValue) {
        this.set('refreshStreetView', true);
        this.set('wasSpecialLogement', nowValue);
      }
    }),

    toggleMmConseille() {
      var model = this.get('model');

      if (model.get('mm_obligatoire')) {
        model.set('mm_conseille', 1);
        this.toggleProperty('refreshMmConseille');
      }

      if (!model.get('mm_utilisable')) {
        model.set('mm_conseille', 0);
        this.toggleProperty('refreshMmConseille');
      }
    },

    enableMmConseille: Ember.computed('model.{mm_utilisable,mm_obligatoire}', function () {
      var model = this.get('model');
      Ember.run.scheduleOnce('afterRender', this, this.toggleMmConseille);
      return !model.get('mm_obligatoire') && model.get('mm_utilisable');
    }),
    enableAllInLift: Ember.computed('model.lift', function () {
      const model = this.get('model');
      const lift = model.get('lift');

      if (!lift) {
        model.set('all_in_lift', 0);
        model.set('all_in_lift_comment', null);
      }

      return lift;
    }),
    etapesChargement: Ember.computed('model.services_chargement', function () {
      var model = this.get('model');

      if (model.get('isNew')) {
        var project = this.get('project').get('model');

        if (model.get('type') === 1) {
          return [Ember.Object.create({
            livraison: project.get('adresseLivraison')
          })];
        }
      }

      return this.get('model').get('services_chargement');
    }),
    etapesLivraison: Ember.computed('model.services_livraison', function () {
      var model = this.get('model');

      if (model.get('isNew')) {
        var project = this.get('project').get('model');

        if (model.get('type') === 2) {
          return [Ember.Object.create({
            chargement: project.get('adresseChargement')
          })];
        }
      }

      return this.get('model').get('services_livraison');
    }),
    nextTransition: null,

    setStationnementAsSelected(stationnement) {
      stationnement.set("selected", true);
    },

    selectedStationnement: Ember.computed('model.stationnements.@each.isLoaded', {
      get: function () {
        var allStationnements = this.get('model').get('stationnements');

        if (allStationnements != null) {
          var selected = allStationnements.filterBy('selected', true).get('firstObject');

          if (selected != null) {
            return selected;
          }

          var firstStationnement = allStationnements.get('firstObject');

          if (firstStationnement != null && firstStationnement.get('isLoaded') && !firstStationnement.get('selected')) {
            Ember.run.once(this, this.setStationnementAsSelected, firstStationnement);
            return firstStationnement;
          }

          return null;
        }

        return null;
      },
      set: function (key, value) {
        return value;
      }
    }),
    postalError: Ember.computed("model.{postal,country}", function () {
      var model = this.get("model");
      var country = model.get("country");
      var postal = model.get("postal");
      if (Ember.isBlank(postal)) return "Code postal incorrect";

      if (!country || country == "FR") {
        if (!(postal.length == 5 && parseInt(postal) < 96000)) return "Code postal incorrect";
      }

      return null;
    }),
    fullAdresse: Ember.computed("model.{city,fullTextAddress}", function () {
      var model = this.get('model');

      if (!model.get('city')) {
        return null;
      }

      return model.get('fullTextAddress');
    }),

    focusError() {
      var elem = (0, _jquery.default)('div.has-error input').first();
      elem.select().focus();
    },

    actions: {
      changeAddressOrder: function (newOrder) {
        this.send('changeOrder', this.get('model'), newOrder);
      },
      addStationnement: function () {
        var stat = this.store.createRecord('stationnement', {
          adresse: this.get('model')
        });
        this.send("selectStationnement", stat);
      },
      selectStationnement: function (stationnement) {
        var previousSelected = this.get('selectedStationnement');

        if (previousSelected != null) {
          previousSelected.set('selected', false);
        }

        stationnement.set('selected', true);
        this.set('selectedStationnement', stationnement);
      },
      confirmDeleteStationnement: function () {
        var selectedStationnement = this.get("selectedStationnement");

        if (selectedStationnement != null) {
          var statId = selectedStationnement.get('id');
          var name = selectedStationnement.get('type');

          if (!name) {
            name = "";
          }

          selectedStationnement.set("selected", false);
          selectedStationnement.deleteRecord();

          if (statId != null) {
            selectedStationnement.save().then(function () {
              _jquery.default.growl.notice({
                title: 'Stationnement supprimé',
                message: name
              });
            }).catch(function (error) {
              _jquery.default.growl.error({
                title: 'Stationnement non supprimé',
                message: error.message
              });

              return;
            });
          } else {
            _jquery.default.growl.notice({
              title: 'Stationnement supprimé',
              message: name
            });
          }
        }
      },
      confirmDelete: function () {
        var model = this.get("model");
        var oldOrder = model.get('order');
        var name = model.get("adresse_descriptor");

        var _this = this;

        this.send("changeOrder", null, oldOrder);
        this.send('putInLoadingState');

        var errorFunction = function (error) {
          _jquery.default.growl.error({
            title: 'Adresse non supprimée',
            message: error.message
          });

          console.log("Erreur lors de la suppression d'une adresse");
          console.error(error);

          _this.replaceRoute(_this.get('routeRoot') + '.adresse', model.get('id'));

          return;
        };

        Ember.RSVP.all(model.get('stationnements').invoke('destroyRecord')).then(function () {
          model.destroyRecord().then(function () {
            //Save other addresses if we have modified their order
            var otherAddressesToSave = _this.get('parentController').get('model').filter(function (item) {
              return item.get('hasDirtyAttributes') && !item.get('isDeleted');
            });

            Ember.RSVP.all(otherAddressesToSave.invoke('save')).then(function () {
              _jquery.default.growl.notice({
                title: 'Adresse supprimée',
                message: name
              });

              var findFirstAddress = _this.get('parentController').get('orderedModels').get('firstObject');

              if (findFirstAddress) {
                _this.replaceRoute(_this.get('routeRoot') + '.adresse', findFirstAddress.get('id'));
              } else {
                _this.transitionToRoute(_this.get('routeRoot'));
              }
            }).catch(errorFunction);
          }).catch(errorFunction);
        }).catch(errorFunction);
      },
      confirmSave: function () {
        //IF ALREADY LOADING, DONT DO ANYTHING
        if (this.get('isLoading')) return; //SET LOADING STATE

        this.set('isLoading', true);

        var _this = this;

        var model = this.get("model");
        var transition = this.get("nextTransition");
        var isNew = model.get('isNew');

        var onError = function (error) {
          console.log("Erreur lors de la sauvegarde d'une adresse");
          console.error(error);

          _jquery.default.growl.error({
            title: 'Adresse non enregistrée',
            message: "Champs manquants"
          }); //Go back to the address if not new


          _this.set('isLoading', false);

          Ember.run.scheduleOnce('afterRender', _this, _this.focusError);
          return;
        };

        if (!Ember.isBlank(this.get("postalError"))) {
          onError("Code postal incorrect");
          return false;
        }

        var isSpecialLogement = model.get('isSpecialLogement');
        var stationnementsToSave = [];

        if (isSpecialLogement) {
          //DELETE STATIONNEMENTS
          model.get('stationnements').invoke('destroyRecord');
        } else {
          stationnementsToSave = model.get('stationnements').filterBy('hasDirtyAttributes', true);
        } //Save other addresses if we have modified their order


        var otherAddressesToSave = this.get('parentController').get('model').filter(function (item) {
          return item.get('hasDirtyAttributes') && item.get('id') !== model.get('id');
        });
        var toSave = [];
        toSave.pushObjects(stationnementsToSave);
        toSave.pushObjects(otherAddressesToSave); //ADD ETAPES THAT WERE MODIFIED BECAUSE OF DISTANCE OR ORDER MODIFICATIONS

        if (!isNew) {
          toSave.pushObjects(this.get('etapesChargement').filterBy('hasDirtyAttributes', true));
          toSave.pushObjects(this.get('etapesLivraison').filterBy('hasDirtyAttributes', true));
        }

        var project = this.get('project').get('model');

        if (project.get('hasDirtyAttributes')) {
          toSave.pushObject(project);
        } //IF SPECIAL LOGEMENT TYPE, RESET ADDRESS VALUES


        if (isSpecialLogement) {
          model.set('stairs', false);
          model.set('stairs_comment', null);
          model.set('floor', 0);
          model.set('lift', false);
          model.set('lift_comment', null);
          model.set('mm_utilisable', 0);
          model.set('mm_obligatoire', 0);
          model.set('mm_conseille', 0);
          model.set('mm_comment', null);
          model.set('cave', false);
          model.set('cave_comment', null);
          model.set('street', '-');
        }

        model.save().then(function () {
          Ember.RSVP.all(toSave.invoke('save')).then(function () {
            //CREATE ASSOCIATED ETAPE IF POSSIBLE
            if (isNew) {
              var isChargement = model.get('type') === 1;

              var project = _this.get('project').get('model');

              var otherAddresses = project.get('adresses').filterBy('type', isChargement ? 2 : 1);

              if (otherAddresses.get('length') === 1) {
                var otherAddress = otherAddresses.get('firstObject');
                var chargement = isChargement ? model : otherAddress;
                var livraison = isChargement ? otherAddress : model;
                var fakeEtape = isChargement ? _this.get('etapesChargement') : _this.get('etapesLivraison');
                var serviceName = getEtapeServiceName(chargement, livraison, null);

                var newEtape = _this.store.createRecord('service', {
                  name: serviceName,
                  type: 1,
                  key: "address",
                  project: project,
                  include_vol: true,
                  chargement: chargement,
                  livraison: livraison,
                  distance: fakeEtape.get('firstObject').get('distance')
                });

                newEtape.set('color', newEtape.calcNewColor(chargement.get('order'), livraison.get('order')));
                newEtape.save();
              }
            } //Reload addresses only if is new and if leaving for the same route


            if (isNew && (transition == null || transition.targetName.indexOf(_this.get('routeRoot')) > -1)) {
              _this.get('parentController').set('isNewAdresse', false);

              _this.send("reloadAddresses");
            }

            _jquery.default.growl.notice({
              title: 'Adresse enregistrée',
              message: model.get('adresse_descriptor')
            });

            if (!Ember.isNone(transition)) {
              model.set('currentlySelected', false);
              transition.retry();
            }

            _this.set('isLoading', false);
          }).catch(onError);
        }, onError);
      },
      confirmDeleteNewAdresse: function () {
        var model = this.get("model");
        model.deleteRecord();
        var parentController = this.get('parentController');
        parentController.set('isNewAdresse', false);

        _jquery.default.growl.notice({
          title: 'Nouvelle adresse supprimée',
          message: ''
        });

        var findFirstAddress = parentController.get('orderedModels').get('firstObject');

        if (findFirstAddress) {
          this.replaceRoute(this.get('routeRoot') + '.adresse', findFirstAddress.get('id'));
        } else {
          this.transitionToRoute(this.get('routeRoot'));
        }
      }
    }
  });

  _exports.default = _default;
});