define("dem-editor-v2/helpers/safestring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.safestring = safestring;
  _exports.default = void 0;

  /* global Handlebars */
  function safestring(params
  /*, hash*/
  ) {
    var escaped = "";

    for (var i = 0; i < params.length; i++) {
      escaped += Handlebars.Utils.escapeExpression(params[i]);
    }

    return new Ember.String.htmlSafe(escaped);
  }

  var _default = Ember.Helper.helper(safestring);

  _exports.default = _default;
});