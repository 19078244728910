define("dem-editor-v2/helpers/holiday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isHoliday = isHoliday;
  _exports.default = void 0;

  /* global moment */
  const easter = y => {
    const c = Math.floor(y / 100);
    const n = y - 19 * Math.floor(y / 19);
    const k = Math.floor((c - 17) / 25);
    let i = c - Math.floor(c / 4) - Math.floor((c - k) / 3) + 19 * n + 15;
    i = i - 30 * Math.floor(i / 30);
    i = i - Math.floor(i / 28) * (1 - Math.floor(i / 28) * Math.floor(29 / (i + 1)) * Math.floor((21 - n) / 11));
    let j = y + Math.floor(y / 4) + i + 2 - c + Math.floor(c / 4);
    j = j - 7 * Math.floor(j / 7);
    const l = i - j;
    const m = 3 + Math.floor((l + 40) / 44);
    const d = l + 28 - 31 * Math.floor(m / 4);
    return moment([y, m - 1, d]);
  };

  const getEasterDate = (y, diff) => {
    if (diff > 0) {
      return easter(y).add(diff, 'days');
    }

    return easter(y).substract(diff, 'days');
  };

  const getHolidays = year => [moment(`${year}-01-01`), getEasterDate(year, 1), // Lundi de Pâques
  moment(`${year}-05-01`), moment(`${year}-05-08`), getEasterDate(year, 39), // Jeudi de l'Ascension
  getEasterDate(year, 50), // Lundi de Pentecôte
  moment(`${year}-07-14`), moment(`${year}-08-15`), moment(`${year}-11-01`), moment(`${year}-11-11`), moment(`${year}-12-25`)];

  function isHoliday(date) {
    const year = moment(date).year();
    const hollidays = getHolidays(year);
    return !hollidays.every(holliday => !holliday.isSame(date));
  }

  var _default = Ember.Helper.helper(isHoliday);

  _exports.default = _default;
});