define("dem-editor-v2/components/country-select", ["exports", "dem-editor-v2/components/auto-complete"], function (_exports, _autoComplete) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _autoComplete.default.extend({
    countries: Ember.inject.service("country-list"),
    suggestions: Ember.computed('countries.list', function () {
      return this.get("countries").get("list");
    }),
    value: Ember.computed('model.isLoaded', 'refresh', {
      get: function () {
        var model = this.get('model');
        var countryCode = model.get("country");
        if (Ember.isBlank(countryCode)) return null;
        var country = this.get("suggestions").filterBy("value", countryCode).get("firstObject");
        if (country) return country.text;
        return null;
      },
      set: function (key, value) {
        return value;
      }
    }),
    focusOut: function () {
      this.toggleProperty("refresh");
    },
    actions: {
      onItemSelected: function (country) {
        var countryCode = country.value;
        var model = this.get("model");
        model.set("country", countryCode);
        var domTom = this.get("countries").get("domTomPostals").filterBy("value", countryCode).get("firstObject");

        if (domTom) {
          var postal = model.get("postal");
          if (postal && postal.substr(0, 3) != domTom.postal.substr(0, 3)) model.set("postal", domTom.postal);
        }
      }
    }
  });

  _exports.default = _default;
});