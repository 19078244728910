define("dem-editor-v2/controllers/project/chd", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    projectController: Ember.inject.controller('project'),

    init() {
      this._super(...arguments);

      this.set('versions', this.get('versions') || []);
    },

    currentVersion: null,
    isLoading: false,
    status_message: null,
    isFirstVersion: Ember.computed('versions', function () {
      return this.get('versions').length === 1;
    }),
    newComment: null,
    editedComment: Ember.computed('currentVersion', {
      get: function () {
        var comment = this.get('newComment');

        if (comment == null) {
          var currentVersion = this.get('currentVersion');
          return currentVersion == null || this.get('isFirstVersion') ? "" : currentVersion.comment;
        }

        return comment;
      },
      set: function (key, value) {
        this.set('newComment', value);
        return value;
      }
    }),
    reloadCHD: Ember.observer('model.shouldReloadCHD', function () {
      if (this.get('model').get('shouldReloadCHD')) {
        this.get('model').set('shouldReloadCHD', false);
        Ember.run.scheduleOnce('actions', this, this.doReloadCHDAndVersions);
      }
    }),

    doReloadCHDAndVersions() {
      this.send('loadChd');
      this.send('loadVersions');
    },

    actions: {
      save: function (newVersion) {
        if (this.get('isLoading')) {
          return;
        }

        (0, _jquery.default)('#chd-data').hide();
        this.set('isLoading', true);
        var project = this.get('model');

        var _this = this;

        var server = _environment.default.APP.API_HOST;
        var url = server + "/save/" + project.get('num_client');
        url += "?new_version=" + (newVersion ? "true" : "false");
        url += "&comment=" + this.get('editedComment');

        _jquery.default.get(url, function (data) {
          var finish = function () {
            (0, _jquery.default)('#chd-data').show();

            _this.set("status_message", data);

            (0, _jquery.default)('#status-message').fadeIn(1000).delay(120000).fadeOut(1500); //WE DON'T KNOW THE CHD VERSION DATE BUT WE KNOW IT IS NOW UP TO DATE, SO RESET THE FLAG

            project.set('chdNotUpToDate', false);

            _this.set('isLoading', false);

            _this.send('loadVersions');
          }; //RELOAD APPEL OFFRES - DEMENAGEURS LIST MAY HAVE BEEN MODIFIED


          project.get('appoff').then(function (model) {
            if (Ember.isNone(model)) {
              _this.store.query('project', {
                num_client: project.get('num_client')
              }).then(finish);
            } else {
              model.reload().then(finish);
            }
          });
        });
      },
      toggleShowDiff: function (version_index) {
        (0, _jquery.default)('#diff_version_' + version_index).toggle();
      },
      askRevert: function (version) {
        this.set("selectedVersionToRevert", version);
        (0, _jquery.default)("#confirmRevert").modal('show');
      },
      confirmRevert: function () {
        if (this.get('isLoading')) {
          return;
        }

        var versionToRevert = this.get('selectedVersionToRevert');

        if (Ember.isNone(versionToRevert)) {
          _jquery.default.growl.error({
            title: 'Aucune version sélectionnée !',
            message: ''
          });

          return;
        }

        this.set('selectedVersionToRevert', null);
        (0, _jquery.default)('#chd-data').hide();
        this.set('isLoading', true);
        var numClient = this.get('model').get('num_client');

        var _this = this;

        var server = _environment.default.APP.API_HOST;
        var url = server + "/versions/revert/" + numClient + "/" + versionToRevert.version_id;

        _jquery.default.get(url, function () {
          var proj = _this.get('model');

          proj.set('adresses', []);
          proj.set('services', []);
          proj.set('pieces', []);
          proj.set('dateranges', []); //RELOAD PROJECT

          _this.store.query('project', {
            num_client: numClient
          }).then(function () {
            proj.reload().then(function (reloadedModel) {
              reloadedModel.set('last_modified', moment.utc().format());
              reloadedModel.set('last_selected_address_1', null);
              reloadedModel.set('last_selected_address_2', null);
              reloadedModel.set('lastPieceOpened', null);
              reloadedModel.toggleProperty('shouldReloadServices');

              _this.send('loadChd');

              _this.send('loadVersions');

              _this.set('isLoading', false);

              _jquery.default.growl.notice({
                title: 'Données chargées !',
                message: 'Données de la v' + versionToRevert.version_index
              });
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});