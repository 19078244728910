define("dem-editor-v2/templates/components/daterange-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wloo1q7e",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"class\",[29,[[22,\"inputClassName\"]]]],[10,\"autocomplete\",\"KO\"],[11,\"value\",[29,[[22,\"value\"]]]],[10,\"type\",\"text\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/daterange-picker.hbs"
    }
  });

  _exports.default = _default;
});