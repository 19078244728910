define("dem-editor-v2/templates/components/slide-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fN2/pCk+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\",true],[10,\"id\",\"c-menu--slide-right\"],[10,\"class\",\"c-menu c-menu--slide-right\"],[10,\"style\",\"overflow-y: auto;\"],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"c-menu__close\"],[8],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-remove\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\" Fermer\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/slide-menu.hbs"
    }
  });

  _exports.default = _default;
});