define("dem-editor-v2/controllers/project/appoff", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment, md5 */
  var _default = Ember.Controller.extend({
    projectController: Ember.inject.controller('project'),
    editorOptions: Object.freeze({
      menu: false,
      menubar: false,
      language: 'fr_FR',
      language_url: '/assets/scripts/tinymce/fr_FR.js',
      statusbar: false,
      visual: false,
      allow_html_in_named_anchor: true,
      content_css: '/assets/css/mail-appoff.css',
      height: 600
    }),
    isLoading: false,
    disableAddingMover: true,
    otherDemenageurs: Ember.computed('allDemenageurs.[]', 'model.demenageurs.[]', 'disableAddingMover', 'projectController.model.{volume,distance,isCommissionnaire}', function () {
      var appoff = this.get('model');
      var selectedDemenageurs = appoff.get('demenageurs');
      var addingMoverIsDisabled = this.get('disableAddingMover');
      var project = this.get('projectController').get('model');
      return this.get('allDemenageurs').map(function (demenageur) {
        return Ember.Object.create({
          "demenageur": demenageur,
          "reponse": appoff.get_reponse_for_demenageur(demenageur.get('id'))
        });
      }).filter(function (obj) {
        var allowMover = !addingMoverIsDisabled;

        if (allowMover) {
          var maxVolSmall = obj.demenageur.get('dgrs_type_volmax_smalldist');
          var maxVolLong = obj.demenageur.get('dgrs_type_volmax_longdist');

          var isLongDist = project.get('distance') > _environment.default.IDEM_SETTINGS.MIN_LONG_DISTANCE;

          allowMover = (!project.get('isCommissionnaire') || obj.demenageur.get('minarm_access')) && (isLongDist || maxVolSmall === null || project.get('volume') <= maxVolSmall) && (!isLongDist || maxVolLong === null || project.get('volume') <= maxVolLong);
        }

        return !selectedDemenageurs.includes(obj.demenageur) && (obj.reponse || obj.demenageur.get('wasSelected') || allowMover);
      }).sortBy('reponse.reponse_text', 'demenageur.postal');
    }),
    selectedDemenageurs: Ember.computed('model.demenageurs.[]', function () {
      var appoff = this.get('model');
      return appoff.get('demenageurs').map(function (demenageur) {
        return Ember.Object.create({
          "demenageur": demenageur,
          "reponse": appoff.get_reponse_for_demenageur(demenageur.get('id'))
        });
      }).sortBy('reponse.reponse_text', 'demenageur.postal');
    }),
    demenageursMapper: function (item) {
      if (item == null) {
        return {
          text: "",
          emberObject: ""
        };
      }

      var demenageur = item.get('demenageur');
      var text = demenageur.get('diminutif');

      if (Ember.isBlank(text)) {
        text = demenageur.get('name');
      }

      return {
        text: text,
        emberObject: demenageur
      };
    },
    appoffSubject: Ember.computed('model.{subject,urgent}', {
      get: function () {
        var appoff = this.get('model');
        var subject = appoff.get('subject');

        if (Ember.isNone(subject)) {
          subject = "";
        }

        subject = subject.replace(/\[URGENT\]/g, '').trim();

        if (appoff.get('urgent')) {
          return '[URGENT] ' + subject;
        }

        return subject;
      },
      set: function (key, value) {
        this.get('model').set('subject', value);
        return value;
      }
    }),
    cannotSaveAppoff: Ember.computed('dateInvalid', 'subjectInvalid', 'demenageurCountInvalid', function () {
      return this.get('dateInvalid') || this.get('subjectInvalid') || this.get('demenageurCountInvalid');
    }),
    dateInvalid: Ember.computed('model.date_best', function () {
      var date = this.get('model').get('date_best');
      return moment(date) < moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    }),
    subjectInvalid: Ember.computed('model.subject', function () {
      return Ember.isBlank(this.get('model').get('subject'));
    }),
    demenageurCountInvalid: Ember.computed('model.demenageurs.[]', function () {
      return this.get('model').get('demenageurs').get('length') <= 0;
    }),

    doUpdateDateEnd() {
      var appoff = this.get('model');
      var momentBest = moment(appoff.get('date_best'));
      var momentLast = moment(appoff.get('date_end'));

      if (momentBest.isAfter(momentLast)) {
        var newDate = momentBest.add(2, 'days');
        var weekDay = newDate.isoWeekday();

        if (weekDay >= 6) {
          newDate = newDate.add(8 - weekDay, 'days');
        }

        appoff.set('date_end', newDate.format('YYYY-MM-DD'));
      }
    },

    updateDateEnd: Ember.observer('model.date_best', function () {
      Ember.run.scheduleOnce('actions', this, this.doUpdateDateEnd);
    }),

    replaceMailContent(contentId, newContent) {
      var appoffContent = this.get('realMailContent');
      var dataHtml = (0, _jquery.default)(appoffContent);
      dataHtml.find('#' + contentId).html(newContent);
      this.set('realMailContent', (0, _jquery.default)('<div>').append(dataHtml.clone()).html());
    },

    replaceDateInMailContent: function (date_id) {
      var date = moment(this.get('model').get(date_id));
      this.replaceMailContent(date_id, date.format("DD/MM/YYYY"));
    },
    realMailContent: Ember.computed('model.mail_content', {
      get: function () {
        return this.get('model').get('mail_content');
      },
      set: function (key, value) {
        return value;
      }
    }),
    urgentReplace: Ember.observer('model.{urgent,mail_content}', function () {
      var urgent = this.get('model').get('urgent');
      var content = urgent ? "Cet appel d'offres est URGENT. " : "";
      Ember.run.scheduleOnce('actions', this, this.replaceMailContent, "urgent", content);
    }),
    dateEndReplace: Ember.observer('model.{date_end,mail_content}', function () {
      Ember.run.scheduleOnce('actions', this, this.replaceDateInMailContent, 'date_end');
    }),
    dateBestReplace: Ember.observer('model.{date_best,mail_content}', function () {
      Ember.run.scheduleOnce('actions', this, this.replaceDateInMailContent, 'date_best');
    }),
    bounceOutDemenageur: function (tableId, demenageurList, demenageur) {
      var elem = (0, _jquery.default)("#" + tableId + " tr").eq(demenageurList.mapBy('demenageur').indexOf(demenageur));
      elem.addClass('bounceOut');
    },
    bounceInDemenageur: function (tableId, demenageurList, demenageur) {
      var elem = (0, _jquery.default)("#" + tableId + " tr").eq(demenageurList.mapBy('demenageur').indexOf(demenageur));
      var invDiv = (0, _jquery.default)("#" + tableId);
      invDiv.scrollTop(elem.offset().top - invDiv.offset().top + invDiv.scrollTop());
      elem.addClass('bounceIn');
    },
    actions: {
      addDemenageur: function (demenageur) {
        if (!Ember.isNone(demenageur)) {
          this.bounceOutDemenageur("not-selected", this.get('otherDemenageurs'), demenageur);
          Ember.run.later(this, function () {
            var demenageurs = this.get('model').get('demenageurs');

            if (!demenageurs.includes(demenageur)) {
              demenageurs.pushObject(demenageur);

              _jquery.default.growl.notice({
                title: 'Déménageur ajouté',
                message: demenageur.get('diminutif')
              });

              Ember.run.scheduleOnce('afterRender', this, this.bounceInDemenageur, "selected", this.get('selectedDemenageurs'), demenageur);
            }
          }, 200);
        }
      },
      removeAllDemenageurs: function () {
        var dgrs = this.get('model').get('demenageurs');
        dgrs.forEach(function (dgr) {
          dgr.set("wasSelected", true);
        });
        dgrs.clear();
      },
      removeDemenageur: function (demenageur) {
        if (!Ember.isNone(demenageur)) {
          demenageur.set('wasSelected', true);
          this.bounceOutDemenageur("selected", this.get('selectedDemenageurs'), demenageur);
          Ember.run.later(this, function () {
            var demenageurs = this.get('model').get('demenageurs');

            if (demenageurs.includes(demenageur)) {
              demenageurs.removeObject(demenageur);

              _jquery.default.growl.notice({
                title: 'Déménageur enlevé',
                message: demenageur.get('diminutif')
              });
            }
          }, 200);
        }
      },
      saveAppoff: function () {
        if (this.get('isLoading')) {
          return;
        }

        this.set("isLoading", true);

        var _this = this;

        this.get('model').set('mail_content', this.get('realMailContent'));
        this.get('model').save().then(function () {
          //RESET SCROLL
          (0, _jquery.default)("body").scrollTop(0);

          _this.set("isLoading", false);

          _this.set("status_message", "Appel d'offres envoyé avec succès !");

          (0, _jquery.default)('#status-message').fadeIn(1000).delay(30000).fadeOut(1500);

          _jquery.default.growl.notice({
            title: "Appel d'offres envoyé !",
            message: ""
          });
        }).catch(function (error) {
          _this.set('isLoading', false);

          console.log("Erreur lors de l'envoi de l'appel d'offres.");
          console.error(error);

          _jquery.default.growl.error({
            title: "Appel d'offres non envoyé !",
            message: error
          });
        });
      },
      unlockAddingMover: function () {
        var password = "3e9ff101b0dc3b156c85c79b08f0d392";
        var hashedPassword = md5(this.get('passwordForUnlock', ''));
        this.set('passwordForUnlock', '');

        if (hashedPassword == password) {
          _jquery.default.growl.notice({
            title: "Ajout débloqué !",
            message: ""
          });

          this.set("disableAddingMover", false);
        } else {
          _jquery.default.growl.error({
            title: "Mot de passe invalide !",
            message: ""
          });
        }
      }
    }
  });

  _exports.default = _default;
});