define("dem-editor-v2/routes/application", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    COOKIE_USERNAME: "username",
    COOKIE_USERHASH: "userhash",
    COOKIE_USERID: "userid",
    COOKIE_IS_ADMIN: "is_admin",
    savedTransition: null,
    model: function (params, transition) {
      var userHash = this.getCookie(this.get('COOKIE_USERHASH'));
      var userId = this.getCookie(this.get('COOKIE_USERID'));
      var userName = this.getCookie(this.get('COOKIE_USERNAME'));
      var isAdmin = this.getCookie(this.get('COOKIE_IS_ADMIN'));
      var isAuthenticated = userHash !== null && userHash !== "" && userName !== null && userName !== "";

      if (isAuthenticated) {
        this.authorizeJQuery(userName, userHash);
      } else if (transition.targetName !== "login") {
        transition.abort();
        this.set('savedTransition', transition);
        this.transitionTo('login');
      }

      return Ember.Object.create({
        username: userName,
        userid: userId,
        isAuthenticated: isAuthenticated,
        isAdmin: isAdmin === "1"
      });
    },

    setCookie(cname, cvalue, exdays) {
      var expires = "";

      if (exdays > 0) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        expires = "; expires=" + d.toUTCString();
      }

      document.cookie = cname + "=" + cvalue + expires;
    },

    getCookie(cname) {
      var re = new RegExp(cname + "=([^;]*)");
      var match = document.cookie.match(re);

      if (match && match !== undefined) {
        return Ember.get(document.cookie.match(re), "1");
      }

      return null;
    },

    authorizeJQuery(username, userhash) {
      //CONFIGURE JQUERY TO SEND USER HEADERS AS WELL
      _jquery.default.ajaxSetup({
        beforeSend: function (xhr) {
          xhr.setRequestHeader('crm-username', username);
          xhr.setRequestHeader('crm-userhash', userhash);
        }
      });
    },

    actions: {
      login: function () {
        var _this = this;

        var model = this.modelFor('application');
        model.set('loggingIn', true);
        model.set('error', false);
        var server = _environment.default.APP.API_HOST;
        var url = server + "/login";
        var username = model.get('username');
        var userhash = window.md5(model.get('password'));
        this.setCookie(this.get('COOKIE_USERNAME'), username, 3650);
        var params = {
          "username": username,
          "userhash": userhash
        };

        _jquery.default.post(url, params, function (data) {
          _this.setCookie(_this.get('COOKIE_USERID'), data['user_id'], 3);

          _this.setCookie(_this.get('COOKIE_USERHASH'), userhash, 3);

          _this.setCookie(_this.get('COOKIE_IS_ADMIN'), data['is_admin'], 3);

          _this.authorizeJQuery(username, userhash);

          model.set('isAuthenticated', true);
          model.set('isAdmin', data['is_admin']);
          model.set('userid', data['user_id']);

          var transition = _this.get('savedTransition');

          if (transition != null) {
            transition.retry();
          } else {
            _this.transitionTo('index');
          }
        }).fail(function () {
          model.set('isAuthenticated', false);
          model.set('isAdmin', false);
          model.set('password', '');
          model.set('error', true);
        }).always(function () {
          model.set('loggingIn', false);
        });
      },
      logout: function () {
        this.setCookie(this.get('COOKIE_USERID'), "", -1);
        this.setCookie(this.get('COOKIE_USERHASH'), "", -1);
        this.setCookie(this.get('COOKIE_IS_ADMIN'), "", -1);
        this.modelFor('application').set('isAuthenticated', false);
        this.modelFor('application').set('isAdmin', false);
        this.transitionTo('login');
      },
      exitProject: function () {
        this.transitionTo('index');
      },
      willTransition: function (transition) {
        var model = this.modelFor('application');

        if (!model.get('isAuthenticated') && transition.targetName !== "login") {
          transition.abort();
          this.set('savedTransition', transition);
          this.transitionTo('login');
        }
      }
    }
  });

  _exports.default = _default;
});