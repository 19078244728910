define("dem-editor-v2/models/meuble", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    volume: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    demontage: _emberData.default.attr('boolean'),
    emballage: _emberData.default.attr('boolean'),
    observations: _emberData.default.attr('string'),
    type: _emberData.default.belongsTo('meuble-type', {
      async: true
    }),
    piece: _emberData.default.belongsTo('piece', {
      inverse: 'meubles',
      async: true
    }),
    services: _emberData.default.hasMany('service', {
      inverse: 'meubles',
      async: true
    }),
    isOpened: false,
    isSelected: false,
    is_fourniture: Ember.computed('type', function () {
      var meubleType = this.get('type');

      if (meubleType) {
        var idType = meubleType.get('id');
        return idType <= 4;
      }

      return false;
    }),
    isOfType: function (type_id) {
      var meubleType = this.get('type');

      if (meubleType) {
        var idType = meubleType.get('id');
        return idType === type_id;
      }

      return false;
    },
    is_carton: Ember.computed('is_carton_std', 'is_carton_liv', function () {
      return this.get('is_carton_std') || this.get('is_carton_liv');
    }),
    is_carton_std: Ember.computed('type', function () {
      return this.isOfType("1");
    }),
    is_carton_liv: Ember.computed('type', function () {
      return this.isOfType("2");
    }),
    is_penderie: Ember.computed('type', function () {
      return this.isOfType("3");
    }),
    has_cartons: Ember.computed('type', function () {
      var meubleType = this.get('type');

      if (meubleType) {
        return meubleType.get('has_cartons');
      }

      return true;
    }),
    special_service: Ember.computed('type', function () {
      var meubleType = this.get('type');

      if (meubleType) {
        return meubleType.get('special_service');
      }

      return null;
    }),
    serviceChooserId: Ember.computed('id', function () {
      return "service-chooser-" + this.get('id');
    }),
    eq_cartons_liv: Ember.computed('volume', function () {
      var volumeType = 0.05;
      var cartonsType = 1;
      return Math.ceil(this.get('volume') / volumeType * cartonsType);
    }),
    eq_cartons_std: Ember.computed('volume', function () {
      var volumeType = 0.1;
      var cartonsType = 1;
      return Math.ceil(this.get('volume') / volumeType * cartonsType);
    }),
    should_include_vol: Ember.computed('services.@each.include_vol', function () {
      var services = this.get('services');

      if (services.get('length') > 0) {
        var included = services.filterBy('include_vol', true);

        if (included.get('length') > 0) {
          return !(services.get('length') > 1 && included.get('length') === 1 && included.get('firstObject').get('isMainService'));
        }

        return false;
      }

      return true;
    }),
    not_deleted_services: Ember.computed('services.@each.isDeleted', function () {
      return this.get('services').filterBy('isDeleted', false);
    }),
    counted_volume: Ember.computed('volume', 'should_include_vol', 'isDeleted', function () {
      if (!this.get('isDeleted') && this.get('should_include_vol')) {
        return Math.round(this.get('volume') * 100) / 100;
      }

      return 0;
    }),
    volume_type_from_dimensions: Ember.computed('observations', function () {
      const desc = this.get('observations');

      if (desc) {
        const match = desc.match(/(\d+) x (\d+) x (\d+)/);

        if (match) {
          return Math.round(parseInt(match[1]) * parseInt(match[2]) * parseInt(match[3]) / 10000) / 100;
        }
      }

      return null;
    }),

    isPartOfAddressService(addressService) {
      return this.get('services').includes(addressService);
    }

  });

  _exports.default = _default;
});