define("dem-editor-v2/controllers/project/client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    dgtTypes: Object.freeze([Ember.Object.create({
      name: "Particulier",
      value: "DGT_TYPE_PARTICULIER"
    }), Ember.Object.create({
      name: "Entreprise",
      value: "DGT_TYPE_ENTREPRISE"
    }), Ember.Object.create({
      name: "Militaire",
      value: "DGT_TYPE_MILITAIRE"
    }), Ember.Object.create({
      name: "Mutation professionnelle",
      value: "DGT_TYPE_MUTATION"
    })]),
    civTypes: Object.freeze([Ember.Object.create({
      name: "Monsieur",
      value: "Mr."
    }), Ember.Object.create({
      name: "Madame",
      value: "Mrs."
    }), Ember.Object.create({
      name: "Mademoiselle",
      value: "Ms."
    })]),
    updateClientName: Ember.observer('model.showClientName', function () {
      var model = this.get('model'); //SI DEJA EXISTANT, NE PAS DONNER LA VALEUR PAR DEFAUT

      if (model.get('showClientName') && !model.get('name_to_show')) {
        model.set('name_to_show', model.get('first_name') + " " + model.get('last_name'));
        this.toggleProperty('nameRefresh');
      }
    })
  });

  _exports.default = _default;
});