define("dem-editor-v2/templates/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gsfopd/B",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-content\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"col-xs-4 col-xs-push-4\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"importing\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"style\",\"width: 100%; height: 100%; text-align: center; padding: 30px;\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/assets/images/loader.gif\"],[10,\"height\",\"32\"],[10,\"width\",\"32\"],[8],[9],[0,\" \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\\tImport en cours...\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"h3\",true],[8],[0,\"Dossier \"],[1,[24,[\"model\",\"num_client\"]],false],[0,\" non trouvé\"],[9],[0,\"\\n\\t\\t\\t\\tAucun dossier trouvé dans l'Editor pour le numéro client \"],[1,[24,[\"model\",\"num_client\"]],false],[0,\".\"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h4\",true],[8],[0,\"Créer un nouveau dossier ?\"],[9],[7,\"br\",true],[8],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",\"btn btn-primary\"],[12,\"style\",\"width: 100%\"],[3,\"action\",[[23,0,[]],\"importClient\",[24,[\"model\",\"num_client\"]]]],[8],[0,\"Importer\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/import.hbs"
    }
  });

  _exports.default = _default;
});