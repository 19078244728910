define("dem-editor-v2/templates/components/etape-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e2LJCkak",
    "block": "{\"symbols\":[\"etapeObject\"],\"statements\":[[4,\"each\",[[24,[\"getEtapes\"]]],null,{\"statements\":[[7,\"tr\",true],[8],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[28,\"input\",null,[[\"type\",\"id\",\"checked\",\"disabled\"],[\"checkbox\",[23,1,[\"chooserId\"]],[23,1,[\"isSelected\"]],[23,1,[\"isDisabled\"]]]]],false],[9],[0,\"\\n    \"],[7,\"td\",true],[8],[1,[28,\"service-box\",null,[[\"service\"],[[23,1,[\"service\"]]]]],false],[9],[0,\"\\n    \"],[7,\"td\",true],[11,\"class\",[29,[[28,\"if\",[[23,1,[\"isSelected\"]],\"selected\"],null]]]],[8],[0,\"\\n        \"],[7,\"label\",true],[11,\"for\",[29,[[23,1,[\"chooserId\"]]]]],[8],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"service-description\"],[8],[1,[23,1,[\"service\",\"name\"]],false],[9],[7,\"br\",true],[8],[9],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"adresses-description\"],[8],[1,[23,1,[\"service\",\"adressesDescriptionString\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/etape-chooser.hbs"
    }
  });

  _exports.default = _default;
});