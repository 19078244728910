define("dem-editor-v2/routes/basemultiadresse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      var project = this.modelFor('project');
      var type = this.get("type");
      return project.get('adresses').then(function (items) {
        return items.filterBy('type', type);
      });
    },
    redirect: function (model) {
      var route = this.get('routeRoot') + '.adresse';
      var params = this.paramsFor(route);

      if (!params.adresse_id) {
        var lastSelectedObject = this.modelFor('project').get('last_selected_address_' + this.get('type'));

        if (Ember.isNone(lastSelectedObject) || lastSelectedObject.get('isDeleted')) {
          lastSelectedObject = model.get('firstObject');
        }

        if (!Ember.isNone(lastSelectedObject) && !lastSelectedObject.get('isDeleted')) {
          this.transitionTo(route, lastSelectedObject);
        }
      }
    },
    renderTemplate: function () {
      return this.render('project.location');
    },

    updateOrders(address, orderValue) {
      var oldOrder; //Cas de la suppression d'une adresse

      if (address === null) {
        oldOrder = orderValue;
        orderValue = 100000;
      } else {
        oldOrder = address.get('order');
      }

      var change = orderValue > oldOrder ? -1 : 1;
      var minOrder = Math.min(oldOrder, orderValue);
      var maxOrder = Math.max(oldOrder, orderValue);
      this.get('controller').get('model').forEach(function (item) {
        if (item.get("isDeleted")) {
          return;
        }

        var order = item.get('order');

        if (order < minOrder || order > maxOrder) {
          return;
        }

        if (address !== null && item.get('id') === address.get('id')) {
          item.set('order', orderValue);
          return;
        }

        if (change !== 0) {
          item.set('order', order + change);
        }
      });
    },

    actions: {
      reloadAddresses: function () {
        this.refresh();
      },
      changeOrder: function (model, orderValue) {
        Ember.run.scheduleOnce("actions", this, this.updateOrders, model, orderValue);
        return false;
      }
    }
  });

  _exports.default = _default;
});