define("dem-editor-v2/components/project-verifier", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onlyShowErrors: false,
    fixCartons: false,
    store: Ember.inject.service(),
    energyUtils: Ember.inject.service('energy-utils'),

    init() {
      this._super(...arguments);

      this.get('energyUtils').requestTransfer(this.get('project'));
    },

    applyAddDefaultService: function (serviceRecord) {
      var project = this.get('project');
      var formuleValue = project.get('formuleObject').get('value');
      var newValue = formuleValue;
      project.get('services').filterBy('isDefaultService').forEach(function (service) {
        var servObject = project.get('defaultServices').findBy('key', service.get('key'));
        newValue += servObject.get('value');
      });
      var newFormule = project.get('availableFormules').filter(function (item) {
        return item.get('value') <= newValue;
      }).get('lastObject');
      var countedValue = 0;
      var objectsToSave = [];
      project.get('defaultServices').forEach(function (item) {
        countedValue += item.get('value');

        if (countedValue <= newFormule.get('value')) {
          var existService = project.get('services').findBy('key', item.get('key'));

          if (!Ember.isNone(existService)) {
            existService.deleteRecord();
            objectsToSave.pushObject(existService);
          }
        }
      });

      if (!serviceRecord.get('isDeleted')) {
        objectsToSave.pushObject(serviceRecord);
      }

      project.set('formule', newFormule.get('key'));
      objectsToSave.pushObject(project);
      this.proceedWithSave(project, objectsToSave, "Ajout en cours...", serviceRecord.get('name') + " ajouté !", "shouldReloadServices");
    },
    proceedWithSave: function (project, objectsToSave, proceedTitle, successTitle, propertyToToggle) {
      var growl = _jquery.default.growl({
        title: '<img src="/assets/images/ajax_loader.gif" height="24" width="24"/> ' + proceedTitle,
        fixed: true,
        message: ''
      });

      var onFail = function (error) {
        console.error(error);
        growl.dismiss(function () {
          growl.remove(function () {
            _jquery.default.growl.error({
              title: 'Une erreur est survenue...',
              message: ''
            });
          });
        });
      };

      Ember.RSVP.all(objectsToSave.invoke('save')).then(function () {
        if (propertyToToggle) {
          project.toggleProperty(propertyToToggle);
        }

        project.set('shouldReloadCHD', true);
        growl.dismiss(function () {
          growl.remove(function () {
            _jquery.default.growl.notice({
              title: successTitle,
              message: ''
            });
          });
        });
      }).catch(onFail);
    },
    getMeubleType: function (id) {
      var store = this.get('store');
      var meubleType = store.peekRecord('meuble-type', id);

      if (Ember.isNone(meubleType)) {
        return store.findRecord('meuble-type', id);
      }

      return meubleType;
    },
    actions: {
      toggleFixCartons: function () {
        this.toggleProperty('fixCartons');
      },
      toggleFixDemontage: function () {
        this.toggleProperty('fixDemontage');
      },
      toggleFixPenderies: function () {
        this.toggleProperty('fixPenderies');
      },
      convertCartons: function () {
        var project = this.get('project');
        var objectsToSave = [];

        var _this = this;

        project.get('pieces').forEach(function (piece) {
          piece.get('meubles').filterBy('is_carton', true).forEach(function (carton) {
            var typeId = carton.get('type').get('id');

            if (typeId === "1") {
              carton.set('type', _this.getMeubleType(124));
              carton.set('name', 'Carton standard du client (55x35x30)');
            }

            if (typeId === "2") {
              carton.set('type', _this.getMeubleType(125));
              carton.set('name', 'Carton livres du client (30x35x30)');
            }

            objectsToSave.pushObject(carton);
          });
        });
        this.set('fixCartons', false);
        this.proceedWithSave(project, objectsToSave, "Conversion en cours...", "Cartons convertis !", null);
      },
      addLivraisonCartons: function () {
        var project = this.get('project');
        var newService = this.get('store').createRecord('service', {
          name: "Livraison de cartons et emballages",
          type: 1,
          key: "cartons",
          color: "aaa",
          include_vol: true,
          adresse: null,
          project: project
        });
        this.set('fixCartons', false);
        this.applyAddDefaultService(newService);
      },
      convertPenderies: function () {
        var project = this.get('project');
        var objectsToSave = [];

        var _this = this;

        project.get('pieces').forEach(function (piece) {
          piece.get('meubles').filterBy('is_penderie', true).forEach(function (penderie) {
            penderie.set('type', _this.getMeubleType(1));
            penderie.set('quantity', Math.round(penderie.get('volume') / 0.1));
            penderie.set('name', 'Cartons standards (à livrer)');
            objectsToSave.pushObject(penderie);
          });
        });
        this.set('fixPenderies', false);
        this.proceedWithSave(project, objectsToSave, "Conversion en cours...", "Penderies converties !", null);
      },
      addPenderies: function () {
        var project = this.get('project');
        var newService = this.get('store').createRecord('service', {
          name: "Mise en penderies des habits sur cintres",
          type: 1,
          key: "penderie",
          color: "aaa",
          include_vol: true,
          adresse: null,
          project: project
        });
        this.set('fixPenderies', false);
        this.applyAddDefaultService(newService);
      },
      addDemRem: function () {
        var project = this.get('project');
        var newService = this.get('store').createRecord('service', {
          name: "Démontage et remontage du mobilier",
          type: 1,
          key: "demrem",
          color: "aaa",
          include_vol: true,
          adresse: null,
          project: project
        });
        this.set('fixDemontage', false);
        this.applyAddDefaultService(newService);
      },
      removeDemontage: function () {
        var project = this.get('project');
        var objectsToSave = [];
        project.get('pieces').forEach(function (piece) {
          piece.get('meubles').filterBy('demontage', true).forEach(function (aDemonter) {
            aDemonter.set('demontage', false);
            var observations = aDemonter.get('observations');

            if (Ember.isBlank(observations)) {
              observations = "démonté par client";
            } else {
              observations += " - démonté par client";
            }

            aDemonter.set('observations', observations);
            objectsToSave.pushObject(aDemonter);
          });
        });
        this.set('fixDemontage', false);
        this.proceedWithSave(project, objectsToSave, "Modifications en cours...", "Démontage retiré !", null);
      },
      fixDistance: function () {
        this.fixDistance();
      }
    }
  });

  _exports.default = _default;
});