define("dem-editor-v2/components/overlay-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    opacity_string: Ember.computed('opacity', function () {
      var op = this.get('opacity');

      if (op) {
        return Ember.String.htmlSafe("opacity: " + op);
      }

      return null;
    })
  });

  _exports.default = _default;
});