define("dem-editor-v2/controllers/project/prestations", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global getEtapeServiceName */

  /* global autosize */
  var _default = Ember.Controller.extend({
    project: Ember.inject.controller('project'),
    serviceToDelete: null,
    newServiceChargement: null,
    newServiceLivraison: null,
    serviceInEditMode: null,
    currentPage: 'pagePrestations',
    pagePrestations: true,
    resetTextArea: false,
    newGMService: false,

    resetServiceName() {
      this.set('newServiceName', '');
    },

    gmServiceObserver: Ember.observer('newGMService', function () {
      Ember.run.scheduleOnce("actions", this, this.resetServiceName);
    }),
    isNewServiceInvalid: Ember.computed('newServiceName', 'newServiceType', function () {
      var newName = this.get("newServiceName");
      var newType = this.get("newServiceType");
      return !newName || !newType;
    }),
    isNewAdresseServiceInvalid: Ember.computed('newServiceChargement', 'newServiceLivraison', function () {
      var chargement = this.get('newServiceChargement');
      var livraison = this.get('newServiceLivraison');
      return !chargement || !livraison;
    }),
    isServiceToAssignNull: Ember.computed('serviceToAssign', function () {
      return !this.get('serviceToAssign');
    }),
    suggestionsPrestations: Object.freeze(['Fourniture de papier bulles', 'Formule transport', 'Formule économique', 'Formule standard', 'Formule complète', 'Déménagement meubles uniquement', 'Manutention d\'un piano droit', 'Manutention d\'un piano à queue', 'Manutention d\'un frigidaire américain', 'Manutention d\'un scooter', 'Manutention d\'une moto', 'Manutention d\'un marbre', 'Manutention d\'un aquarium', 'Transport d\'une voiture', 'Mise sur trottoir', 'Mise en déchetterie', 'Transbordement à la livraison', 'Transbordement au chargement']),
    availableAssurances: Ember.computed('project.model.isCommissionnaire', function () {
      var isComm = this.get('project').get('model').get('isCommissionnaire');

      if (isComm) {
        return ["CLASSIQUE", "AUCUNE"];
      }

      return ["CLASSIQUE", "COMPLEMENTAIRE", "AUCUNE"];
    }),
    chosenAssurance: Ember.computed.alias('project.model.assurance'),
    disableAssurance: Ember.computed('project.model.assurance', function () {
      var model = this.get('project').get('model');
      return model.get('assurance') === "AUCUNE";
    }),
    defaultServices: Ember.computed('project.model.defaultServices', function () {
      return this.get('project').get('model').get('defaultServices');
    }),
    availableFormules: Ember.computed('project.model.availableFormules', function () {
      return this.get('project').get('model').get('availableFormules');
    }),
    editingServiceChargement: Ember.computed("serviceInEditMode", {
      get: function () {
        return this.get('serviceInEditMode').get('chargement').get("content");
      },
      set: function (key, value) {
        this.get('serviceInEditMode').set('chargement', value);
        return value;
      }
    }),
    editingServiceLivraison: Ember.computed("serviceInEditMode", {
      get: function () {
        return this.get('serviceInEditMode').get('livraison').get("content");
      },
      set: function (key, value) {
        this.get('serviceInEditMode').set('livraison', value);
        return value;
      }
    }),
    formuleObserver: Ember.observer('formule', function () {
      Ember.run.once(this, this.prepareFormuleItems);
    }),

    prepareFormuleItems() {
      var formule = this.get('formule');
      var formuleValue = 20001;

      if (!Ember.isBlank(formule)) {
        this.get('project').get('model').set('formule', formule.get('key'));
        formuleValue = formule.get("value");
      }

      var value = 0;
      this.get("defaultServices").sortBy("value:desc").forEach(function (item) {
        value += item.get("value");

        if (value <= formuleValue) {
          item.set("in_formule", true);
        } else {
          //if (item.get('type') === 1) {
          //TODO voir si on enleve uniquement les services ou aussi les options
          //ca pose probleme lors de l'initialisation
          item.set("in_formule", false);
        }
      });
    },

    formule: Ember.computed("defaultServices.@each.type", {
      get: function () {
        var value = 0;
        this.get("defaultServices").filterBy('type', 1).forEach(function (item) {
          value += item.get("value");
        });
        var formule = "";
        this.get("availableFormules").forEach(function (item) {
          if (value >= item.get("value")) {
            formule = item;
          }
        });
        return formule;
      },
      set: function (key, newFormule) {
        var formuleValue = 20001;

        if (!Ember.isBlank(newFormule)) {
          formuleValue = newFormule.get("value");
        }

        var value = 0;
        this.get("defaultServices").sortBy("value:desc").forEach(function (item) {
          value += item.get("value");

          if (value <= formuleValue) {
            item.set("type", 1);
          } else {
            //if (item.get('type') === 1) {
            //TODO voir si on enleve uniquement les services ou aussi les options
            //ca pose probleme lors de l'initialisation
            item.set("type", 0);
          }
        });
        return newFormule;
      }
    }),
    initServices: Ember.observer('model.isLoaded', 'project.model.shouldReloadServices', function () {
      var selectedFormule = this.get('availableFormules').findBy('key', this.get('project').get('model').get('formule'));
      this.set('formule', selectedFormule);
      Ember.run.scheduleOnce("actions", this, this.initDefaultServices);
    }),

    initDefaultServices() {
      var selectedDefaultServices = this.get('model').filter(function (item) {
        return item.get("key") !== "custom";
      });
      var defaultServices = this.get('defaultServices');
      defaultServices.forEach(function (item) {
        var selected = selectedDefaultServices.findBy("key", item.get('key'));

        if (selected != null) {
          item.set('type', selected.get('type'));
        }
      });
    },

    initTextArea: Ember.on('init', Ember.observer("currentPage", "serviceInEditMode", "resetTextArea", function () {
      Ember.run.scheduleOnce("afterRender", this, this.resizeTextAreas);
    })),

    resizeTextAreas() {
      autosize((0, _jquery.default)('textarea'));
    },

    customServices: Ember.computed("model.[]", function () {
      return this.get('model').filterBy('key', 'custom');
    }),
    adressesServices: Ember.computed("model.@each.{chargement_order,livraison_order}", function () {
      return this.get('model').filterBy('key', 'address').sortBy('livraison_order', 'chargement_order', 'chargement.type', 'livraison.type', 'id');
    }),
    deleteEnabled: Ember.computed('adressesServices', function () {
      return this.get('adressesServices').get('length') > 1;
    }),
    livraisonIsGMAddress: false,
    newServiceGMData: Ember.Object.create({
      'duration': -1,
      'comment': '',
      'externe': false,
      'relivraison': true
    }),
    actions: {
      showPage: function (page) {
        var currentPage = this.get('currentPage');
        this.toggleProperty(currentPage);
        this.set('currentPage', page);
        this.toggleProperty(page);
      },
      addPrestation: function () {
        var gmData = null;

        if (this.get('newGMService')) {
          gmData = this.get('newServiceGMData');
        }

        var newService = this.store.createRecord('service', {
          name: this.get('newServiceName'),
          type: this.get('newServiceType'),
          key: "custom",
          gm_data: gmData,
          chargement: null,
          livraison: null,
          project: this.get('project').get('model'),
          include_vol: this.get('newServiceType') === 1
        });
        newService.set('color', newService.calcNewColor(0, 0));

        var _this = this;

        newService.save().then(function () {
          _this.set('newServiceName', null);

          _this.set('newServiceType', null);

          _this.get('model').pushObject(newService);

          _this.set('newGMService', false);

          _this.set('newServiceGMData', {
            'duration': -1,
            'comment': '',
            'externe': false,
            'relivraison': !_this.get('livraisonIsGMAddress')
          });

          _this.toggleProperty('resetTextArea');

          _jquery.default.growl.notice({
            title: 'Nouveau service',
            message: newService.get('name')
          });
        }).catch(function (error) {
          console.log("Erreur lors de la création du nouveau service");
          console.log(error);

          _jquery.default.growl.error({
            title: 'Service non créé',
            message: newService.get('name')
          });
        });
      },
      addEtape: function () {
        var chargement = this.get('newServiceChargement');
        var livraison = this.get('newServiceLivraison');
        var serviceName = getEtapeServiceName(chargement, livraison, null);
        var newService = this.store.createRecord('service', {
          name: serviceName,
          type: 1,
          key: "address",
          gm_data: null,
          project: this.get('project').get('model'),
          include_vol: true,
          chargement: chargement,
          livraison: livraison
        });
        newService.updateColor();

        var _this = this;

        newService.save().then(function () {
          _this.set('newServiceChargement', null);

          _this.set('newServiceLivraison', null);

          _this.get('model').pushObject(newService);

          _this.toggleProperty('resetTextArea');

          _jquery.default.growl.notice({
            title: 'Nouvelle étape',
            message: newService.get('name')
          });
        }).catch(function (error) {
          console.log("Erreur lors de la création d'une étape");
          console.error(error);

          _jquery.default.growl.error({
            title: 'Etape non créée',
            message: newService.get('name')
          });
        });
      },
      deleteService: function (service) {
        this.set('serviceToDelete', service);
        var meubles = service.get('meubles');
        var popupToShow = '#confirmDeleteService'; //CHECK IF SERVICE CAN BE DELETED

        if (service.get('isAdresseService')) {
          var m1 = meubles.filter(function (meuble) {
            if (meuble.get('isDeleted')) {
              return false;
            }

            var chargServ = meuble.get('services').find(function (serv) {
              return serv !== service && serv.get('isAdresseService');
            });
            return Ember.isNone(chargServ);
          });

          if (m1.get('length') > 0) {
            this.set('meublesToModify', m1);
            this.set('newServicesToAssign', this.get('adressesServices').filter(function (item) {
              return item !== service;
            }));
            popupToShow = '#confirmDeleteAdresseService';
          }
        }

        (0, _jquery.default)(popupToShow).modal('show');
        return false;
      },
      confirmDeleteAdresseService: function () {
        var growl = _jquery.default.growl({
          title: '<img src="/assets/images/ajax_loader.gif" height="24" width="24"/> Suppression en cours...',
          fixed: true,
          message: ''
        });

        var serviceToAssign = this.get('serviceToAssign');
        var itemsToSave = [];
        this.get('meublesToModify').forEach(function (item) {
          var services = item.get('services');

          if (!services.includes(serviceToAssign)) {
            services.pushObject(serviceToAssign);
            itemsToSave.pushObject(item);
          }
        });
        Ember.RSVP.all(itemsToSave.invoke('save')).then(() => {
          this.set('meublesToModify', null);
          this.set('serviceToAssign', null);
          this.send('confirmDelete', growl);
        });
      },
      confirmDelete: function (growl) {
        var serviceDeleted = this.get('serviceToDelete');
        var project = this.get('project').get('model'); //CHARGEMENT AND LIVRAISON CAN BE THE SAME - DELETE ONE AT A TIME, THEN SAVE ONE AT A TIME

        if (this.get('alsoDeleteChargement')) {
          serviceDeleted.get('chargement').then(function (charg) {
            var chargement_order = charg.get('order');
            charg.get('stationnements').then(function (stats) {
              stats.invoke('destroyRecord');
              charg.destroyRecord();
            });
            project.get('adressesChargement').filter(function (adresse) {
              return adresse.get('order') > chargement_order;
            }).forEach(function (adresse) {
              adresse.set('order', adresse.get('order') - 1);
              adresse.save();
            });
          });
          this.set('alsoDeleteChargement', false);
        }

        if (this.get('alsoDeleteLivraison')) {
          serviceDeleted.get('livraison').then(function (livr) {
            if (!livr.get('isDeleted')) {
              var livraison_order = livr.get('order');
              livr.get('stationnements').then(function (stats) {
                stats.invoke('destroyRecord');
                livr.destroyRecord();
              });
              project.get('adressesLivraison').filter(function (adresse) {
                return adresse.get('order') > livraison_order;
              }).forEach(function (adresse) {
                adresse.set('order', adresse.get('order') - 1);
                adresse.save();
              });
            }
          });
          this.set('alsoDeleteLivraison', false);
        }

        if (this.get('alsoDeleteDates')) {
          serviceDeleted.get('stepSpecificDates').invoke('destroyRecord');
          this.set('alsoDeleteDates', false);
        }

        var name = serviceDeleted.get('name');
        serviceDeleted.destroyRecord().then(function () {
          if (growl) {
            growl.dismiss(function () {
              growl.remove(function () {
                _jquery.default.growl.notice({
                  title: 'Service supprimé',
                  message: name
                });
              });
            });
          } else {
            _jquery.default.growl.notice({
              title: 'Service supprimé',
              message: name
            });
          }
        });
      },
      toggleInclude: function (service) {
        service.toggleProperty('include_vol');
      },
      editService: function (service) {
        var editingService = this.get('serviceInEditMode');

        if (editingService) {
          editingService.set('editing', false);
        }

        this.set('serviceInEditMode', service);

        if (service) {
          service.set('editing', true);
        }
      },
      toggleEtapeAsGM: function (service) {
        if (service.get('isGMService')) {
          service.set('gm_data', null);
          service.updateServiceName();
        } else {
          service.set('gm_data', {
            'duration': -1,
            'comment': '',
            'externe': false,
            'relivraison': true
          });
        }

        this.send("editService", null);
      }
    }
  });

  _exports.default = _default;
});