define("dem-editor-v2/helpers/first-capital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.firstCapital = firstCapital;
  _exports.default = void 0;

  function firstCapital(params
  /*, hash*/
  ) {
    return params[0].substring(0, 1).toUpperCase() + params[0].substring(1);
  }

  var _default = Ember.Helper.helper(firstCapital);

  _exports.default = _default;
});