define("dem-editor-v2/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    computedTitle: Ember.computed('openedProject.{num_client,last_name}', 'title', function () {
      var openedProject = this.get('openedProject');

      if (openedProject == null) {
        return this.get('title');
      } else {
        return openedProject.get('num_client') + ' - ' + openedProject.get('last_name');
      }
    })
  });

  _exports.default = _default;
});