define("dem-editor-v2/controllers/basemultiadresse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    orderedModels: null,
    modelObserver: Ember.observer('model.@each.order', 'model.@each.isDeleted', function () {
      Ember.run.once(this, this.updateOrderedModels);
    }),

    updateOrderedModels() {
      this.set('orderedModels', this.get('model').filterBy("isDeleted", false).sortBy('order'));
    },

    maxOrder: Ember.computed('orderedModels', function () {
      var orderedModels = this.get('orderedModels');

      if (orderedModels.get('length') > 0) {
        return orderedModels.get('lastObject').get('order');
      }

      return 0;
    }),
    availableOrderValues: Ember.computed('maxOrder', function () {
      var last = this.get('maxOrder');
      var orders = [];

      for (var i = 1; i <= last; i++) {
        orders.push(i);
      }

      return orders;
    })
  });

  _exports.default = _default;
});