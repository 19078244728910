define("dem-editor-v2/routes/project/appoff", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      var project = this.modelFor('project');
      return project.get('appoff');
    },
    redirect: function (model) {
      if (Ember.isNone(model)) {
        _jquery.default.growl.error({
          title: 'Pas d\'appel d\'offres !',
          message: "Sauvegarder le dossier d'abord."
        });

        var project = this.modelFor('project');
        this.transitionTo('project.chd', project);
      }
    },
    setupController: function (controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      controller.set('allDemenageurs', this.get('allDemenageurs'));
      controller.set('disableAddingMover', true);
    },
    beforeModel: function () {
      if (typeof tinymce === 'undefined') {
        return _jquery.default.getScript('//cdn.tiny.cloud/1/t2lb7n18mia4t93feakywp8hol8iv7xxftwvqabyf1ans4c6/tinymce/5/tinymce.min.js');
      }
    },
    afterModel: function () {
      var _this = this;

      var allDemenageurs = this.store.peekAll('demenageur');

      if (allDemenageurs.get('length') < _environment.default.IDEM_SETTINGS.DEMENAGEURS_COUNT) {
        return this.store.findAll('demenageur').then(function (results) {
          _this.set('allDemenageurs', results);
        });
      }

      this.set('allDemenageurs', allDemenageurs);
    }
  });

  _exports.default = _default;
});