define("dem-editor-v2/mixins/computed-piece-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    get: function (key) {
      var specialElems = this.findSpecialValueElements(key);
      var total = 0;
      specialElems.forEach(function (elem) {
        total += parseFloat(elem.get('quantity'));
      });
      return Math.round(total * 100) / 100;
    },

    resetKey(key) {
      this.set(key, 0);
    },

    set: function (key, value) {
      var oldValue = this.get(key);

      if (isNaN(parseFloat(value))) {
        value = 0;
      }

      if (isNaN(parseFloat(oldValue))) {
        oldValue = 0;
      }

      var addedValue = Math.round((parseFloat(value) - parseFloat(oldValue)) * 100) / 100;
      var specialElems = this.findSpecialValueElements(key);
      var meubleTypes = this.get('inventaire').get('model').types;
      var meubleType = meubleTypes.findBy('id', this.keyToMeubleTypeId(key));

      if (!meubleType && value > 0) {
        this.send('showError', 'Aucun meuble type trouvé pour la clé ' + key);
        Ember.run.scheduleOnce('actions', this, this.resetKey, key);
        return value;
      }

      var serviceAdresse = this.get('notNullSelectedAdresseService');

      if (!serviceAdresse && value > 0) {
        this.send('showError', "Impossible d'ajouter des cartons, car aucune étape de déménagement n'est sélectionnée.");
        Ember.run.scheduleOnce('actions', this, this.resetKey, key);
        return value;
      }

      var qteType = parseFloat(meubleType.get('quantite_type'));
      var volType = parseFloat(meubleType.get('volume_type'));
      var addedVolume = Math.round(parseFloat(addedValue / qteType * volType) * 100) / 100;

      if (!specialElems || specialElems.get('length') === 0) {
        if (value > 0) {
          meubleType.set('quantite_type', addedValue);
          meubleType.set('volume_type', addedVolume);
          this.send('createMeuble', meubleType);
        }
      } else {
        if (value > 0) {
          var specialElem = specialElems.get('firstObject');
          specialElem.set('quantity', Math.round((parseFloat(specialElem.get('quantity')) + addedValue) * 10) / 10);
          specialElem.set('volume', Math.round((parseFloat(specialElem.get('volume')) + addedVolume) * 100) / 100);
        } else {
          specialElems.forEach(function (item) {
            item.deleteRecord();
          });
        }
      }

      return value;
    }
  });

  _exports.default = _default;
});