define("dem-editor-v2/templates/components/service-tagger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kpCDu64o",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"each\",[[24,[\"tags\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"span\",false],[12,\"role\",\"button\"],[12,\"class\",\"tag label\"],[3,\"action\",[[23,0,[]],\"toggleTag\",[23,1,[]]]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/service-tagger.hbs"
    }
  });

  _exports.default = _default;
});