define("dem-editor-v2/helpers/service-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.serviceClass = serviceClass;
  _exports.default = void 0;

  function serviceClass(params
  /*, hash*/
  ) {
    var serviceType = params[0];

    if (serviceType === 1) {
      return "service";
    } else if (serviceType === 2) {
      return "option";
    }

    return "";
  }

  var _default = Ember.Helper.helper(serviceClass);

  _exports.default = _default;
});