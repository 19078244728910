define("dem-editor-v2/components/meuble-multi-modifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    enable: false,
    classNameBindings: ['enable::hide'],
    multiSelectCount: Ember.computed('selectedMeubles', function () {
      return this.get('selectedMeubles').get('length');
    }),
    actions: {
      confirmMultiDelete: function () {
        var _this = this;

        var allElemsToDelete = this.get('selectedMeubles');
        allElemsToDelete.forEach(function (item) {
          _this.sendAction("deleteMeuble", item);
        });
        this.set('multiSelect', false);
      },
      showServiceChooser: function () {
        this.showServiceChooser(this.get('selectedMeubles'));
      }
    }
  });

  _exports.default = _default;
});