define("dem-editor-v2/templates/project/inventaire/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9LFoyM/b",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"piece-editor\"],[10,\"style\",\"width: 100%;\"],[10,\"class\",\"editor-height panel panel-default\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"style\",\"width: 100%; height: 100%; text-align: center;\"],[8],[0,\"\\n    \\t\"],[7,\"img\",true],[10,\"src\",\"/assets/images/loader.gif\"],[10,\"style\",\"margin-top: 184px;\"],[10,\"height\",\"32\"],[10,\"width\",\"32\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/project/inventaire/loading.hbs"
    }
  });

  _exports.default = _default;
});