define("dem-editor-v2/routes/project/chargement/adresse", ["exports", "dem-editor-v2/routes/baseadresse"], function (_exports, _baseadresse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseadresse.default.extend({
    type: 1,
    routeRoot: "project.chargement"
  });

  _exports.default = _default;
});