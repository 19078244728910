define("dem-editor-v2/controllers/project/inventaire/piece", ["exports", "jquery", "dem-editor-v2/mixins/computed-piece-value"], function (_exports, _jquery, _computedPieceValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    inventaire: Ember.inject.controller('project.inventaire'),

    init() {
      this._super(...arguments);

      this.set('modifiedMeubles', this.get('modifiedMeubles') || []);
      this.set('meublesForServiceChooser', this.get('meublesForServiceChooser') || []);
    },

    multiSelect: false,
    selectedCategory: null,
    selectedSubCategory: null,
    showMeubleCreator: false,
    isSearchingAdresseService: false,
    cartons_std: Ember.computed('model.meubles.@each.{isDeleted,quantity,not_deleted_services}', 'notNullSelectedAdresseService', Ember.Object.extend(_computedPieceValue.default).create()),
    cartons_liv: Ember.computed('model.meubles.@each.{isDeleted,quantity,not_deleted_services}', 'notNullSelectedAdresseService', Ember.Object.extend(_computedPieceValue.default).create()),
    penderies: Ember.computed('model.meubles.@each.{isDeleted,quantity,not_deleted_services}', 'notNullSelectedAdresseService', Ember.Object.extend(_computedPieceValue.default).create()),
    divers: Ember.computed('model.meubles.@each.{isDeleted,quantity,not_deleted_services}', 'notNullSelectedAdresseService', Ember.Object.extend(_computedPieceValue.default).create()),
    orderedMeubles: Ember.computed('model.meubles', function () {
      return this.get('model').get('meubles').toArray().sort(function (a, b) {
        var type1 = a.get('type');
        var type2 = b.get('type');
        var id1 = type1 && type1.get('id') <= 4 ? type1.get('id') : a.get('id') + 5;
        var id2 = type2 && type2.get('id') <= 4 ? type2.get('id') : b.get('id') + 5;

        if (id1 <= 4 && id2 <= 4) {
          return id1 - id2;
        }

        if (id1 > 4 && a.get('isNew')) {
          id1 = a.get('timeCreated') + b.get('id') + 5;
        }

        if (id2 > 4 && b.get('isNew')) {
          id2 = b.get('timeCreated') + a.get('id') + 5;
        }

        return id1 - id2;
      });
    }),
    keyToMeubleTypeId: function (key) {
      if (key === "cartons_std") {
        return "1";
      } else if (key === "cartons_liv") {
        return "2";
      } else if (key === "penderies") {
        return "3";
      } else if (key === "divers") {
        return "4";
      }

      return "ERROR";
    },
    findSpecialValueElements: function (key) {
      var meubleTypeId = this.keyToMeubleTypeId(key);
      var serviceAdresse = this.get('notNullSelectedAdresseService');
      var foundMeubles = this.get('model').get('meubles').filter(function (meuble) {
        if (!meuble.get('type') || meuble.get('type').get('id') !== meubleTypeId || meuble.get('isDeleted')) {
          return false;
        }

        var services = meuble.get('not_deleted_services');
        return services.get('length') === 1 && services.includes(serviceAdresse);
      });
      return foundMeubles;
    },
    notNullSelectedAdresseService: Ember.computed('inventaire.{selectedAdresseService,adresseServices.[]}', function () {
      var serv = this.get('inventaire').get('selectedAdresseService');

      if (serv) {
        return serv;
      } else {
        return this.get('inventaire').get('adresseServices').get('firstObject');
      }
    }),
    multiSelectCheck: Ember.computed('multiSelect', 'multiSelectedMeubles', {
      get: function () {
        return this.get('multiSelect');
      },
      set: function (key, value) {
        var multiSelect = this.get('multiSelect');
        var selectedMeubles = this.get('multiSelectedMeubles');
        var count = selectedMeubles.get('length');

        if (value === true) {
          if (!multiSelect || count > 0) {
            this.set('multiSelect', true);
            let meubles = this.get('model').get('meubles');

            if (this.get('isSearchingAdresseService')) {
              let searchedAddress = this.get('notNullSelectedAdresseService');
              meubles = meubles.filter(meuble => meuble.isPartOfAddressService(searchedAddress));
            }

            meubles.forEach(function (item) {
              item.set('isSelected', true);
            });
          } else {
            this.set('multiSelect', false);
          }
        }

        if (value === false && multiSelect) {
          if (count > 0) {
            selectedMeubles.forEach(function (item) {
              item.set('isSelected', false);
            });
          } else {
            this.set('multiSelect', false);
          }
        }

        return value;
      }
    }),
    deletePieceButtons: Object.freeze([Ember.Object.create({
      title: 'Annuler',
      dismiss: 'modal'
    }), Ember.Object.create({
      title: 'Valider',
      clicked: "confirmDelete",
      type: "primary"
    })]),
    selectedMeuble: null,
    isEditing: false,
    meubleAdded: false,
    lastMeubleOpened: null,
    multiSelectedMeubles: Ember.computed('model.meubles.@each.isSelected', function () {
      return this.get('model').get('meubles').filterBy('isSelected', true);
    }),
    lastActionText: Ember.computed('modifiedMeubles.length', function () {
      var modifiedObjects = this.get('modifiedMeubles');

      if (modifiedObjects.length > 0) {
        var lastModified = modifiedObjects.get('lastObject');
        var action = '';

        if (lastModified.get('isDeleted')) {
          action = 'Suppression';
        } else if (lastModified.get('isNew')) {
          action = 'Création';
        } else {
          action = 'Modification';
        }

        return action + " de " + lastModified.get('name');
      }

      return null;
    }),
    findJQueryElementForMeuble: function (item) {
      var index = this.get("orderedMeubles").indexOf(item);
      var elem = (0, _jquery.default)("#inventaire > tbody > tr:not('.not-a-row')").eq(index);
      return elem;
    },
    scrollToMeuble: function (item) {
      var elem = this.findJQueryElementForMeuble(item);
      var invDiv = (0, _jquery.default)("#inventaire").parent();
      invDiv.scrollTop(elem.offset().top - invDiv.offset().top + invDiv.scrollTop());
      elem.find('td.inv-qty.quantity input.qtyinput').focus();
    },
    openAndScrollToMeuble: function (meuble) {
      meuble.set("isOpened", true);
      Ember.run.scheduleOnce('afterRender', this, this.scrollToMeuble, meuble);
    },

    bounceOutDomElem(elem) {
      elem.addClass('bounceOut');
    },

    scrollToInvTop: function () {
      var invDiv = (0, _jquery.default)("#inventaire").parent();
      invDiv.scrollTop(invDiv.scrollTop() + (0, _jquery.default)('#inventaire tr').first().height());
    },
    actions: {
      searchAdresseService: function () {
        this.toggleProperty('isSearchingAdresseService');
      },
      showServiceChooser: function (meubles) {
        var chooserToShow; //HIDE OTHER POPOVERS

        if (Ember.isNone(meubles.get('length'))) {
          chooserToShow = '#' + meubles.get('serviceChooserId');
          meubles = [meubles];
        } else {
          chooserToShow = '#multiServicesChooser';
        }

        (0, _jquery.default)('[data-popover-target="serviceChooser"]').not(chooserToShow).popover('destroy');
        this.set('meublesForServiceChooser', meubles);
      },
      cancelLastModif: function () {
        var lastModified = this.get('modifiedMeubles').popObject();

        if (lastModified != null) {
          var deleted = lastModified.get('isDeleted');
          lastModified.rollbackAttributes();

          if (deleted) {
            Ember.run.scheduleOnce('afterRender', this, this.scrollToMeuble, lastModified);
          }
        }
      },
      createMeuble: function (meubleType) {
        var serviceAdresse = this.get('notNullSelectedAdresseService');

        if (!serviceAdresse) {
          _jquery.default.growl.error({
            title: 'Erreur',
            message: "Impossible de créer un élément, car aucune étape de déménagement n'est sélectionnée."
          });

          return;
        }

        var name = meubleType;
        var volume = 0.25;
        var demontage = 0;
        var emballage = 0;
        var type = null;
        var quantity = 1;
        var dontScroll = false;
        var description = null;

        if (meubleType && meubleType.constructor.name !== "String") {
          name = meubleType.get('nom_type');
          volume = meubleType.get('volume_type');
          emballage = meubleType.get('emballage_type');
          demontage = meubleType.get('demontage_type');
          quantity = meubleType.get('quantite_type');
          description = meubleType.get('description_type');
          dontScroll = meubleType && meubleType.get('id') <= 4;

          if (!meubleType.get('fake_type')) {
            meubleType.rollbackAttributes();
            type = meubleType;
          }
        }

        var newMeuble = this.store.createRecord('meuble', {
          name: name,
          volume: volume,
          piece: this.get("model"),
          emballage: emballage,
          demontage: demontage,
          type: type,
          quantity: quantity,
          observations: description
        });
        newMeuble.set("timeCreated", new Date().getTime());
        newMeuble.set('services', [serviceAdresse]); //MODIF: don't allow to cancel a meuble creation (simply delete it if needed)
        //this.get('modifiedMeubles').pushObject(newMeuble);

        this.set("newName", "");

        if (!dontScroll) {
          Ember.run.scheduleOnce('afterRender', this, this.openAndScrollToMeuble, newMeuble);
        } else {
          Ember.run.scheduleOnce('afterRender', this, this.scrollToInvTop);
        }
      },
      deleteMeuble: function (meuble) {
        _jquery.default.growl.notice({
          title: 'Meuble supprimé',
          message: meuble.get('name')
        });

        Ember.run.scheduleOnce('afterRender', this, this.bounceOutDomElem, this.findJQueryElementForMeuble(meuble));
        Ember.run.later(this, function () {
          var servicesToRemove = meuble.get('services').filter(function (serv) {
            return serv.get('key') === "custom" && serv.get('meubles').filterBy('isDeleted', false).get('length') === 1;
          });

          if (servicesToRemove.get('length') > 0) {
            this.send('removeSpecialServices', servicesToRemove);
          } //if it is a newly created one, we just cancel the creation
          //Inconvénient : on ne peut pas annuler cette suppression


          if (meuble.get("isNew")) {
            this.get('modifiedMeubles').removeObject(meuble);
            meuble.deleteRecord();
            return;
          }

          meuble.set('isSelected', false);
          meuble.deleteRecord();
          this.get('modifiedMeubles').pushObject(meuble);
        }, 400);
      },
      addToPieceValue: function (valueType, nToAdd, meuble) {
        this.set(valueType, parseFloat(this.get(valueType)) + parseFloat(nToAdd));

        if (meuble) {
          meuble.set("isOpened", false);
        }
      },
      selectCategory: function (category) {
        var previous = this.get('selectedCategory');

        if (previous != null) {
          previous.set('isSelected', false);
        }

        category.set('isSelected', true);
        this.set("selectedCategory", category);
        this.send("selectSubCategory", "all");
        this.set('showMeubleCreator', false);
      },
      selectSubCategory: function (subCategory) {
        var previous = this.get('selectedSubCategory');

        if (previous != null && previous !== "all") {
          previous.set('subSelected', false);
        }

        if (subCategory === "all") {
          this.set("selectedSubCategory", this.get("selectedCategory"));
        } else {
          subCategory.set('subSelected', true);
          this.set('selectedSubCategory', subCategory);
        }

        this.set('showMeubleCreator', false);
      },
      showMeubleCreator: function () {
        this.toggleProperty('showMeubleCreator');
        var isShowing = this.get('showMeubleCreator');

        if (isShowing) {
          var previous = this.get('selectedCategory');

          if (previous != null) {
            previous.set('isSelected', false);
          }
        }
      },
      addSpecialService: function (meuble) {
        this.get('inventaire').set('specialServiceMeuble', meuble);
        (0, _jquery.default)("#specialServiceAddPopup").modal('show');
      },
      removeSpecialServices: function (servicesToRemove) {
        servicesToRemove.invoke('set', 'toDelete', true);
        this.get('inventaire').set('specialServicesToRemove', servicesToRemove);
        (0, _jquery.default)("#specialServicesRemovePopup").modal('show');
      },
      showError: function (errorMessage) {
        _jquery.default.growl.error({
          title: 'Erreur',
          message: errorMessage
        });
      }
    }
  });

  _exports.default = _default;
});