define("dem-editor-v2/routes/project/photos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function () {
      var projectToken = this.modelFor('project').get('token');
      var photos = this.store.peekAll('photo').filterBy('token', projectToken);

      if (photos.get('length') <= 0) {
        photos = this.store.query("photo", {
          token: projectToken
        });
      }

      return photos;
    },

    model() {
      return this.store.peekAll('photo');
    },

    actions: {
      putPhoto: function () {
        this.intermediateTransitionTo('project.photos');
      },
      willTransition: function (transition) {
        var controller = this.get("controller");
        var model = controller.get('model');
        var toSave = [];
        model.forEach(function (item) {
          if (item.get('hasDirtyAttributes')) {
            toSave.pushObject(item);
          }
        });

        if (!Ember.isEmpty(toSave)) {
          transition.abort();
          this.send("putPhoto");
          Ember.RSVP.all(toSave.invoke('save')).then(function () {
            transition.retry();
          });
          return false;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});