define("dem-editor-v2/routes/project", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function (params) {
      var userId = this.modelFor('application').get('userid');
      return this.store.query('project', {
        num_client: params.num_client
      }).then(function (results) {
        var project = results.get('firstObject');

        if (project) {
          project.set('current_user_id', userId);
          (0, _jquery.default)(window).on('beforeunload', function () {
            if (project.get('chdNotUpToDate') && !project.get('allowExit')) {
              return "CHD NON A JOUR";
            }
          });
          return project;
        }

        return params.num_client;
      });
    },
    afterModel: function (model) {
      if (typeof model === 'string' || model instanceof String) {
        this.transitionTo('import', {
          "num_client": model
        });
      } else {
        this.controllerFor('application').set('openedProject', model);
        (0, _jquery.default)(document).attr('title', "Editor - " + model.get('last_name') + " " + model.get('num_client'));
        var types = this.store.peekAll('meuble-type');

        if (types.get('length') < _environment.default.IDEM_SETTINGS.MEUBLE_TYPES_COUNT) {
          return this.store.findAll('meuble-type');
        }
      }
    },
    renderTemplate: function () {
      this.render('project');
      this.render('project.phones', {
        into: 'application',
        outlet: 'phones',
        controller: this.get('controller')
      });
      this.render('project.menu', {
        // the template to render
        into: 'application',
        // the template to render into
        outlet: 'menu',
        // the name of the outlet in that template
        controller: this.get('controller') // the controller to use for the template

      });
    },
    actions: {
      error: function (error, transition) {
        _jquery.default.growl.error({
          title: 'Dossier non trouvé',
          message: 'Numéro client = ' + transition.params.project.num_client
        });

        console.error(error);
        this.transitionTo('index');
      },
      exitProject: function () {
        var project = this.modelFor('project');

        if (project.get('chdNotUpToDate') && !project.get('allowExit')) {
          (0, _jquery.default)('#confirmExitProject').modal('show');
        } else {
          this.transitionTo('index');
        }
      },
      //FAKE ACTION IN CASE THE CHD ONE IS NOT HANDLED
      loadChd: function () {
        return false;
      }
    }
  });

  _exports.default = _default;
});