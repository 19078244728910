define("dem-editor-v2/templates/project/phones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LiLKQk/2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"visible-md-block visible-lg-block topbar-phone navbar-text phone-tel-ext\"],[8],[0,\"\\n\\t\"],[1,[24,[\"project\",\"phone_mobile\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"visible-md-block visible-lg-block topbar-phone navbar-text phone-tel-ext\"],[8],[0,\"\\n\\t\"],[1,[24,[\"project\",\"phone_home\"]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/project/phones.hbs"
    }
  });

  _exports.default = _default;
});