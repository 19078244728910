define("dem-editor-v2/components/service-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["service-box", "fa"],
    classNameBindings: ['isNull:fa-plus', 'includedClass'],
    attributeBindings: ['style', 'title'],
    tagName: 'div',
    color: 'd1885e',
    click: function () {
      if (this.onClick) {
        this.onClick(this.get('service'));
      }
    },
    includedClass: Ember.computed('service.include_vol', function () {
      if (this.get('service') == null) {
        return "";
      }

      return this.get('service').get('include_vol') ? "fa-circle" : "fa-circle-o";
    }),
    isNull: Ember.computed('service', function () {
      return this.get('service') == null;
    }),
    title: Ember.computed('service.name', function () {
      var service = this.get('service');

      if (service) {
        return Ember.String.htmlSafe(service.get('type_name') + " : " + service.get('name'));
      }

      return Ember.String.htmlSafe("");
    }),
    style: Ember.computed('service.color', function () {
      var service = this.get('service');

      if (service) {
        return Ember.String.htmlSafe("color: #" + Ember.String.htmlSafe(service.get('color')));
      }

      return Ember.String.htmlSafe("");
    })
  });

  _exports.default = _default;
});