define("dem-editor-v2/components/check-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["row"],
    disable: false,
    disableWhenValue: false,
    commentaire: "Commentaire",
    checkboxClass: "col-sm-1 col-xs-2 col-sm-push-5",
    labelClass: "col-sm-5 col-xs-10 col-sm-pull-1",
    commentClass: "col-sm-6 col-xs-12",
    checkEnabled: true,
    refreshComment: false,
    enableComment: true,
    comment: null,
    notNull: true,
    dontUncheckOnDisable: false,
    checked: Ember.computed("model", "refreshValue", {
      get: function () {
        return this.get('model').get(this.get('field'));
      },
      set: function (key, value) {
        this.get('model').set(this.get('field'), value);

        if (!!value === !!this.get('disableWhenValue') && this.get('disable')) {
          this.get('model').set(this.get('commentField'), null);
          this.toggleProperty('refreshComment');
        }

        return value;
      }
    }),
    dontKnow: Ember.computed('checked', 'model', 'refreshValue', function () {
      const field = this.get('dontKnowField') || this.get('field');
      return this.get('model').get(field) === -1;
    }),
    commentNotNull: Ember.computed('notNull', 'disable', 'disabled', function () {
      if (this.get('disable')) {
        return this.get('notNull') && !this.get('disabled');
      }

      return this.get('notNull');
    }),
    errorComment: Ember.computed('checked', function () {
      if (this.get('commentNotNull') && Ember.isBlank(this.get('model').get(this.get('commentField')))) {
        return "Renseigner ce champ";
      }

      return null;
    }),

    setCheckedFalse() {
      /* eslint-disable ember/no-side-effects */
      this.set('checked', 0);
      /* eslint-enable ember/no-side-effects */
    },

    checkDisabled: Ember.computed('checkEnabled', function () {
      var disable = !this.get('checkEnabled');

      if (disable && !this.get('dontUncheckOnDisable')) {
        Ember.run.scheduleOnce('actions', this, this.setCheckedFalse);
      }

      return disable;
    }),
    commentField: Ember.computed('field', function () {
      var commentField = this.get('comment');

      if (commentField) {
        return commentField;
      }

      return this.get('field') + "_comment";
    }),
    disabled: Ember.computed('checked', function () {
      if (this.get("disable")) {
        return !!this.get("checked") === !!this.get('disableWhenValue');
      }

      return false;
    }),
    actions: {
      verifyDontKnow: function () {
        const field = this.get('dontKnowField') || this.get('field');
        this.get('model').set(field, !!this.get('checked'));
        this.toggleProperty('refreshValue');
      }
    }
  });

  _exports.default = _default;
});