define("dem-editor-v2/components/qty-shortcuts", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function () {
      (0, _jquery.default)(this.element).find('input.qtyinput').focus(function (event) {
        (0, _jquery.default)(event.target).select();
      });
      (0, _jquery.default)(this.element).find('input.qtyinput').keydown(function (event) {
        //LEFT AND RIGHT ARROWS
        if (event.which === 37 || event.which === 39) {
          var pureInput = event.target;
          var jqInput = (0, _jquery.default)(pureInput);
          var selStart = 0,
              selEnd = 0,
              valueLength = 0;

          if (jqInput.attr('type') === 'text') {
            selStart = pureInput.selectionStart;
            selEnd = pureInput.selectionEnd;
            valueLength = jqInput.val().length;
          }

          var nextTd = null;

          if (selStart === 0 && (selEnd === 0 || selEnd === valueLength) && event.which === 37) {
            nextTd = jqInput.closest('span');

            if (nextTd.is(':first-child')) {
              nextTd = nextTd.closest('div').prev('div').find('span:has(input:not([tabindex="-1"]))').last();
            } else {
              nextTd = nextTd.prevAll('span:has(input:not([tabindex="-1"]))').first();
            }
          }

          if (selEnd === valueLength && (selStart === 0 || selStart === valueLength) && event.which === 39) {
            nextTd = jqInput.closest('span');

            if (nextTd.is(':last-child')) {
              nextTd = nextTd.closest('div').next('div').find('span:has(input:not([tabindex="-1"]))').first();
            } else {
              nextTd = nextTd.nextAll('span:has(input:not([tabindex="-1"]))').first();
            }
          } //SELECT MEUBLE AUTOCOMPLETE


          if (Ember.isEmpty(nextTd) && event.which === 37 && selStart === 0) {
            nextTd = jqInput.closest('td').prev('td');
          }

          if (!Ember.isNone(nextTd)) {
            var nextInp = nextTd.find('input:not([tabindex="-1"])').first();

            if (nextInp !== null && nextInp !== undefined) {
              event.preventDefault();
              nextInp.focus();
            }
          }
        }
      });
    }
  });

  _exports.default = _default;
});