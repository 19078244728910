define("dem-editor-v2/controllers/index", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global moment */
  var _default = Ember.Controller.extend({
    userinfo: Ember.inject.controller('application'),
    userData: Ember.computed('userinfo', function () {
      return this.get("userinfo").get("model");
    }),
    dateFrom: moment().format("YYYY-MM-DD"),
    dateTo: moment().format("YYYY-MM-DD"),
    meetings: null,
    unsentMeetings: null,
    meetingsLoading: false,
    unsentMeetingsLoading: false,
    showAllFiles: false,
    selectedUser: null,
    showAllFilesToggle: Ember.observer('showAllFiles', function () {
      this.send('open', null);
    }),
    projectMapper: function (item) {
      if (item == null) {
        return {
          text: "",
          emberObject: null
        };
      }

      return {
        text: item.get('num_client') + " - " + item.get('last_name'),
        emberObject: item
      };
    },
    sortedProjects: Ember.computed('model.@each.last_modified', function () {
      return this.get('model').sortBy('last_modified:desc');
    }),
    actions: {
      selectUser: function (user) {
        this.set("selectedUser", user);
        this.send('updateMeetings');
        this.send('updateUnsentMeetings');
      },
      open: function (project) {
        if (Ember.isNone(project)) {
          project = (0, _jquery.default)("#project-search").val();
        }

        if (project.constructor.name !== "String") {
          this.transitionToRoute('project.client', project.get('num_client'));
          return;
        }

        this.set('loading', true);
        (0, _jquery.default)('#project-search').blur();

        var _this = this;

        this.store.query('project', {
          search: project,
          show_all: this.get('showAllFiles')
        }).then(function (results) {
          var sortedProjects = _this.get('sortedProjects');

          results.forEach(function (item) {
            if (!sortedProjects.includes(item)) {
              sortedProjects.unshiftObject(item);
            }
          });
          setTimeout(function () {
            _this.set('loading', false);

            if (!Ember.isBlank(project)) {
              (0, _jquery.default)('#project-search').focus();
            }
          }, 500);
        });
      },
      import: function (meeting) {
        var _this = this;

        var num_client = meeting.parent.num_client;
        var selectedUser = null; //IF USER IS NOT ASSIGNED TO MEETING, IMPORT FOR SELECTED USER (USER = ADMIN)

        var user_id = this.get('userData').get('userid');

        if (user_id !== meeting.assigned_user_id) {
          selectedUser = this.get('selectedUser');
        }

        this.set('meetingsLoading', true);
        var server = _environment.default.APP.API_HOST;
        var url = server + "/import/" + num_client;

        if (!Ember.isNone(selectedUser)) {
          url += "?for_user=" + selectedUser;
        }

        _jquery.default.get(url, function () {
          _this.transitionToRoute('project.client', num_client);
        }).fail(function (error) {
          console.error(error);
        }).always(function () {
          _this.set('meetingsLoading', false);
        });
      },
      gotoChd: function (meeting) {
        var num_client = meeting.parent.num_client;
        this.transitionToRoute('project.chd', num_client);
      },
      updateMeetings: function () {
        var _this = this;

        var server = _environment.default.APP.API_HOST;
        this.set('meetingsLoading', true);
        var url = server + "/meetings/?date_from=" + this.get('dateFrom');

        if (this.get('dateTo')) {
          url += "&date_to=" + this.get('dateTo');
        }

        var selectedUser = this.get('selectedUser');

        if (selectedUser) {
          url += "&selected_user=" + selectedUser;
        }

        _jquery.default.get(url, function (data) {
          _this.set('meetings', data.results);
        }).fail(function () {
          _this.set('meetings', null);
        }).always(function () {
          _this.set('meetingsLoading', false);
        });
      },
      updateUnsentMeetings: function () {
        var _this = this;

        var server = _environment.default.APP.API_HOST;
        this.set('unsentMeetingsLoading', true);
        var url = server + "/meetings/?must_be_sent=true";
        var selectedUser = this.get('selectedUser');

        if (selectedUser) {
          url += "&selected_user=" + selectedUser;
        }

        _jquery.default.get(url, function (data) {
          _this.set('unsentMeetings', data.results);
        }).fail(function () {
          _this.set('unsentMeetings', null);
        }).always(function () {
          _this.set('unsentMeetingsLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});