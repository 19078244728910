define("dem-editor-v2/routes/project/inventaire", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function () {
      var project = this.modelFor('project');
      var types = this.store.peekAll('meuble-type');

      if (types.get('length') < _environment.default.IDEM_SETTINGS.MEUBLE_TYPES_COUNT) {
        types = this.store.findAll('meuble-type');
      }

      var categories = this.store.peekAll('meuble-category');

      if (categories.get('length') <= 0) {
        categories = this.store.findAll('meuble-category');
      }

      return Ember.RSVP.hash({
        types: types,
        pieces: project.get('pieces'),
        categories: categories,
        allServices: project.get('services')
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);

      controller.set('selectedAdresseService', null);
    },
    redirect: function (model) {
      var last = this.modelFor('project').get('lastPieceOpened');

      if (Ember.isNone(last) || last.get('isDeleted')) {
        last = model.pieces.get('firstObject');
      }

      if (!Ember.isNone(last) && !last.get('isDeleted')) {
        this.transitionTo('project.inventaire.piece', last);
      }
    },
    actions: {
      willTransition: function (transition) {
        //WHEN EXITING INVENTAIRE, SAVE PROJECT TO UPDATE VOLUME TOTAL
        if (transition.targetName.indexOf("inventaire") < 0) {
          var project = this.modelFor('project');
          var notSavedPieces = project.get('pieces').filter(function (item) {
            return (item.get('hasDirtyAttributes') || item.get('isDeleted')) && !item.get('isSaving');
          });

          if (project.get('hasDirtyAttributes') || notSavedPieces.get('length') > 0) {
            transition.abort();
            var controller = this.get('controller');
            controller.set('isInLoadingRoute', true);
            this.intermediateTransitionTo('project.loading');

            var afterFunction = function () {
              //IF WE LEFT THE LOADING ROUTE, A TRANSITION ALREADY HAPPENED SO DON'T TRANSITION (THE USER LEFT...)
              if (!controller.get('isInLoadingRoute')) {
                return;
              }

              transition.retry();
            };

            Ember.RSVP.all(notSavedPieces.invoke('save')).then(function (savedPieces) {
              savedPieces.forEach(function (piece) {
                piece.get('meubles').filterBy('isNew', true).forEach(function (item) {
                  item.deleteRecord();
                });
              });
              project.save().finally(function () {
                afterFunction();
              });
            }).catch(function (error) {
              _jquery.default.growl.error({
                title: 'ATTENTION: des pièces n\'ont pas été enregistrées !',
                message: error.message
              });

              afterFunction();
            });
          }

          return;
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});