define("dem-editor-v2/routes/index", ["exports", "jquery", "dem-editor-v2/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function (controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      controller.set('all_users', this.get('all_users'));
    },
    model: function () {
      this.controllerFor('application').set('openedProject', null);
      this.controllerFor('application').set('title', 'Ouvrir un dossier');
      var controller = this.get('controller');
      var show_all = false;

      if (!Ember.isNone(controller)) {
        show_all = controller.get('showAllFiles');
      }

      return this.store.query('project', {
        show_all: show_all
      });
    },
    afterModel: function () {
      var user = this.controllerFor('application').get('model');
      var controller = this.get('controller'); //IF USERS ALREADY LOADED, DONT RELOAD

      if (!Ember.isNone(controller) && !Ember.isNone(controller.get('all_users'))) {
        return;
      }

      if (user.get('isAdmin')) {
        var server = _environment.default.APP.API_HOST;
        var url = server + "/users/";

        var _this = this;

        return _jquery.default.get(url, function (data) {
          _this.set('all_users', data);
        });
      }
    },
    renderTemplate: function () {
      this.render('index');
      this.render('admin', {
        // the template to render
        into: 'application',
        // the template to render into
        outlet: 'menuadmin',
        // the name of the outlet in that template
        controller: this.get('controller') // the controller to use for the template

      });
    },
    actions: {
      didTransition: function () {
        var user = this.controllerFor('application').get('model');

        if (user.get('isAdmin')) {
          var selectedUser = this.controller.get('selectedUser');

          if (Ember.isNone(selectedUser)) {
            this.controller.set('selectedUser', user.get('userid'));
          }
        }

        this.controller.send('updateUnsentMeetings');
        (0, _jquery.default)(window).off('beforeunload');
      },
      error: function (error) {
        _jquery.default.growl.error({
          title: 'Problème de chargement des données',
          message: ''
        });

        console.error("Problème de chargement des données - page index");
        console.error(error);
        this.transitionTo('login');
      }
    }
  });

  _exports.default = _default;
});