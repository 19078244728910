define("dem-editor-v2/models/meuble-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    nom_type: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    quantite_type: _emberData.default.attr('number'),
    volume_type: _emberData.default.attr('number'),
    demontage_type: _emberData.default.attr('boolean'),
    emballage_type: _emberData.default.attr('boolean'),
    has_cartons: _emberData.default.attr('boolean'),
    special_service: _emberData.default.attr('string'),
    icon_src: Ember.computed('icon', function () {
      return 'images/icons/' + this.get('icon') + '.png';
    }),
    not_null_icon: Ember.computed('icon', function () {
      return this.get('icon') || 'default';
    })
  });

  _exports.default = _default;
});