define("dem-editor-v2/controllers/project/livraison", ["exports", "dem-editor-v2/controllers/basemultiadresse"], function (_exports, _basemultiadresse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basemultiadresse.default.extend({
    adresseRoute: 'project.livraison.adresse'
  });

  _exports.default = _default;
});