define("dem-editor-v2/models/meuble-category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    meubles_type: _emberData.default.hasMany('meuble-type', {
      async: true
    }),
    sub_categories: _emberData.default.hasMany('meuble-sub-category', {
      async: true,
      inverse: "parents"
    })
  });

  _exports.default = _default;
});