define("dem-editor-v2/services/country-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    list: Object.freeze([{
      value: 'FR',
      text: 'France'
    }, {
      value: 'BE',
      text: 'Belgique'
    }, {
      value: 'CH',
      text: 'Suisse'
    }, {
      value: 'GB',
      text: 'Royaume-Uni'
    }, {
      value: 'ES',
      text: 'Espagne'
    }, {
      value: 'DE',
      text: 'Allemagne'
    }, {
      value: 'IT',
      text: 'Italie'
    }, {
      value: 'PT',
      text: 'Portugal'
    }, {
      value: 'GP',
      text: 'Guadeloupe'
    }, {
      value: 'RE',
      text: 'Réunion'
    }, {
      value: 'MQ',
      text: 'Martinique'
    }, {
      value: 'AF',
      text: 'Afghanistan'
    }, {
      value: 'ZA',
      text: 'Afrique Du Sud'
    }, {
      value: 'AX',
      text: 'Åland, Îles'
    }, {
      value: 'AL',
      text: 'Albanie'
    }, {
      value: 'DZ',
      text: 'Algérie'
    }, {
      value: 'AD',
      text: 'Andorre'
    }, {
      value: 'AO',
      text: 'Angola'
    }, {
      value: 'AI',
      text: 'Anguilla'
    }, {
      value: 'AQ',
      text: 'Antarctique'
    }, {
      value: 'AG',
      text: 'Antigua-Et-Barbuda'
    }, {
      value: 'SA',
      text: 'Arabie Saoudite'
    }, {
      value: 'AR',
      text: 'Argentine'
    }, {
      value: 'AM',
      text: 'Arménie'
    }, {
      value: 'AW',
      text: 'Aruba'
    }, {
      value: 'AU',
      text: 'Australie'
    }, {
      value: 'AT',
      text: 'Autriche'
    }, {
      value: 'AZ',
      text: 'Azerbaïdjan'
    }, {
      value: 'BS',
      text: 'Bahamas'
    }, {
      value: 'BH',
      text: 'Bahreïn'
    }, {
      value: 'BD',
      text: 'Bangladesh'
    }, {
      value: 'BB',
      text: 'Barbade'
    }, {
      value: 'BY',
      text: 'Bélarus'
    }, {
      value: 'BZ',
      text: 'Belize'
    }, {
      value: 'BJ',
      text: 'Bénin'
    }, {
      value: 'BM',
      text: 'Bermudes'
    }, {
      value: 'BT',
      text: 'Bhoutan'
    }, {
      value: 'BO',
      text: 'Bolivie, L\'état Plurinational De'
    }, {
      value: 'BQ',
      text: 'Bonaire, Saint-Eustache Et Saba'
    }, {
      value: 'BA',
      text: 'Bosnie-Herzégovine'
    }, {
      value: 'BW',
      text: 'Botswana'
    }, {
      value: 'BV',
      text: 'Bouvet, Île'
    }, {
      value: 'BR',
      text: 'Brésil'
    }, {
      value: 'BN',
      text: 'Brunei Darussalam'
    }, {
      value: 'BG',
      text: 'Bulgarie'
    }, {
      value: 'BF',
      text: 'Burkina Faso'
    }, {
      value: 'BI',
      text: 'Burundi'
    }, {
      value: 'KY',
      text: 'Caïmans, Îles'
    }, {
      value: 'KH',
      text: 'Cambodge'
    }, {
      value: 'CM',
      text: 'Cameroun'
    }, {
      value: 'CA',
      text: 'Canada'
    }, {
      value: 'CV',
      text: 'Cap-Vert'
    }, {
      value: 'CF',
      text: 'Centrafricaine, République'
    }, {
      value: 'CL',
      text: 'Chili'
    }, {
      value: 'CN',
      text: 'Chine'
    }, {
      value: 'CX',
      text: 'Christmas, Île'
    }, {
      value: 'CY',
      text: 'Chypre'
    }, {
      value: 'CC',
      text: 'Cocos (Keeling), Îles'
    }, {
      value: 'CO',
      text: 'Colombie'
    }, {
      value: 'KM',
      text: 'Comores'
    }, {
      value: 'CG',
      text: 'Congo'
    }, {
      value: 'CD',
      text: 'Congo, La République Démocratique Du'
    }, {
      value: 'CK',
      text: 'Cook, Îles'
    }, {
      value: 'KR',
      text: 'Corée du Sud'
    }, {
      value: 'KP',
      text: 'Corée du Nord'
    }, {
      value: 'CR',
      text: 'Costa Rica'
    }, {
      value: 'CI',
      text: 'Côte D\'ivoire'
    }, {
      value: 'HR',
      text: 'Croatie'
    }, {
      value: 'CU',
      text: 'Cuba'
    }, {
      value: 'CW',
      text: 'Curaçao'
    }, {
      value: 'DK',
      text: 'Danemark'
    }, {
      value: 'DJ',
      text: 'Djibouti'
    }, {
      value: 'DO',
      text: 'Dominicaine, République'
    }, {
      value: 'DM',
      text: 'Dominique'
    }, {
      value: 'EG',
      text: 'Égypte'
    }, {
      value: 'SV',
      text: 'El Salvador'
    }, {
      value: 'AE',
      text: 'Émirats Arabes Unis'
    }, {
      value: 'EC',
      text: 'Équateur'
    }, {
      value: 'ER',
      text: 'Érythrée'
    }, {
      value: 'EE',
      text: 'Estonie'
    }, {
      value: 'US',
      text: 'États-Unis'
    }, {
      value: 'ET',
      text: 'Éthiopie'
    }, {
      value: 'FK',
      text: 'Falkland, Îles (Malvinas)'
    }, {
      value: 'FO',
      text: 'Féroé, Îles'
    }, {
      value: 'FJ',
      text: 'Fidji'
    }, {
      value: 'FI',
      text: 'Finlande'
    }, {
      value: 'GA',
      text: 'Gabon'
    }, {
      value: 'GM',
      text: 'Gambie'
    }, {
      value: 'GE',
      text: 'Géorgie'
    }, {
      value: 'GS',
      text: 'Géorgie Du Sud-Et-Les Îles Sandwich Du Sud'
    }, {
      value: 'GH',
      text: 'Ghana'
    }, {
      value: 'GI',
      text: 'Gibraltar'
    }, {
      value: 'GR',
      text: 'Grèce'
    }, {
      value: 'GD',
      text: 'Grenade'
    }, {
      value: 'GL',
      text: 'Groenland'
    }, {
      value: 'GU',
      text: 'Guam'
    }, {
      value: 'GT',
      text: 'Guatemala'
    }, {
      value: 'GG',
      text: 'Guernesey'
    }, {
      value: 'GN',
      text: 'Guinée'
    }, {
      value: 'GW',
      text: 'Guinée-Bissau'
    }, {
      value: 'GQ',
      text: 'Guinée Équatoriale'
    }, {
      value: 'GY',
      text: 'Guyana'
    }, {
      value: 'GF',
      text: 'Guyane Française'
    }, {
      value: 'HT',
      text: 'Haïti'
    }, {
      value: 'HM',
      text: 'Heard-Et-Îles Macdonald, Île'
    }, {
      value: 'HN',
      text: 'Honduras'
    }, {
      value: 'HK',
      text: 'Hong Kong'
    }, {
      value: 'HU',
      text: 'Hongrie'
    }, {
      value: 'IM',
      text: 'Île De Man'
    }, {
      value: 'UM',
      text: 'Îles Mineures Éloignées Des États-Unis'
    }, {
      value: 'VG',
      text: 'Îles Vierges Britanniques'
    }, {
      value: 'VI',
      text: 'Îles Vierges Des États-Unis'
    }, {
      value: 'IN',
      text: 'Inde'
    }, {
      value: 'ID',
      text: 'Indonésie'
    }, {
      value: 'IR',
      text: 'Iran'
    }, {
      value: 'IQ',
      text: 'Iraq'
    }, {
      value: 'IE',
      text: 'Irlande'
    }, {
      value: 'IS',
      text: 'Islande'
    }, {
      value: 'IL',
      text: 'Israël'
    }, {
      value: 'JM',
      text: 'Jamaïque'
    }, {
      value: 'JP',
      text: 'Japon'
    }, {
      value: 'JE',
      text: 'Jersey'
    }, {
      value: 'JO',
      text: 'Jordanie'
    }, {
      value: 'KZ',
      text: 'Kazakhstan'
    }, {
      value: 'KE',
      text: 'Kenya'
    }, {
      value: 'KG',
      text: 'Kirghizistan'
    }, {
      value: 'KI',
      text: 'Kiribati'
    }, {
      value: 'KW',
      text: 'Koweït'
    }, {
      value: 'LA',
      text: 'Laos, République Démocratique Populaire'
    }, {
      value: 'LS',
      text: 'Lesotho'
    }, {
      value: 'LV',
      text: 'Lettonie'
    }, {
      value: 'LB',
      text: 'Liban'
    }, {
      value: 'LR',
      text: 'Libéria'
    }, {
      value: 'LY',
      text: 'Libye'
    }, {
      value: 'LI',
      text: 'Liechtenstein'
    }, {
      value: 'LT',
      text: 'Lituanie'
    }, {
      value: 'LU',
      text: 'Luxembourg'
    }, {
      value: 'MO',
      text: 'Macao'
    }, {
      value: 'MK',
      text: 'Macédoine, L\'ex-République Yougoslave De'
    }, {
      value: 'MG',
      text: 'Madagascar'
    }, {
      value: 'MY',
      text: 'Malaisie'
    }, {
      value: 'MW',
      text: 'Malawi'
    }, {
      value: 'MV',
      text: 'Maldives'
    }, {
      value: 'ML',
      text: 'Mali'
    }, {
      value: 'MT',
      text: 'Malte'
    }, {
      value: 'MP',
      text: 'Mariannes Du Nord, Îles'
    }, {
      value: 'MA',
      text: 'Maroc'
    }, {
      value: 'MH',
      text: 'Marshall, Îles'
    }, {
      value: 'MU',
      text: 'Maurice'
    }, {
      value: 'MR',
      text: 'Mauritanie'
    }, {
      value: 'YT',
      text: 'Mayotte'
    }, {
      value: 'MX',
      text: 'Mexique'
    }, {
      value: 'FM',
      text: 'Micronésie, États Fédérés De'
    }, {
      value: 'MD',
      text: 'Moldavie, République De'
    }, {
      value: 'MC',
      text: 'Monaco'
    }, {
      value: 'MN',
      text: 'Mongolie'
    }, {
      value: 'ME',
      text: 'Monténégro'
    }, {
      value: 'MS',
      text: 'Montserrat'
    }, {
      value: 'MZ',
      text: 'Mozambique'
    }, {
      value: 'MM',
      text: 'Myanmar (Birmanie)'
    }, {
      value: 'NA',
      text: 'Namibie'
    }, {
      value: 'NR',
      text: 'Nauru'
    }, {
      value: 'NP',
      text: 'Népal'
    }, {
      value: 'NI',
      text: 'Nicaragua'
    }, {
      value: 'NE',
      text: 'Niger'
    }, {
      value: 'NG',
      text: 'Nigéria'
    }, {
      value: 'NU',
      text: 'Niué'
    }, {
      value: 'NF',
      text: 'Norfolk, Île'
    }, {
      value: 'NO',
      text: 'Norvège'
    }, {
      value: 'NC',
      text: 'Nouvelle-Calédonie'
    }, {
      value: 'NZ',
      text: 'Nouvelle-Zélande'
    }, {
      value: 'IO',
      text: 'Océan Indien, Territoire Britannique De L\''
    }, {
      value: 'OM',
      text: 'Oman'
    }, {
      value: 'UG',
      text: 'Ouganda'
    }, {
      value: 'UZ',
      text: 'Ouzbékistan'
    }, {
      value: 'PK',
      text: 'Pakistan'
    }, {
      value: 'PW',
      text: 'Palaos'
    }, {
      value: 'PS',
      text: 'Palestine'
    }, {
      value: 'PA',
      text: 'Panama'
    }, {
      value: 'PG',
      text: 'Papouasie-Nouvelle-Guinée'
    }, {
      value: 'PY',
      text: 'Paraguay'
    }, {
      value: 'NL',
      text: 'Pays-Bas'
    }, {
      value: 'PE',
      text: 'Pérou'
    }, {
      value: 'PH',
      text: 'Philippines'
    }, {
      value: 'PN',
      text: 'Pitcairn'
    }, {
      value: 'PL',
      text: 'Pologne'
    }, {
      value: 'PF',
      text: 'Polynésie Française'
    }, {
      value: 'PR',
      text: 'Porto Rico'
    }, {
      value: 'QA',
      text: 'Qatar'
    }, {
      value: 'RO',
      text: 'Roumanie'
    }, {
      value: 'RU',
      text: 'Russie, Fédération De'
    }, {
      value: 'RW',
      text: 'Rwanda'
    }, {
      value: 'EH',
      text: 'Sahara Occidental'
    }, {
      value: 'BL',
      text: 'Saint-Barthélemy'
    }, {
      value: 'SH',
      text: 'Sainte-Hélène, Ascension Et Tristan Da Cunha'
    }, {
      value: 'LC',
      text: 'Sainte-Lucie'
    }, {
      value: 'KN',
      text: 'Saint-Kitts-Et-Nevis'
    }, {
      value: 'SM',
      text: 'Saint-Marin'
    }, {
      value: 'MF',
      text: 'Saint-Martin (Partie Française)'
    }, {
      value: 'SX',
      text: 'Saint-Martin (Partie Néerlandaise)'
    }, {
      value: 'PM',
      text: 'Saint-Pierre-Et-Miquelon'
    }, {
      value: 'VA',
      text: 'Saint-Siège (État De La Cité Du Vatican)'
    }, {
      value: 'VC',
      text: 'Saint-Vincent-Et-Les Grenadines'
    }, {
      value: 'SB',
      text: 'Salomon, Îles'
    }, {
      value: 'WS',
      text: 'Samoa'
    }, {
      value: 'AS',
      text: 'Samoa Américaines'
    }, {
      value: 'ST',
      text: 'Sao Tomé-Et-Principe'
    }, {
      value: 'SN',
      text: 'Sénégal'
    }, {
      value: 'RS',
      text: 'Serbie'
    }, {
      value: 'SC',
      text: 'Seychelles'
    }, {
      value: 'SL',
      text: 'Sierra Leone'
    }, {
      value: 'SG',
      text: 'Singapour'
    }, {
      value: 'SK',
      text: 'Slovaquie'
    }, {
      value: 'SI',
      text: 'Slovénie'
    }, {
      value: 'SO',
      text: 'Somalie'
    }, {
      value: 'SD',
      text: 'Soudan'
    }, {
      value: 'SS',
      text: 'Soudan Du Sud'
    }, {
      value: 'LK',
      text: 'Sri Lanka'
    }, {
      value: 'SE',
      text: 'Suède'
    }, {
      value: 'SR',
      text: 'Suriname'
    }, {
      value: 'SJ',
      text: 'Svalbard Et Île Jan Mayen'
    }, {
      value: 'SZ',
      text: 'Swaziland'
    }, {
      value: 'SY',
      text: 'Syrienne, République Arabe'
    }, {
      value: 'TJ',
      text: 'Tadjikistan'
    }, {
      value: 'TW',
      text: 'Taïwan, Province De Chine'
    }, {
      value: 'TZ',
      text: 'Tanzanie, République-Unie De'
    }, {
      value: 'TD',
      text: 'Tchad'
    }, {
      value: 'CZ',
      text: 'Tchèque, République'
    }, {
      value: 'TF',
      text: 'Terres Australes Françaises'
    }, {
      value: 'TH',
      text: 'Thaïlande'
    }, {
      value: 'TL',
      text: 'Timor-Leste'
    }, {
      value: 'TG',
      text: 'Togo'
    }, {
      value: 'TK',
      text: 'Tokelau'
    }, {
      value: 'TO',
      text: 'Tonga'
    }, {
      value: 'TT',
      text: 'Trinité-Et-Tobago'
    }, {
      value: 'TN',
      text: 'Tunisie'
    }, {
      value: 'TM',
      text: 'Turkménistan'
    }, {
      value: 'TC',
      text: 'Turks-Et-Caïcos, Îles'
    }, {
      value: 'TR',
      text: 'Turquie'
    }, {
      value: 'TV',
      text: 'Tuvalu'
    }, {
      value: 'UA',
      text: 'Ukraine'
    }, {
      value: 'UY',
      text: 'Uruguay'
    }, {
      value: 'VU',
      text: 'Vanuatu'
    }, {
      value: 'VE',
      text: 'Venezuela'
    }, {
      value: 'VN',
      text: 'Vietnam'
    }, {
      value: 'WF',
      text: 'Wallis Et Futuna'
    }, {
      value: 'YE',
      text: 'Yémen'
    }, {
      value: 'ZM',
      text: 'Zambie'
    }, {
      value: 'ZW',
      text: 'Zimbabwe'
    }]),
    domTomPostals: Object.freeze([{
      value: 'GP',
      postal: '97100'
    }, {
      value: 'MQ',
      postal: '97200'
    }, {
      value: 'GF',
      postal: '97300'
    }, {
      value: 'RE',
      postal: '97400'
    }, {
      value: 'YT',
      postal: '97600'
    }, {
      value: 'BL',
      postal: '97133'
    }, {
      value: 'MF',
      postal: '97150'
    }, {
      value: 'NC',
      postal: '98800'
    }, {
      value: 'PF',
      postal: '98700'
    }, {
      value: 'PM',
      postal: '97500'
    }, {
      value: 'TF',
      postal: '98400'
    }, {
      value: 'WF',
      postal: '98600'
    }])
  });

  _exports.default = _default;
});