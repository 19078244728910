define("dem-editor-v2/templates/project/inventaire/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PWYbcOmv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[29,[[28,\"if\",[[24,[\"lastActionText\"]],\"\",\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"cancelLastModif\"]],[8],[0,\"\\n    Annuler \"],[7,\"i\",true],[10,\"class\",\"fa fa-reply fa-lg pull-right\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"lastActionText\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"menu-comment\"],[8],[1,[22,\"lastActionText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"li\",true],[10,\"role\",\"separator\"],[10,\"class\",\"divider\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/project/inventaire/menu.hbs"
    }
  });

  _exports.default = _default;
});