define("dem-editor-v2/templates/components/piece-type-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cb7KXeCS",
    "block": "{\"symbols\":[\"pieceType\"],\"statements\":[[4,\"each\",[[24,[\"availableTypes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-md-2 col-sm-3 col-xs-4\"],[8],[0,\"\\n\\t   \"],[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[28,\"if\",[[23,1,[\"selected\"]],\"label label-full\",\"label label-empty\"],null]],[3,\"action\",[[23,0,[]],\"selectPieceType\",[23,1,[]]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "dem-editor-v2/templates/components/piece-type-chooser.hbs"
    }
  });

  _exports.default = _default;
});